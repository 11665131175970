import { Checkbox } from "@mui/material";

import v from 'assets/scss/_variables.scss';

//---------------------------------------------------------------------------
const MuiCheckbox = (props) => (
  <Checkbox
    sx={{
      '&.Mui-checked': { color: v.darkGreen, }
    }}
    {...props} />
)

export default MuiCheckbox;
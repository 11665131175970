import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import 'assets/scss/temp.scss';
import { breadData } from 'components/bread/BreadBar';
import SearchResultLayout from 'layouts/search/SearchResultLayout';
import cookie from 'utils/cookie';
import { apiConfig } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import { linkData } from 'utils/links';
import { getFullPath } from 'utils/general';

//---------------------------------------------------------------------------
// Go in: 'Protein' page -> any card button -> this page
// path: paths.spp.protein.source_search(),
/*
const breadOptions = [
  {
    text: "Fragment Count",
    icon: "",
  },
  {
    text: "Frag-Graph",
    icon: "",
  },
  {
    text: "Classification Count",
    icon: "",
  },
  {
    text: "Class-Count",
    icon: "",
  },
]
*/
const ProteinSourceSearch = ({ setInfo }) => {
  const selectedClsId = cookie.getCookie(cookie.keys.protein.classGraphSelectedClsID)
  const selectedClsName = cookie.getCookie(cookie.keys.protein.classGraphSelectedClsName)

  const selectedSourceId = cookie.getCookie(cookie.keys.protein.classGraphSelectedSourceID)
  const selectedSourceName = cookie.getCookie(cookie.keys.protein.classGraphSelectedSourceName)

  const [clsId, setClsId] = useState(selectedClsId !== "" ? selectedClsId : "")
  const [sourceId, setSourceId] = useState(selectedSourceId !== "" ? selectedSourceId : "")


  //Bovine Milk proteins, Camel Milk proteins...
  const [breadTitle, setTableTitle] = useState(selectedClsName !== "" ? `${selectedClsName} proteins` : selectedSourceName !== "" ? `${selectedSourceName} proteins` : "")

  let location = useLocation()
  let breadText = breadTitle.trimClassifyName()
  let bread = linkData(breadText, getFullPath(location))
  let json3rd = JSON.stringify(bread)
  // console.log(bread, json3rd);
  cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
  let texts = {
    bread: breadData.create('Protein', breadTitle, breadText),

    searchHint: "Search by SPP ID, Organism, Protein name, Protein length, UniProtKB",
    tableHeadCell: spp.protein.search.source.output_text,
    tableBodyCell: spp.protein.search.source.output,
  }


  return (
    <SearchResultLayout
      setInfo={setInfo}
      texts={texts}
      configSearch={apiConfig.protein.source_search} //11
      jsonInput={spp.protein.search.source.input}
      inputProp={[clsId, sourceId]}
      jsonInputKeyword={spp.protein.search.source.input_keyword}
    />
  )
}
export default ProteinSourceSearch
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import scss from './BreadResult.module.scss';
import paths from 'utils/network/apiPath';
import { spp } from 'utils/network/jsons';
import NoneButton from 'components/button/NoneButton';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import cookie from 'utils/cookie';
import Text from "components/tag/Text";
import { navigateNewTab } from "utils/general";

//---------------------------------------------------------------------------
const texts = {
  tool_aceipp: [
    'Pre-ACEiPs', 'Seq-Features', 'Pre-Libraries',
  ],
  
  tool_anoxpp: [
    'Pre-AnOxPs', 'Seq-Features', 'Pre-Libraries',
  ],
}

//---------------------------------------------------------------------------
const BreadButton = (props) => {
  const isToolACEiPP = props.isToolACEiPP
  const isToolAnOxPP = props.isToolAnOxPP
  
  //------------------------------------------------------------------------------
  const ButtonRow = (props) => {
    if( isToolACEiPP ) {
      return (
        texts.tool_aceipp.map((item, index) => (
          <LabelButton key={index} index={index}>{item}</LabelButton>))
    )}
    if( isToolAnOxPP ) {
      return (
        texts.tool_anoxpp.map((item, index) => (
          <LabelButton key={index} index={index}>{item}</LabelButton>))
    )}
  }
  
  const LabelButton = (props) => {
    return (
      <NoneButton
        className={scss.button}
        onClick={() => { handleClick(props.index) }}
        {...props}
      >
        <Text className={'b2-darkGreen'}>{props.children}</Text>
      </NoneButton>)
  }
  //------------------------------------------------------------------------------
  const handleClick = (index) => {
    if( isToolACEiPP )
      clickToolACEiPP(index)
    if( isToolAnOxPP )
      clickToolAnOxPP(index)
  }
  
  const navigate = useNavigate();
  function clickToolACEiPP(index) {
    switch (index) {
      case 0: // 'Pre-ACEiPs'
        navigate( paths.spp.tool.aceipp() )
        break;
      case 1: // 'Seq-Features'
        navigate( paths.spp.tool.aceipp_sf() )
        break;
      case 2: // 'Pre-Libraries'
        navigate( paths.spp.tool.aceipp_pl() )
        break;
      default:
        break;}
  }
  function clickToolAnOxPP(index) {
    switch (index) {
      case 0: // 'Pre-AnOxPs'
        navigate( paths.spp.tool.anoxpp() )
        break;
      case 1: // 'Seq-Features'
        navigate( paths.spp.tool.anoxpp_sf() )
        break;
      case 2: // 'Pre-Libraries'
        navigate( paths.spp.tool.anoxpp_pl() )
        break;
      default:
        break;}
  }
  
  //---------------------------------------------------------------------------
  return (
    <div className={scss.top_result} >
      <div className={scss.result_buttons}>
        <ButtonRow />
      </div>
    </div>
  )
}
export default BreadButton
// define image src path
//Get file name & path (windows)
//$ dir /b /a:-d /s * > list.txt
//Get file name & path (mac & linux)
//$ ls -R -A | grep -v ^d > list.txt

// Nav image // Footer image
/*
assets\img\00Nav\
01-icon-social_fb.svg
01-icon-social_ig.svg
01-icon-social_tw.svg
01-icon-social_yt.svg
line-4.svg
logo_image.svg
logo_image.png
*/

// Homepage image
/*
assets\img\01Homepage\
BG Gradient.svg
color-morph.svg
Group.svg
Rectangle 8-1.jpg
Rectangle 8-2.jpg
Rectangle 8-3.jpg
*/

//Proteins image
/*
assets\img\02Proteins\

//Milk image
assets/img/02Proteins/Milk/
//Plant image
assets/img/02Proteins/Plant/
//Animel image
assets/img/02Proteins/Anime/
//Micro-organism image
assets/img/02Proteins/Micro-organism/
//Marine image
assets/img/02Proteins/Marine/
*/

//Tools image
/*
assets\img\03Tools\
01_HotSpot_Search.svg
02_EHP-Tool.svg
03_BPP-Tool.svg
04_AASD-Tool.svg
05_PeptideCalculator.svg
06_Multi-cross_Analysis.svg

assets\img\03Tools\BPP\
bitterPredict1.png

assets\img\03Tools\EHP\
EnzymeTable.jpg
*/

//AASD image
/*
assets\img\04ToolAASD\
*/

//---------------------------------------------------------------------------
//require.context(directory {String}=讀取檔案的路徑, useSubdirectories {Boolean}=是否遍歷檔案的子目錄, regExp {RegExp}=匹配檔案的正則)
//The arguments passed to require.context must be literals!!!
// const images = importAll(require.context('assets/img/', true, /\.(png|jpe?g|svg)$/)); //all of images in assets/img/
const images = {
  root: importAll(require.context('assets/img/', false, /\.(png|jpe?g|svg)$/)),
  
  //name from figma
  _00Nav: importAll(require.context('assets/img/00Nav', false, /\.(png|jpe?g|svg)$/)),
  _01Homepage: importAll(require.context('assets/img/01Homepage', false, /\.(png|jpe?g|svg)$/)),
  // _02Proteins: importAll(require.context('assets/img/02Proteins', false, /\.(png|jpe?g|svg)$/)),
  _02Proteins_Anime: importAll(require.context('assets/img/02Proteins/Anime', false, /\.(png|jpe?g|svg)$/)),
  _02Proteins_Marine: importAll(require.context('assets/img/02Proteins/Marine', false, /\.(png|jpe?g|svg)$/)),
  _02Proteins_MicroOrganism: importAll(require.context('assets/img/02Proteins/Micro-organism', false, /\.(png|jpe?g|svg)$/)),
  _02Proteins_Milk: importAll(require.context('assets/img/02Proteins/Milk', false, /\.(png|jpe?g|svg)$/)),
  _02Proteins_Plant: importAll(require.context('assets/img/02Proteins/Plant', false, /\.(png|jpe?g|svg)$/)),
  
  _03Tools: importAll(require.context('assets/img/03Tools', false, /\.(png|jpe?g|svg)$/)),
  _03Tools_BPP: importAll(require.context('assets/img/03Tools/BPP', false, /\.(png|jpe?g|svg)$/)),
  _03Tools_EHP: importAll(require.context('assets/img/03Tools/EHP', false, /\.(png|jpe?g|svg)$/)),
  
  _04ToolAASD: importAll(require.context('assets/img/04ToolAASD', false, /\.(png|jpe?g|svg)$/)),
  
  ToolCRS: importAll(require.context('assets/img/ToolCRS', false, /\.(png|jpe?g|svg)$/)),
}

function importAll(r) {
  let obj = {};
  r.keys().forEach((item, index) => { obj[item.replace('./', '')] = r(item); });
  // console.log(obj);
  return obj;
}

//---------------------------------------------------------------------------
export function imgData(alt, src) {
  return { alt, src }
}

const site = {
  nav: {
    // logo: imgData('SmartPP Logo', images._00Nav["assets/img/00Nav/logo_image.png"]), //work
    logo: imgData('SmartPP Logo', images._00Nav['logo_image.png']), //work
  },

  footer: {
    // instagram: imgData('Instagram', images._00Nav['01-icon-social_ig.svg']),
    // facebook: imgData('Facebook', images._00Nav['01-icon-social_fb.svg']),
    // twitter: imgData('Twitter', images._00Nav['01-icon-social_tw.svg']),
    // youtube: imgData('Youtube', images._00Nav['01-icon-social_yt.svg']),
    line: imgData('Line', images._00Nav['line-4.svg']),
    logo: imgData('SmartPP Logo', images._00Nav['2871695265348_.pic.png']),
  },

  home: {
    bg: imgData('Background', images._01Homepage['BG Gradient.svg']),
    // bg: imgData('Background', images._01Homepage['color-morph.svg']), //debug
    checkbox: imgData('Check', images._01Homepage['Group.svg']),
    rectangle1: imgData('Retrieval', images._01Homepage['Rectangle 8-1.jpg']),
    rectangle2: imgData('Discovery', images._01Homepage['Rectangle 8-2.jpg']),
    rectangle3: imgData('Analysis', images._01Homepage['Rectangle 8-3.jpg']),
  },
}
//---------------------------------------------------------------------------
const protein = {
  list: [
    //Milk //[0]    
    imgData('Cow Milk', images._02Proteins_Milk['Bovine.jpg']),
    imgData('Camel Milk', images._02Proteins_Milk['Camel.jpg']),
    imgData('Human Milk', images._02Proteins_Milk['Human Milk.jpg']),
    imgData('Horse Milk', images._02Proteins_Milk['Horse.jpg']),
    imgData('Rabbit Milk', images._02Proteins_Milk['Rabbit.jpg']),
    imgData('Wallaby Milk', images._02Proteins_Milk['Wallaby.jpg']),
    imgData('Sheep Milk', images._02Proteins_Milk['Sheep.jpg']),
    imgData('Goat Milk', images._02Proteins_Milk['Goat.jpg']),
    imgData('Mouflon Milk', images._02Proteins_Milk['Mouflon.jpg']),
    imgData('Buffalo Milk', images._02Proteins_Milk['Buffalo.jpg']),
    
    //Plant //[10]
    imgData('Barley', images._02Proteins_Plant['Barley.jpg']),
    imgData('Rape', images._02Proteins_Plant['Rape.jpg']),
    imgData('Oat', images._02Proteins_Plant['Oat.jpg']),
    imgData('Soybean', images._02Proteins_Plant['Soybean.jpg']),
    imgData('Wheat', images._02Proteins_Plant['Wheat.jpg']),
    imgData('Corn', images._02Proteins_Plant['Corn.jpg']),
    imgData('Rice', images._02Proteins_Plant['Rice.jpg']),
    imgData('Sorghum', images._02Proteins_Plant['Sorghum.jpg']),
    imgData('Amaranth', images._02Proteins_Plant['Amaranth.jpg']),
    imgData('GardenPea', images._02Proteins_Plant['Garden Pea.jpg']),
    
    imgData('Broad Beans', images._02Proteins_Plant['Broad Beans.jpg']),
    imgData('Ginkgo', images._02Proteins_Plant['Ginkgo.jpg']),
    imgData('Kidney Beans', images._02Proteins_Plant['Kidney Beans.jpg']),
    imgData('Pumpkin', images._02Proteins_Plant['Pumpkin.jpg']),
    imgData('SerendipityBerry', images._02Proteins_Plant['Serendipity Berry.jpg']),
    imgData('White Lupine', images._02Proteins_Plant['White lupine.jpg']),
    imgData('Rye', images._02Proteins_Plant['Rye.jpg']),
    imgData('Buckwheat', images._02Proteins_Plant['buckwheat.jpg']),
    imgData('Celery', images._02Proteins_Plant['Celery.jpg']),
    imgData('Peanuts', images._02Proteins_Plant['Peanuts.jpg']),
    
    imgData('Sesame', images._02Proteins_Plant['Sesame.jpg']),
    imgData('Lentil', images._02Proteins_Plant['Lentil.jpg']),
    imgData('Runner Beans', images._02Proteins_Plant['Runner Beans.jpg']),
    imgData('Sunflowers', images._02Proteins_Plant['Sunflowers.jpg']),
        
    imgData('', images._02Proteins_Plant['Allium cepa.jpg']),
    imgData('', images._02Proteins_Plant['Allium cepa var. aggregatum.jpg']),
    imgData('', images._02Proteins_Plant['Chamaecyparis.jpg']),
    imgData('', images._02Proteins_Plant['Cryptomeria.jpg']),
    imgData('', images._02Proteins_Plant['Cycas revoluta.jpg']),
    imgData('', images._02Proteins_Plant['Cycas taitungensis.jpg']),
    imgData('', images._02Proteins_Plant['Glebionis coronaria.jpg']),
    imgData('', images._02Proteins_Plant['Gnetum parvifolium.jpg']),
    imgData('', images._02Proteins_Plant['Juniperus ashei.jpg']),
    imgData('', images._02Proteins_Plant['Juniperus ashei.jpg']), //Juniperus Virginiana
        
    imgData('', images._02Proteins_Plant['Picea abies.jpg']),
    imgData('', images._02Proteins_Plant['Sagittaria sagittifolia.jpg']),
    imgData('', images._02Proteins_Plant['Sagittaria sagittifolia.jpg']), //Sequoia Sempervirens
    imgData('', images._02Proteins_Plant['Tanacetum cinerariifolium.jpg']),
    imgData('', images._02Proteins_Plant['Thuja occidentalis.jpg']),
    imgData('', images._02Proteins_Plant['Cicer arietinum (Chickpea).jpg']),
    imgData('', images._02Proteins_Plant['Oryza rufipogon (Brownbeard rice).jpg']),
    imgData('', images._02Proteins_Plant['Vigna radiata (Mung bean).jpg']),
    
    //Marine //[10+24+18]    
    imgData('', images._02Proteins_Marine['chaetosphaeridium globosum.jpg']),
    imgData('', images._02Proteins_Marine['Chlorella vulgaris (Green alga).jpg']),
    imgData('', images._02Proteins_Marine['Mesostigma viride (Green alga).jpg']),
    imgData('', images._02Proteins_Marine['Nephroselmis olivacea.jpg']),
    imgData('', images._02Proteins_Marine['Oscarella pearsei.jpg']),
    
    imgData('', images._02Proteins_Marine['Staurastrum punctulatum.jpg']),
    imgData('', images._02Proteins_Marine['Stigeoclonium helveticum.jpg']),
    imgData('', images._02Proteins_Marine['Tetradesmus obliquus.jpg']),
    imgData('', images._02Proteins_Marine['Tupiella akineta.jpg']),
    imgData('', images._02Proteins_Marine['Zygnema circumcarinatum.jpg']),
    
    //Micro-organism //[10+24+18+10]
    imgData('Yeast', images._02Proteins_MicroOrganism['Yeast.jpg']),
    imgData('Lactobacillus', images._02Proteins_MicroOrganism['Lactobacillus.jpg']),
    imgData('Acetobacter Aceti', images._02Proteins_MicroOrganism['Acetobacter aceti.jpg']),
    imgData('AgaricusBisporus', images._02Proteins_MicroOrganism['Agaricus bisporus.jpg']),
    
    //Animal //[10+24+18+10+4]    
    imgData('', images._02Proteins_Anime['Chicken.jpg']),
    imgData('', images._02Proteins_Milk['Bovine.jpg']),
    imgData('', images._02Proteins_Anime['Pig.jpg']),
    imgData('', images._02Proteins_Anime['Dog.jpg']),
    imgData('', images._02Proteins_Anime['Dove.jpg']),
    
    imgData('', images._02Proteins_Milk['Rabbit.jpg']),
    imgData('', images._02Proteins_Milk['Horse.jpg']),
    imgData('', images._02Proteins_Milk['Goat.jpg']),
    imgData('', images._02Proteins_Milk['Sheep.jpg']),
    imgData('', images._02Proteins_Anime['Carp.jpg']),
  ],
}

const tool = {
  ehp_tool_enzyme_table: imgData('Enzyme Table', images._03Tools_EHP['EnzymeTable.jpg']),
}

const spp = {
  protein,
  tool,
}
//---------------------------------------------------------------------------
const imgs = {
  site,
  spp,
  
  images,
}
export {images}
export default imgs

import React from 'react'

import scss from './Summary.module.scss';
import imgs from 'utils/imgs';
import Img from 'components/tag/Img';

//---------------------------------------------------------------------------
// const texts = {
// }

function countData(number, text) {
  return { number, text };
}

const Label = (props) => (
  <div className={scss.bottom_lable} {...props}>
    <Img imgName={imgs.site.home.checkbox} className={scss.label_icon}></Img>
    <span className={scss.label_number} style={{ whiteSpace: 'pre-line' }}>
      {(props.item ? props.item.number : '')}
    </span>
    <span className={scss.label_text} style={{ whiteSpace: 'pre-line' }}>
      {(props.item ? props.item.text : '')}
    </span>
  </div>
)

//---------------------------------------------------------------------------
const Summary = (props) => {
  const {output} = props
  
  if( output === undefined || output === null || output.length === 0 ) { //jsons.site.home_number.output
    output["peptide_classification"] = 31
    output["peptide_attributes"] = 30
    output["literatures"] = 1065
    
    output["peptide_sequence"] = 4000
    output["multifunctional_peptides"] = 763
    output["application_tools"] = 6
    
    output["peptide_entries"] = 6289
    output["food_borne_proteins"] = 70299
    output["sequence_features"] = 6
  }
  const words = {
    items: [
      countData(output["peptide_classification"], 'Peptide Classification'),
      countData(output["peptide_attributes"], 'Peptide Attributes'),
      countData(output["literatures"], 'Literatures'),

      countData(output["peptide_sequence"], 'Peptide Sequences'),
      countData(output["multifunctional_peptides"], 'Multifunctional Peptides'),
      countData(output["application_tools"], 'Application Tools'),

      countData(output["peptide_entries"], 'Peptide Entries'),
      countData(output["food_borne_proteins"], 'Food-borne Proteins'),
      countData(output["sequence_features"], 'Sequence Features'),
    ],
  }
  
  return (
    <div className={scss.layout}>
      <div className={scss.frame_bottom}>
        <div className={scss.bottom_left}>
          {words.items.slice(0, 3).map((item) => (
            <Label item={item} key={item.text}></Label>
          ))}
        </div>
        <div className={scss.bottom_center}>
          {words.items.slice(3, 6).map((item) => (
            <Label item={item} key={item.text}></Label>
          ))}
        </div>
        <div className={scss.bottom_right}>
          {words.items.slice(6, 9).map((item) => (
            <Label item={item} key={item.text}></Label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Summary;

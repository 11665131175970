import { useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Box, TableCell, TableHead, TableRow } from '@mui/material';

import 'assets/scss/common.scss';
import 'assets/scss/temp.scss';
import v from 'assets/scss/_variables.scss';
import csScss from "./ToolPDCAASResult.module.scss";
import scssTools from 'views/tool/Tools.module.scss';

import paths from 'utils/network/apiPath';
import LayoutPage from 'layouts/LayoutPage';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { breadData } from 'components/bread/BreadBar';
import { MuiTableCell, TableHeadRow, TableRow2Body } from 'components/table/MuiTable';
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { Space, checkInvalidSeq, isEmptyObjOrArray, isOnlySpacesOrNewlines } from 'utils/general';
import { MuiTableContainer } from "components/table/MuiTable";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import { methods } from './ToolPDCAAS';

//---------------------------------------------------------------------------
const jsons = spp.tool.pdcaas_result
const texts = {  
  process: [
    'PDCAAS calculation process',
    'DIAAS calculation process',
  ],
  
  //---------------------------------------------------------------------------
  pdcaas: {
    title: [
      'Analytical data',
      'Digsestible quantities in mixture',
    ],
    unit: [
      '', 'g', 'g/100 g', 'mg/g protein',
      '-', 'g', 'mg',
    ],
    tableHeadCell: jsons.pdcaas_items_text,
    tableBodyCell: jsons.pdcaas_items,
    
    total: {
      title: 'Totals',
      tableBodyCell: jsons.pdcaas_total,
    },
    
    amino_acid: {
      title: 'Amino acids: mg/g protein (total for each amino acid/total protein)',
      tableBodyCell: jsons.pdcaas_amino_acid,
    },
    
    avg: {
      title: 'Weighted average digestibility: sum of digestible protein',
      tableBodyCell: jsons.pdcaas_avg,
    },
  },
  pdcaas_age: {
    tableHeadCell: jsons.age_group_text('PDCAAS'),
    tableBodyCell: jsons.age_group,
    years: [
      'Infants (0-5 years)',
      'Preschool children(1-2 years)',
      'Older children and adolescents (4-18 years)',
      'Adults (>18 years)',
    ],
  },
  
  //---------------------------------------------------------------------------
  diaas: {
    head: [
      'Composition data',
      'True ileal IAA Digestibility',
      'True ileal digestiible IAA content in mixture',
    ],
    unit: [
      '', 'g', 'g/100 g', 'mg/g protein', '-', '-', '-', '-', 'g', 'mg',
    ],
    tableHeadCell: jsons.diaas_items_text,
    tableBodyCell: jsons.diaas_items,
    
    total: {
      title: 'Totals',
      tableBodyCell: jsons.diaas_total,
    },
    
    amino_acid: {
      title: 'Amino acids: mg/g protein (total for each amino acid/total protein)',
      tableBodyCell: jsons.diaas_amino_acid,
    },
  },
  diaas_age: {
    tableHeadCell: jsons.age_group_text('DIAAS'),
    tableBodyCell: jsons.age_group,
    years: [
      'Infants (birth to 6 months)',
      'Children (6 months to 3 years)',
      'Older children, adolescents, adult',
    ],
  },
  
  //---------------------------------------------------------------------------
  age_group: {
    head: [
      '',
      'Reference patterns: mg/g protein',
      'Amino acid score for mixture: \namino acids/g protein per reference pattern',
      '',
    ],
  },
}

const TableHeadAndRow = (props) => (
  <TableHead>
    <TableRow>
      {props.children}
    </TableRow>
  </TableHead>
)

const TableBodyAndRow = (props) => (
  <TableBody>
    <TableRow2Body>
      {props.children}
    </TableRow2Body>
  </TableBody>
)

const MuiTableCellMin = (props) => {
  const {children, ...other} = props
  return (
    <TableCell style={{padding: '1px'}} {...other}>
      {children}
    </TableCell>)
}

const MuiTableCell4Head = (props) => {
  const {children, index, cell1stAlignRight, ...other} = props
  let align = "center"
  if( cell1stAlignRight !== undefined && index === 0)
    align = "right"
  return (
    <MuiTableCell align={align} className="bgc-dark-green color-white" {...other}>
      {children}
    </MuiTableCell>)
}

const TableCell4HeadTop = (props) => {
  let style = {fontSize: 16, border: '1px solid'}
  if(props.style !== undefined)
    Object.assign(style, props.style)
  // console.log(_style);
  return (
    <MuiTableCell4Head align="center" style={style} {...props}>
      {props.children}
    </MuiTableCell4Head>)
}

const TableCell4HeadUnit = (props) => {
  let style = {border: '1px solid', padding: '2px', height: '32px'}
  if(props.style !== undefined)
    Object.assign(style, props.style)
  // console.log(_style);
  return (
    <MuiTableCell4Head align="center" style={style} {...props}>
      {props.children}
    </MuiTableCell4Head>)
}

//---------------------------------------------------------------------------
const ToolPDCAASResult = ({ setInfo }) => {
  const [queryParameters] = useSearchParams()
  const organism = queryParameters.get(paths.params.organism)
  const weight = queryParameters.get(paths.params.weight)
  const method = queryParameters.get(paths.params.method)
  const arrOrganism = organism ? organism.split(',') : ''
  const arrWeight = weight ? weight.split(',').map((item) => Number(item)) : ''
  // console.log('arrOrganism', arrOrganism);
  // console.log('arrWeight', arrWeight);
  
  let mth = methods.filter(item => item.label === 'PDCAAS')
  // console.log(mth, mth[0], mth[0].method);
  let isPDCAAS, isDIAAS
  if( mth.length > 0 )
    isPDCAAS = (mth[0].method === Number(method))
  mth = methods.filter(item => item.label === 'DIAAS')
  if( mth.length > 0 )
    isDIAAS = (mth[0].method === Number(method))
  if( !isPDCAAS && !isDIAAS) {
    isPDCAAS = true
    isDIAAS = true
  }
  // console.log('isPDCAAS=', isPDCAAS, 'isDIAAS=', isDIAAS);
  
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  const words = {
    bread: breadData.create('Tools', 'PDCAAS result data output', bread3rd, 'Result'),
  }
  
  //---------------------------------------------------------------------------
  // const [output, setOutput] = useState({})
  // const [pdcaasOutput, setPDCAASOutput] = useState({})
  const [pdcaasItems, setPDCAASItems] = useState([]) //obj array
  const [pdcaasAge, setPDCAASAge] = useState([]) //obj array
  const [pdcaasTotal, setPDCAASTotal] = useState({}) //obj
  const [pdcaasAA, setPDCAASAA] = useState({}) //obj
  const [pdcaasAvg, setPDCAASAvg] = useState({}) //obj
  
  // const [diaasOutput, setDIAASOutput] = useState({})
  const [diaasItems, setDIAASItems] = useState([]) //obj array
  const [diaasAge, setDIAASAge] = useState([]) //obj array
  const [diaasTotal, setDIAASTotal] = useState({}) //obj
  const [diaasAA, setDIAASAA] = useState({}) //obj
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiResult = () => {
    const input = jsons.input(arrOrganism, arrWeight, method)
    const config = apiConfig.tool.pdcaas_result(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        // setOutput(data)
        // console.log(data)
        
        let items, age, total, aa, avg
        // PDCAAS
        const pdcaas = data[jsons.output.pdcaas]
        // console.log('pdcaas', pdcaas);
        if( pdcaas !== undefined && Object.isObject(pdcaas) && !isEmptyObjOrArray(pdcaas) ) {
          // setPDCAASOutput(pdcaas)
          items = pdcaas[spp.common.output.items]
          setPDCAASItems(items)
          // console.log('pdcaas items', items)
          age = pdcaas[jsons.method[0]] //"age_group",
          setPDCAASAge(age)
          // console.log('pdcaas age', age)
          total = pdcaas[jsons.method[1]] //"total",
          setPDCAASTotal(total)
          // console.log('pdcaas total', total)
          aa = pdcaas[jsons.method[2]] //"amino_acid",
          setPDCAASAA(aa)
          // console.log('pdcaas aa', aa)
          avg = {}
          avg[jsons.pdcaas_avg[0]] = pdcaas[jsons.pdcaas_avg[0]]
          setPDCAASAvg(avg)
          // console.log('pdcaas avg', avg)
        }
        
        //---------------------------------------------------------------------------
        // DIAAS
        const diaas = data[jsons.output.diaas]
        // console.log('diaas', diaas);
        if( diaas !== undefined && Object.isObject(diaas) && !isEmptyObjOrArray(diaas) ) {
          // setDIAASOutput(diaas)
          items = diaas[spp.common.output.items]
          setDIAASItems(items)
          // console.log('diaas items', items)
          age = diaas[jsons.method[0]] //"age_group",
          setDIAASAge(age)
          // console.log('diaas age', age)
          total = diaas[jsons.method[1]] //"total",
          setDIAASTotal(total)
          // console.log('diaas total', total)
          aa = diaas[jsons.method[2]] //"amino_acid",
          setDIAASAA(aa)
          // console.log('diaas aa', aa)
        }
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  const TableBodyCell = (props) => {
    const { tableBodyCell, children, index, isPDCAAS, isDIAAS } = props
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('children', props.children);
    // console.log(isPDCAAS, isDIAAS);
    let isAliceLeft = false, isAvg = false
    if( tableBodyCell === texts.pdcaas.tableBodyCell
      || tableBodyCell === texts.pdcaas_age.tableBodyCell
      || tableBodyCell === texts.diaas.tableBodyCell
      || tableBodyCell === texts.diaas_age.tableBodyCell )
      isAliceLeft = true
    if( tableBodyCell === texts.pdcaas.avg.tableBodyCell
      || tableBodyCell === texts.diaas.total.tableBodyCell)
      isAvg = true
      
    let value, text, align
    return (
      tableBodyCell.map((cell, index2) => {
        value = children[cell]
        text = value
        if( cell === jsons.age_group[0] ) { //'Age group (years)',
          if(isPDCAAS)
            text = texts.pdcaas_age.years[index]
          if(isDIAAS)
            text = texts.diaas_age.years[index]
        }
        if( index2 !== 0 && isAvg && value === undefined )
          text = '-'
        // console.log(index, 'cell=', cell, 'text=', text);
        
        align = "center"
        if(index2 === 0 && isAliceLeft)
          align = "left"
        
        return (
          <MuiTableCell key={index+''+index2+''+cell} align={align}>
            {text}
          </MuiTableCell>)
      })
  )}
    
  //---------------------------------------------------------------------------
  useEffect(() => {
    apiResult()

    return () => {
    };
  }, []);
  
  return (
    <LayoutPage bread={words.bread}>
      <div className={scssTools.layout}>
        <div className={csScss.main}>
          
          {/* PDCAAS */}
          {!isPDCAAS ? null
          : <>
              <div className={csScss["section_title"]}>{texts.process[0]}</div>
              <div className={csScss.frame_block}>
                <MuiTableContainer>
                  <TableHeadAndRow>{/* text */}
                    {texts.pdcaas.title.map((item, index) => {
                      let col = [7, 6]
                      return <TableCell4HeadTop key={item} colSpan={col[index]}>
                        {item}</TableCell4HeadTop>
                    })}
                  </TableHeadAndRow>
                  <TableHeadRow className="bgc-dark-green">
                    {texts.pdcaas.tableHeadCell.map((title, index) => (
                      <MuiTableCell4Head key={index} index={index}>{title}</MuiTableCell4Head>
                    ))}
                  </TableHeadRow>
                  <TableHeadRow className="bgc-dark-green">{/* unit */}
                    {texts.pdcaas.unit.map((title, index) => {
                      let col = [1, 1, 1, 4, 1, 1, 4]
                      return <TableCell4HeadUnit key={index} index={index} colSpan={col[index]}>
                        {title}</TableCell4HeadUnit>
                    })}
                  </TableHeadRow>
                  <TableBody>{/* value */}
                    {pdcaasItems.map((item, index) => (
                      <TableRow2Body key={index}>
                        <TableBodyCell key={index} index={index} tableBodyCell={texts.pdcaas.tableBodyCell}>
                          {item}
                        </TableBodyCell>
                      </TableRow2Body>
                    ))}
                  </TableBody>
                  
                  <TableBodyAndRow>{/* Totals */}
                    <MuiTableCell colSpan={7+1}>{texts.pdcaas.total.title}</MuiTableCell>
                    <TableBodyCell tableBodyCell={texts.pdcaas.total.tableBodyCell}>
                      {pdcaasTotal}
                    </TableBodyCell>
                  </TableBodyAndRow>
                  
                  <TableBodyAndRow>{/* Amino acids */}
                    <MuiTableCell colSpan={7+1}>{texts.pdcaas.amino_acid.title}</MuiTableCell>
                    <TableBodyCell tableBodyCell={texts.pdcaas.amino_acid.tableBodyCell}>
                      {pdcaasAA}
                    </TableBodyCell>
                  </TableBodyAndRow>
                  
                  <TableBodyAndRow>{/* Weighted average */}
                    <MuiTableCell colSpan={7}>{texts.pdcaas.avg.title}</MuiTableCell>
                    <TableBodyCell tableBodyCell={texts.pdcaas.avg.tableBodyCell}>
                      {pdcaasAvg}
                    </TableBodyCell>
                  </TableBodyAndRow>
                </MuiTableContainer>
                
                {/* Age group */}
                <Space />
                <MuiTableContainer>
                  <TableHeadAndRow>{/* text */}
                    {texts.age_group.head.map((item, index) => {
                      let col = [1, 4, 4, 1]
                      return <TableCell4HeadTop key={index} colSpan={col[index]}>{item}</TableCell4HeadTop>
                    })}
                  </TableHeadAndRow>
                  <TableHeadRow className="bgc-dark-green">
                    {texts.pdcaas_age.tableHeadCell.map((title, index) => (
                      <MuiTableCell4Head key={index} index={index}>{title}</MuiTableCell4Head>
                    ))}
                  </TableHeadRow>
                  <TableBody>{/* value */}
                    {pdcaasAge.map((item, index) => (
                      <TableRow2Body key={index}>
                        <TableBodyCell key={index} index={index} isPDCAAS={true}
                          tableBodyCell={texts.pdcaas_age.tableBodyCell}>
                          {item}
                        </TableBodyCell>
                      </TableRow2Body>
                    ))}
                  </TableBody>
                </MuiTableContainer>
              </div>
            </>}
          
          {/* //--------------------------------------------------------------------------- */}
          {/* DIAAS */}
          {!isDIAAS ? null
          : <>
              {isPDCAAS ? <Box sx={{ mt: 6 }} /> : null}
              <div className={csScss["section_title"]}>{texts.process[1]}</div>
              <div className={csScss.frame_block}>
                <MuiTableContainer>
                  <TableHeadAndRow>{/* text */}
                    {texts.diaas.head.map((item, index) => {
                      let col = [7, 5, 4]
                      return <TableCell4HeadTop key={item} colSpan={col[index]}>
                        {item}</TableCell4HeadTop>
                    })}
                  </TableHeadAndRow>
                  <TableHeadRow className="bgc-dark-green">
                    {texts.diaas.tableHeadCell.map((title, index) => (
                      <MuiTableCell4Head key={index} index={index} style={{padding: 4}}>
                        {title}</MuiTableCell4Head>
                    ))}
                  </TableHeadRow>
                  <TableHeadRow className="bgc-dark-green">{/* unit */}
                    {texts.diaas.unit.map((title, index) => {
                      let col = [1, 1, 1, 4, 1, 1, 1, 1, 1, 4]
                      return <TableCell4HeadUnit key={index} index={index} colSpan={col[index]}>
                        {title}</TableCell4HeadUnit>
                    })}
                  </TableHeadRow>
                  <TableBody>{/* value */}
                    {diaasItems.map((item, index) => (
                      <TableRow2Body key={index}>
                        <TableBodyCell key={index} index={index} tableBodyCell={texts.diaas.tableBodyCell}>
                          {item}
                        </TableBodyCell>
                      </TableRow2Body>
                    ))}
                  </TableBody>
                  
                  <TableBodyAndRow>{/* Totals */}
                    <MuiTableCell>{texts.diaas.total.title}</MuiTableCell>
                    <TableBodyCell tableBodyCell={texts.diaas.total.tableBodyCell}>
                      {diaasTotal}
                    </TableBodyCell>
                  </TableBodyAndRow>
                  
                  <TableBodyAndRow>{/* Amino acids */}
                    <MuiTableCell colSpan={7+5}>{texts.diaas.amino_acid.title}</MuiTableCell>
                    <TableBodyCell tableBodyCell={texts.diaas.amino_acid.tableBodyCell}>
                      {diaasAA}
                    </TableBodyCell>
                  </TableBodyAndRow>
                  
                </MuiTableContainer>
              
                {/* Age group */}
                <Space />
                <MuiTableContainer>
                  <TableHeadAndRow>{/* text */}
                    {texts.age_group.head.map((item, index) => {
                      let col = [1, 4, 4, 1]
                      return <TableCell4HeadTop key={index} colSpan={col[index]}>{item}</TableCell4HeadTop>
                    })}
                  </TableHeadAndRow>
                  <TableHeadRow className="bgc-dark-green">
                    {texts.diaas_age.tableHeadCell.map((title, index) => (
                      <MuiTableCell4Head key={index} index={index}>{title}</MuiTableCell4Head>
                    ))}
                  </TableHeadRow>
                  <TableBody>{/* value */}
                    {diaasAge.map((item, index) => (
                      <TableRow2Body key={index}>
                        <TableBodyCell key={index} index={index} isDIAAS={true}
                          tableBodyCell={texts.pdcaas_age.tableBodyCell}>
                          {item}
                        </TableBodyCell>
                      </TableRow2Body>
                    ))}
                  </TableBody>
                </MuiTableContainer>
              </div>
            </>}
          
        </div>
      </div>
    </LayoutPage>
  )
}

export default ToolPDCAASResult;
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { faker } from '@faker-js/faker';

import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import { breadData } from 'components/bread/BreadBar';
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import VennDiagram from 'components/chart/VennDiagram';
import chartProp from 'components/chart/chartProp';
import SearchResultLayout from 'layouts/search/SearchResultLayout';
import cookie from 'utils/cookie';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import VennDiagramImage from 'components/chart/VennDiagramImage';
import { isEmptyObjOrArray } from 'utils/general';
import StackedBarChart from 'components/chart/StackedBarChart';

//---------------------------------------------------------------------------
const isFakeData = false
const labelsFake = ['January', 'February', 'March'];
const dataFake = {
  labels: labelsFake,
  datasets: [
    {
      label: 'Dataset 1',
      data: labelsFake.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: 'rgb(255, 99, 132)',
    },
    {
      label: 'Dataset 2',
      data: labelsFake.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: 'rgb(75, 192, 192)',
    },
    {
      label: 'Dataset 3',
      data: labelsFake.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: 'rgb(53, 162, 235)',
    },
  ],
};

//---------------------------------------------------------------------------
// Go in: homepage -> 'Tools' tab -> 'CRS-Tool' button -> chose 'Step 01' & 'Step 02' -> click 'Search' -> this page
// path: paths.spp.tool.crs_tool_result
const jsonsResult = spp.tool.crs_tool_result
const ToolCRSToolResultLv1 = ({ setInfo }) => {
  /* Table related */
  const [queryParameters] = useSearchParams()
  const source = queryParameters.get(paths.params.source)
  const cls = queryParameters.get(paths.params.class)
  const sourceArr = source ? source.split(',') : ''
  const clsArr = cls ? cls.split(',') : ''

  const clsNameStr = cookie.getCookie(cookie.keys.tool.crsSelectCls)
  const sourceNameStr = cookie.getCookie(cookie.keys.tool.crsSelectSource)
  const clsName = clsNameStr.split(', ')
  const sourceName = sourceNameStr.split(', ')
  // console.log('sourceNameStr:', sourceNameStr);
  // console.log('sourceName:', sourceName);
  // console.log('clsName:', clsName, ' sourceName:', sourceName);
  
  let jsons = {}
  if( cls === "multifunctionalpeptides" ) {
    jsons.tableHeadCell = jsonsResult.items_text_multi
    jsons.tableBodyCell = jsonsResult.items_multi
  } else {
    jsons.tableHeadCell = jsonsResult.items_text
    jsons.tableBodyCell = jsonsResult.items
  }
  
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  const breadText = `${sourceNameStr} protein result`
  // const breadText = '123456789012345678901234567890123456789012345_7890123456'
  let breadTextFontSize = breadData.getFontSize(breadText)
  let texts = {
    bread: breadData.create('Tools', breadText, bread3rd, 'Result', '', breadTextFontSize),

    searchHint: 'Search by SPP ID, AA sequence, Peptide name, AA length, Precursor protein, Pubdate',
    tableHeadCell: jsons.tableHeadCell,
    tableBodyCell: jsons.tableBodyCell,
  }

  //---------------------------------------------------------------------------
  /* Bar chart related */
  let barChartWords = {
    title: `The sequence counts of selected protein(s) and ${clsNameStr} peptide`,
    subTitle: "",
    x: 'Protein Source',
    y: 'Count',
  }
  
  // https://flatuicolors.com/palette/fr
  const bgColors = [
    "#82ccdd", "#b8e994", "#fad390", "#f8c291", "#6a89cc", 
    "#60a3bc", "#78e08f", "#f6b93b", "#e55039", "#4a69bd",  
    "#3c6382", "#38ada9", "#fa983a", "#eb2f06", "#1e3799",  
    "#0a3d62", "#079992", "#e58e26", "#b71540", "#0c2461", 
  ];
  // const [barChartTotal, setBarChartTotal] = useState(0);
  const [barChartItems, setBarChartItems] = useState([]);
  const [barChartLabels, setBarChartLabels] = useState([]);
  const [barChartValue, setBarChartValue] = useState([]);
  const [barChartDataset, setBarChartDataSet] = useState([])
  let barChartData = (chartProp.data.carateMulti(
    "",
    "",
    barChartLabels,
    barChartDataset,
  ))
  if( isFakeData )
    barChartData = dataFake
  const barChartOptions = {}
  function handleClickBar(e, elements) {
    if (elements.length === 0)
      return;
    
    // console.log('elements', elements);
    // let datasetIndex = elements[0].datasetIndex;
    // let dataIndex = elements[0].index;
    // let datasetLabel = e.chart.data.datasets[datasetIndex].label;
    // let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
    // let label = e.chart.data.labels[dataIndex];
    // console.log(">onClick", datasetLabel, label, value);
    
    const index = elements[0].index
    const source = sourceArr[index]
    const name = sourceName[index]
    // console.log('index=', index);
    // console.log('name=', name, 'source=', source);
    const datasetIndex = elements[0].datasetIndex
    const cls = clsArr[datasetIndex]
    const nameCls = clsName[datasetIndex]
    // console.log('nameCls=', nameCls, 'cls=', cls);
    cookie.setCookie(cookie.keys.tool.crsSelectSourceLv3, name)
    navigate(paths.spp.tool.crs_tool_result_lv3(source, {cls: cls}));
  }
  function handleClickLabel(e, elements, index) {
    if (elements.length === 0)
      return;
    
    const source = sourceArr[index]
    const name = sourceName[index]
    // console.log('index=', index);
    // console.log('name=', name, 'source=', source);
    cookie.setCookie(cookie.keys.tool.crsSelectSourceLv2, name)
    navigate(paths.spp.tool.crs_tool_result_lv2(source, {cls: cls}));
  }
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const jsonsChart = spp.tool.crs_tool_result_lv1_bar_chart
  const apiBarChartData = () => {
    const input = jsonsChart.input(sourceArr, clsArr)
    const config = apiConfig.tool.crs_tool_result_lv1_bar_chart(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let output = result.data
        // setBarChartTotal(output[jsonsChart.output[0]])

        let data = result.data[spp.common.output.items]
        setBarChartItems(data)
        // console.log('data', data);
        setBarChartValue(data.map(item => item[jsonsChart.items[2]])) //"count
        // setBarChartLabels(data.map(item => item[jsonsChart.items[0]])) //"source_name"
        let label
        setBarChartLabels(data.map(item => {
          label = `${item[jsonsChart.items[0]]} (` //"source_name"
          let count = item[jsonsChart.items[3]] //"count_list",
          //let list = item[jsonsChart.items[4]] //"label_list",
          label += count.join('+')
          // console.log('label', label);
          label += '='
          label += item[jsonsChart.items[2]] //"count",
          label += ')'
          return label
        }))
        
        let peptide = output[jsonsChart.output[1]] //"peptide_count"
        let display, count, table, color
        let dataset = []
        if( Array.isArray(peptide) && peptide.length > 0 ) {
          peptide.forEach((item, index) => {
            // console.log('item', item);
            // table = item[jsonsChart.peptide_count[0]] //"table_name",
            display = item[jsonsChart.peptide_count[1]] //"display_name",
            count = item[jsonsChart.peptide_count[3]] //"table_count",
            color = index % bgColors.length
            dataset.push(chartProp.data.singleDataset(
              display, count, bgColors[color], bgColors[color]))
          })
        }
        setBarChartDataSet(dataset)
        // console.log('dataset', dataset);
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  /* Venn diagram related */
  let vennDiagramWords = {
    title: `Protein sources with ${clsNameStr} peptide sequences`,
    subTitle: "",
  }
  
  // const [vennDiagramTotal, setVennDiagramTotal] = useState(0);
  const [vennDiagramItems, setVennDiagramItems] = useState([])
  const [vennDiagramData, setVennDiagramData] = useState({})
  
  const [stateVenn, setStateVenn] = useState(axiosState.init())
  const jsonsVenn = spp.tool.crs_tool_result_lv1_venn_diagram_all
  const apiVennDiagramData = () => {
    const input = jsonsVenn.input(sourceArr, clsArr)
    const config = apiConfig.tool.crs_tool_result_lv1_venn_diagram_all(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateVenn(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setStateVenn(axiosState.resultCode200())
        // console.log(result.data);
        
        // let output = result.data
        // setVennDiagramTotal(output[jsonsVenn.output[0]])
        
        let items = result.data[spp.common.output.items]
        // setVennDiagramItems(items)
        // console.log('items', items);
        
        let labels = []
        if( items !== undefined )
          items.forEach((item) => (labels.push(item[jsonsVenn.items[1]])))
        let data = {}
        data.data = items
        data.labels = labels
        setVennDiagramData(data)
        // console.log('data', data);
      }
    }).catch(err => {
      setStateVenn(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  useEffect(() => {
    apiBarChartData()
    apiVennDiagramData()

    return () => {
    };
  }, []);

  return (
    <>
      <SearchResultLayout
        setInfo={setInfo}
        texts={texts}
        configSearch={apiConfig.tool.crs_tool_result} //86
        jsonInput={jsonsResult.input}
        inputProp={[sourceArr, clsArr]}
        jsonInputKeyword={jsonsResult.input_keyword}
        barChart={<ChartSection
          chartBlockTitle={barChartWords.title}
          chartBlockSubTitle={barChartWords.subTitle}
          // chartBlockSubTitleNumber={barChartTotal}
          mainChart={
            <StackedBarChart
              data={barChartData}
              options={barChartOptions}
              needHoverStyle={true}
              // tooltipCRSresult={barChartItems}
              handleClickBar={handleClickBar}
              handleClickLabel={handleClickLabel}
              showTooltipTitle={false}
            />
          } />}
        vennDiagram={ sourceArr.length > 1
          ? <ChartSection
              chartBlockTitle={vennDiagramWords.title}
              chartBlockSubTitle={barChartWords.subTitle}
              // chartBlockSubTitleNumber={vennDiagramTotal}
              paddingTopOfChild="60px"
              mainChart={
                <div>
                <VennDiagramImage
                  data={vennDiagramData}
                  // options={vennOptions}
                  // items={vennDiagramItems}
                  source={source}
                  cls={cls}
                  />
                </div>
              } />
          : null}
      />
    </>
  )
}
export default ToolCRSToolResultLv1
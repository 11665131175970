import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormControl, FormGroup, FormHelperText, Grid, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link, } from 'react-router-dom';

import _v from "assets/scss/_variables.scss";
import scssLogin from "./Login.module.scss";
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import cookie from "utils/cookie";
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch } from "utils/network/axios";
import jsons from "utils/network/jsons";
import Navbar from 'layouts/Navbar';
import { RouterLink } from 'components/router/RouterLink';
import StyledTextField from 'components/tool/StyledTextField';


//---------------------------------------------------------------------------
const texts = {
  title: 'Login',
  need: 'Need an account? ',
  signup: 'Sign up',
  forgot: 'Forgot',
}

const Login = ({ setInfo }) => {
  // console.log('Login', setInfo);
  // console.log(endpoint.user.login);
  // console.log(endpoint.user.logins.log);

  const [email, setEmail] = useState({
    value: "",
    isErr: false,
    errMsg: ""
  });

  const [passwd, setPasswd] = useState({
    value: "",
    isErr: false,
    errMsg: ""
  });

  const [showPasswd, setShowPasswd] = useState(false)

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    let passAmount = 0
    let fieldList = []
    fieldList.push(email)
    fieldList.push(passwd)

    fieldList.forEach(item => {
      if (item.value !== "" && !item.isErr) passAmount++
    })

    // console.log('fieldList.length', fieldList.length);
    if (passAmount === fieldList.length) {
      setIsSubmitEnabled(true)
    } else {
      setIsSubmitEnabled(false)
    }

  }, [email, passwd]);

  const maxLenStr = (number) => {
    return `Maximum length is ${number}`
  }
  const emailHandler = (value) => {
    /* eslint-disable-next-line */
    const emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    /* eslint-disable-next-line */
    const isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(value)

    // validated or not
    if (value.search(emailRule) !== -1 && isValidEmail) {
      if (value.length > 50) {
        setEmail({ value: value, isErr: true, errMsg: maxLenStr(50) })
      } else {
        setEmail({ value: value, isErr: false, errMsg: "" })
      }

    } else {
      setEmail({ value: value, isErr: true, errMsg: "Invalid format" })
    }
  }

  const passwdHandler = (value) => {
    setPasswd({ ...passwd, value: value })
  }
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && isSubmitEnabled) {
      loginEvent()
    }
  }

  const navigate = useNavigate();

  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  let redirectPath = queryParams.get('redirect');

  const loginEvent = () => {
    setIsLoggingIn(true);

    let dataToSend = jsons.auth.login.input(
      // username.value,
      // userID.value,
      email.value,
      passwd.value)
    const config = apiConfig.auth.login(dataToSend)
    // console.log(config);

    axios(config)
      .then(result => {
        setIsLoggingIn(false);
        // console.log("Login result:", result)
        switch (result.data.result_code) {
          case 200:
            cookie.setCookie(cookie.keys.auth.token, result.data[jsons.auth.response.token])
            cookie.setCookie(cookie.keys.auth.id, result.data[jsons.auth.response.id])
            cookie.setCookie(cookie.keys.auth.email, email.value)
            cookie.setCookie(cookie.keys.auth.nickname, result.data[jsons.auth.login.output.nickname])
            setInfo(snackInfo.open(snackType.success, "Login Successfully"))

            if (redirectPath !== "" && redirectPath !== undefined) {
              navigate(redirectPath)
            }else{
              navigate(paths.site.home)
            }
            break;
          default:
            setInfo(snackInfo.open(snackType.error, result.data.message))
        }

      }).catch(err => {
        setIsLoggingIn(false);
        console.error("Login error:", err)
        let msg = axiosCatch.getMsg(err)
        let code = axiosCatch.getResultCode(err)
        // console.log('msg', msg);
        setInfo(snackInfo.open(snackType.error, msg))
        if( code === 911 || code === 913 ) {
          cookie.removeCookie(cookie.keys.auth.token)
        }
      })
  }

  return (
    <Grid container className={scssLogin.main} >
      <Grid
        container
        item
        className={scssLogin.content}
        xs={7}
      // sm={8}
      // md={7}
      >
        <Grid className={scssLogin.logo}>{texts.title}</Grid>
        <FormGroup className={scssLogin.info_text_filed}>
          <StyledTextField
            // className={scssLogin.input_field}
            multiline={false}
            required
            id="email-required"
            placeholder="E-mail"
            autoFocus
            fullWidth
            value={email.value}
            onChange={(event) => { emailHandler(event.target.value) }}
            onKeyUp={handleKeyPress}
            helperText={email.errMsg}
            error={email.isErr}
          />


          <FormControl variant="outlined"
            sx={{'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: _v.green,
                },
            }}
          >
            <OutlinedInput
              // className={scssLogin.input_field}
              required
              type={showPasswd ? 'text' : 'password'}
              id="passwd-required"
              placeholder="Password"
              value={passwd.value}
              onChange={(event) => { passwdHandler(event.target.value) }}
              onKeyUp={handleKeyPress}
              error={passwd.isErr}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPasswd(!showPasswd)

                    }}
                    // onMouseDown={(event) => { handleMouseDownPassword(event) }}
                    edge="end"
                  >
                    {showPasswd ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {passwd.isErr && <FormHelperText sx={{ color: "#d32f2f" }}>{passwd.errMsg}</FormHelperText>}
          </FormControl>
        </FormGroup>

        <Grid className={scssLogin.login_btn_block}>
          <LoadingButton
            className={scssLogin.login_btn}
            variant="contained"
            size="small"
            // disabled={
            //     !isNextStepBtnEnabled() || !isUsernamePass()
            // }
            sx={{ backgroundColor: `${true ? _v.mainColor : _v.mainGrey}` }}
            disabled={!isSubmitEnabled}
            onClick={() => { loginEvent() }}
            loading={isLoggingIn}
          >Login</LoadingButton>
        </Grid>

        <Grid className={scssLogin.bottom_block}>
          <span style={{ marginBottom: "10px" }}>{texts.need}
            <RouterLink to={paths.auth.signup} sx={{ ml: "5px" }}>{texts.signup}</RouterLink></span>
          {/* <RouterLink to="#">{texts.forgot}</RouterLink> */}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Login
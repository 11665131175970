// import React from 'react';
import { CircularProgress } from '@mui/material';

import scssCommon from 'assets/scss/common.scss'
import v from 'assets/scss/_variables.scss'
import scss from './LoadingAnime.module.scss'

//---------------------------------------------------------------------------
const LoadingAnime = (props) => (
  <div className={scss.loading_block}>
    <CircularProgress className={scssCommon.loading_circle} size={30} style={{ color: v.darkGreen }} />
  </div>
)

export default LoadingAnime
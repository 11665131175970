/* 
Line Chart | react-chartjs-2
https://react-chartjs-2.js.org/examples/line-chart
Line Chart | Chart.js
https://www.chartjs.org/docs/latest/charts/line.html
*/
import { Line } from 'react-chartjs-2';

import Text from 'components/tag/Text';

//---------------------------------------------------------------------------
const LineChart = ({ data, options, ...props }) => {
  let _options = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        display: true, //hide labels
        // position: 'top', //top, bottom, left, right
        // align: 'center', //start, center, end
        // onHover: handleHover,
        // onLeave: handleLeave,
        // onClick: handleClick,
      },
      /* title: {
          display: true,
          // align: 'center', //start, center, end
          text: chartData.title,
      },*/
      /* subtitle: {
          display: true,
          text: chartData.text,
          color: 'blue',
          font: {
              size: 12,
              family: 'tahoma',
              weight: 'normal',
              // style: 'italic'
          },
          padding: {
              bottom: 10
          }
      }, */
    },
    borderWidth: 1,
  }
  if (options !== undefined) {
    Object.assign(_options, options)
  }

  return (
    <div style={{ width: '90%' }}>
      <Text center><h2>{data.title}</h2></Text>
      <Text center style={{ color: 'gray' }}><h4>{data.text}</h4></Text>
      <Line
        data={data}
        options={_options}
        {...props} />
    </div>
  )
}
export default LineChart
import React from 'react'
import { useState, useEffect, } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';

import scss from './UserTerms.module.scss';
import { breadData } from 'components/bread/BreadBar';
import LayoutPage from 'layouts/LayoutPage';
import links from 'utils/links';
import cookie from 'utils/cookie';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import jsons, { spp } from 'utils/network/jsons';
import { TextLink } from 'components/tag/Text';

//---------------------------------------------------------------------------
const texts = {
  bread: breadData.create('Terms & Conditions', 'Terms & Conditions'),
}

// const isChecked = cookie.getCookie(cookie.keys.auth.isChecked) === 'false';

const agreeButtonClick = () => {
  const cookieTemp = cookie.setCookie(cookie.keys.auth.isChecked, true)
};

const disagreeButtonClick = () => {
  const cookieTemp = cookie.setCookie(cookie.keys.auth.isChecked, false)

};
//---------------------------------------------------------------------------
const UserTerms = ({ setCheckbox, setInfo }) => {
  const [termsContent, setTermsContent] = useState("")
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const getTNC = () => {
    const config = apiConfig.auth.get_tnc()
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let item = result.data[jsons.auth.tnc.output.content]
        setTermsContent(item)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  useEffect(() => {
    if (axiosState.keepRest(state))
      getTNC()
  }, [termsContent])

  let tempTerms = termsContent;
  const wrapTerms2 = tempTerms.split('\\n');
  
  const css = {
    text_button: 'b2strong-darkGreen',
  }
  
  return (
    <LayoutPage bread={texts.bread}>
      <div className={scss.layout}>
        <div className={scss.terms_content}>
          {wrapTerms2.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
        <div className={scss.frame_button}>
          <button className={scss.button_agree}
            onClick={agreeButtonClick}
          >
            {
              <TextLink className={css.text_button} link={links.auth.terms.agree}></TextLink>
            }
          </button >
          <button className={scss.button_disagree}
            onClick={disagreeButtonClick}>
            {
              <TextLink className={css.text_button} link={links.auth.terms.disagree}></TextLink>
            }
          </button >

        </div>
      </div>
    </LayoutPage>
  )
}

export default UserTerms;
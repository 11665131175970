// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
// import { styled } from '@mui/material/styles';
// import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

import 'assets/scss/index.scss';
import scss from './Footer.module.scss';
import imgs from 'utils/imgs';
import links from 'utils/links';
import Text, { TextLink } from 'components/tag/Text';
import Img, { ImgLink } from 'components/tag/Img';

//---------------------------------------------------------------------------
const texts = {
  logo: '[Client Logo]',
  phone: '4006-888-888',
  email: 'cs@amway.com',
  // download: 'Download PDF',
  // please: 'Please cite: Qin D, Bo W, Zheng X, Hao Y, Li B, Zheng J, Liang G. SmartPP: A Comprehensive Database of \n'+
  //     'Food-Derived Bioactive Peptides for Peptidomics Research. Bioinformatics, 38(12), 2022, 3275-3280.',
  amway: '© 2023 Amway. All Rights Reserved. ICP No. 00000000',
}
/* 
function clientData(phone, email) {
  return {phone, email}
}

const clients = [
    clientData('000-000-0000', 'hello@amway.com'),
]
 */

//---------------------------------------------------------------------------
export default function Footer() {
  
  const css = {
    text_map: 'size20strong-white',
    text_bottom: 'b2-white',
    text_bottom_link: 'b2-white link',
  }
  
  return (
    <div className={scss.layout}>
      <div className={scss.frame_top}>
        <div className={scss.top_client}>
          <ImgLink imgName={imgs.site.footer.logo} link={links.site.nav.home} height='45px'></ImgLink>
        </div>
        <div className={scss.top_map}>
          <TextLink className={css.text_map} link={links.site.nav.proteins}></TextLink>
          <TextLink className={css.text_map} link={links.site.nav.peptides}></TextLink>
          <TextLink className={css.text_map} link={links.site.nav.products}></TextLink>
          <TextLink className={css.text_map} link={links.site.nav.tools}></TextLink>
          <TextLink className={css.text_map} link={links.site.nav.about}></TextLink>
        </div>
        <div className={scss.top_icon}>
          {/* <Img imgName={imgList.footer.instagram}></Img>
          <Img imgName={imgList.footer.facebook}></Img>
          <Img imgName={imgList.footer.twitter}></Img>
          <Img imgName={imgList.footer.youtube}></Img> */}
        </div>
      </div>

      {/* <div className={scss.frame_center}>
      </div> */}

      <div className={scss.frame_bottom}>
        <Text className={css.text_bottom}>{texts.amway}</Text>
        <Img imgName={imgs.site.footer.line} />
        <Text className={css.text_bottom_link}>Tel:  <a href={`tel:${texts.phone}`} className={css.text_bottom}>{texts.phone}</a></Text>
        <Img imgName={imgs.site.footer.line} />
        <Text className={css.text_bottom_link}><a href={`mailto:${texts.email}`}  style={{ color: 'white' }}>{texts.email}</a></Text>
        <Img imgName={imgs.site.footer.line} />
        <TextLink className={css.text_bottom_link} link={links.site.footer.privacy}></TextLink>
        <Img imgName={imgs.site.footer.line} />
        <TextLink className={css.text_bottom_link} link={links.site.footer.terms}></TextLink>
      </div>
    </div>
  )
}
import TableBody from '@mui/material/TableBody';
// import TableHead from '@mui/material/TableHead';
import { Button, Checkbox, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// import { Box, Popper, TableHead } from '@mui/material';
// import { TableCell } from '@mui/material';
import { CategoryScale } from 'chart.js';
import Chart from "chart.js/auto";
// import { styled } from '@mui/material/styles';

import 'assets/scss/common.scss';
import 'assets/scss/index.scss';
import 'assets/scss/temp.scss';
import v from "assets/scss/_variables.scss";
import scss from 'views/tool/Tools.module.scss';
import _tmcaScss from './ToolMultiCrossAnalysis.module.scss';

// import { NoneDiv } from 'components/GreenDiv';
import LoadingAnime from 'components/LoadingAnime';
// import { TableHeadRow, TableRow2Body } from 'components/MuiTable';
import SnackBar, { emptyResult, snackInfo, snackType } from 'components/SnackBar';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
// import paths from 'utils/network/apiPath';
// import linkList from 'utils/linkList';
import { breadData } from 'components/bread/BreadBar';
import { TagText } from 'components/tag/Text';
import { MuiTableContainer } from "components/table/MuiTable";
import AmDonutChartV5 from 'components/chart/AmDonutChart_v5';
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import chartProp from 'components/chart/chartProp';
import GreenButton from 'components/button/GreenButton';
import WhiteButton from 'components/button/WhiteButton';
import DenseStyledTableCell from 'components/table/DenseStyledTableCell';
import cookie from 'utils/cookie';
import { getFullPath, isEmptyObjOrArray } from 'utils/general';
import paths from 'utils/network/apiPath';
import LayoutPage from 'layouts/LayoutPage';
import AmDonutChartV4 from 'components/chart/AmDonutChart_v4';
import { sortByKey } from 'utils/object';
import { getRow } from 'components/table/table';
import MuiCheckbox from 'components/mui/MuiCheckbox';
import { linkData } from 'utils/links';

//---------------------------------------------------------------------------
// Go in: 'Protein' page -> any card button -> any 'SPP ID' -> this page
// path: paths.spp.tool.multi_cross_analysis(),
const jsons = spp.protein.search.id

const fontSizeOfLongText = 40;
const texts = {
  bread: breadData.create('Tools', 'Multifunctional Peptide Cross Analysis (Multi-cross analysis)',
    'Multi-cross analysis', '', '', fontSizeOfLongText),

  desc: "Multi-cross analysis is a tool for cross-searching 30 kinds of bioactive peptides. It's helpful for discovery of multifunctional peptides and their mechanism of action. Through Multi-cross analysis of 30 bioactive peptides, 29 of them were found to have multifunctional properties.",

  dfbp: {
    title: 'SmartPP ID - ',
    // 'SPP ID', 'Protein name', 'Type', 'Length',
    head: spp.protein.search.id.output_text.slice(0, 4),
    json: spp.protein.search.id.output.slice(0, 4),
  },

  names: {
    title: 'Names & Taxonomy',
    // 'Classification', 'Organism', 'Protein name',
    head: //4, 5, 1
      spp.protein.search.id.output_text.slice(4, 6).concat(
        spp.protein.search.id.output_text.slice(1, 2)
      ),
    json:
      spp.protein.search.id.output.slice(4, 6).concat(
        spp.protein.search.id.output.slice(1, 2)
      ),
  },

  protein: {
    title: 'Protein sequence',
    head: spp.protein.search.id.output_text.slice(6, 8),
    json: spp.protein.search.id.output.slice(6, 8),
  },

  table: {
    title: 'Multifunctional peptide list and related analysis',
    head: [
      'Peptide List',
    ],
    json: ['',],

    list: {
      head: ['No.', 'Classification', 'Multifunction', 'Peptide', 'Ratio'],
      cell: spp.protein.search.id.items,
    },
  },

  statistics: {
    title: 'Statistics View: Sequence coding analysis of bioactive peptides encoded in Transforming growth factor beta-2 proprotein',
  },

  enzymatic: {
    title: 'Enzymatic hydrolysis prediction',
    head: [
      'Enzymatic Hydrolysis',
    ],
    json: ['',],
    link: 'SPP---Enzymatic Hydrolysis Prediction Tool (EHP-Tool)',
  },

  cross: {
    title: 'Cross-references',
    head: [
      spp.protein.search.id.output_text[9], // 'UniProt',
    ],
    json: [
      spp.protein.search.id.output[9],
    ],
  },
}

const chartTypeBtnList = [
  "Histogram",
  // "Line chart",
  "Pie chart"
]

function rowData(name, value) {
  return { name, value };
}

const MuiTableCell = (props) => (
  <DenseStyledTableCell style={{ whiteSpace: 'pre-line', paddingRight: "3px", paddingLeft: `${props.children === "Classification" ? '15px' : '3px'}` }} {...props}>
    {props.children}
  </DenseStyledTableCell >
)

const MuiTableHeadCell = (props) => (
  <MuiTableCell className="bgc-dark-green color-white table_header"
    isHeader={true}
    {...props}>
    {props.children}
  </MuiTableCell>

)

Chart.register(CategoryScale);
Chart.defaults.font.size = 16;
//---------------------------------------------------------------------------
const ToolMultiCrossAnalysis = ({ setInfo }) => {
  const [queryParameters] = useSearchParams()
  const indexOfDFBPID = queryParameters.get(paths.params.id)
  let input = spp.protein.search.id.input(indexOfDFBPID) //17
  // console.log(input);

  const [rows, setRows] = useState(getRows(texts, ''))
  function getRows(texts, output) {
    return ([
      getRow(texts.dfbp, output),
      getRow(texts.names, output),
      getRow(texts.protein, output),
      [rowData(texts.table.head[0], '')],
      [rowData(texts.enzymatic.head[0], texts.enzymatic.link)], //getRows(texts.enzymatic, output),
      getRow(texts.cross, output),
    ])
  }
  
  let location = useLocation()
  let bread = linkData('Multi-cross analysis', getFullPath(location))
  let json3rd = JSON.stringify(bread)
  // console.log('bread3rd', bread);
  cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
  
  //---------------------------------------------------------------------------
  const [totalMultiFunPeptides, setTotalMultiFunPeptides] = useState(0)
  const [totalPeptidesSeq, setTotalPeptidesSeq] = useState(0)
  
  const words = {
    peptideClsStatistics: {
      title: 'Multifunctional peptide classification statistics',
      x: '',
      y: 'Peptide count',
    },

    statisticsOfMultiPeptide: {
      title: 'The function statistics of multifunctional peptides',
      text: `Total multifunctional peptide sequence: ${totalMultiFunPeptides}`,
      x: '',
      y: 'Peptide count',
    }
  }
  
  //---------------------------------------------------------------------------
  const [clsStatisticsObj, setClsStatisticsObj] = useState({}) //all of output
  const [list, setList] = useState({}) //peptide_list data
  // const [items, setItems] = useState([]) //items data in peptide_list
  const [peptideClsStatisticsLabels, setPeptideClsStatisticsLabels] = useState([])
  const [peptideClsStatisticsValue, setPeptideClsStatisticsValue] = useState([])
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  const apiResult = () => {
    const config = apiConfig.peptide.get_multi_cross()

    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        const output = result.data

        if (isEmptyObjOrArray(output["division"]) || isEmptyObjOrArray(output["table name"])) {
          setInfo(snackInfo.open(snackType.info, emptyResult))
          return null;
        }
        
        let obj = result.data.multifunction
        let multifunction = sortByKey(obj)
        // console.log('multifunction', obj);
        // console.log("multifunction'", multifunction);

        const labels = Object.keys(multifunction).map(item => item);
        const value = Object.values(multifunction).map(item => item);

        setPeptideClsStatisticsLabels(labels)
        setPeptideClsStatisticsValue(value)


        handlePieChartData(labels, value)

        let num = 0;

        const selectedPeptideClsIdList = cookie.getCookie(cookie.keys.peptide.selectedMultiPeptideList)
        setMultifunctionalPeptideListForTable(
          Object.keys(multifunction).map((key) => {
            num++;
            return {
              id: result.data["id"][key],
              num: num,
              cls: key,
              table_name: result.data["table name"][key],
              multifunction: result.data["multifunction"][key],
              peptide: result.data["peptide"][key],
              ratio: parseFloat(result.data["division"][key]),
              isCheck: selectedPeptideClsIdList.includes(result.data["id"][key]),
            };
          })
        )

        setClsStatisticsObj(output)
        const list = result.data[spp.protein.search.id.output[8]] //'peptide_list'

        setList(list)
        // console.log(list);
        // console.log(spp.protein.search.id.peptide_list[0]);
        // let item = list[spp.protein.search.id.peptide_list[0]] //'items'
        // setItems(item)

        setRows(getRows(texts, output))
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  const formulaBlockDataObj = (multiPeptidesCounts, totalPeptideSeq, ratio) => {
    return {
      multiPeptidesCounts, totalPeptideSeq, ratio
    }
  }
  const [formulaBlockData, setFormulaBlockData] = useState(formulaBlockDataObj((0, 0, 0)))
  
  const [stateMulti, setStateMulti] = useState(axiosState.init())
  const getMultiCrossFunction = () => {
    const config = apiConfig.peptide.get_multi_cross_function()

    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateMulti(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setStateMulti(axiosState.resultCode200())
        // console.log(result.data);

        const data = result.data
        if (isEmptyObjOrArray(data["item"])) {
          setInfo(snackInfo.open(snackType.info, emptyResult))
          return null;
        }

        setTotalMultiFunPeptides(data.multi_total)
        setTotalPeptidesSeq(data.total)

        setFormulaBlockData(formulaBlockDataObj(data.multi_total, data.total, ((data.multi_total / data.total) * 100).toFixed(2)))

        setPeptideMultiFunLabels(Object.keys(data.item).map(item => `${item} functions`))
        setPeptideMultiFunValue(Object.values(data.item).map(item => item))
      }
    }).catch(err => {
      setStateMulti(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
      console.log("error2")
    })
  }

  useEffect(() => {
    if (axiosState.keepRest(state))
      apiResult()
    if (axiosState.keepRest(stateMulti))
      getMultiCrossFunction()
  }, [])
  
  //---------------------------------------------------------------------------
  const rowChildren = (row) => {
    return (
      <TableRow key={row.num}>
        <MuiTableCell align="center">{row.num}</MuiTableCell>
        <MuiTableCell >
          <MuiCheckbox
            checked={row.isCheck}
            onChange={(event) => {
              handleCheckBox(row.cls)
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <u className={_tmcaScss.cls_text_link} onClick={() => {
            cookie.setCookie(cookie.keys.peptide.selectedPeptideClsId, row.id)
            cookie.setCookie(cookie.keys.peptide.selectedPeptideClsName, row.cls)
            cookie.setCookie(cookie.keys.peptide.selectedPeptideClsTableName, row.table_name)

            navigate(paths.spp.peptide.fun_relationships())
          }}><TagText html={row.cls} /></u>
        </MuiTableCell>
        <MuiTableCell>{row.multifunction}</MuiTableCell>
        <MuiTableCell>{row.peptide}</MuiTableCell>
        <MuiTableCell>{row.ratio}</MuiTableCell>
      </TableRow>
    )
  }

  const handleCheckBox = (displayName) => {
    setMultifunctionalPeptideListForTable(prevItems =>
      prevItems.map((item) => {
        if (item.cls === displayName) {
          return { ...item, isCheck: !item.isCheck };
        }
        return item;
      })
    );
  }

  const resortList = (data) => {
    const totalValue = data.reduce((sum, item) => sum + item.value, 0);
    const dataWithPercentage = data.map(item => ({
      ...item,
      percentage: (item.value / totalValue * 100).toFixed(2)
    }));

    const highValues = dataWithPercentage.filter(item => parseFloat(item.percentage) > 5).sort((a, b) => b.value - a.value);
    const lowValues = dataWithPercentage.filter(item => parseFloat(item.percentage) <= 5).sort((a, b) => a.value - b.value);

    let result = [];
    const highValueInterval = Math.floor(lowValues.length / highValues.length);
    for (let i = 0, j = 0; i < lowValues.length; i++) {
      result.push(lowValues[i]);

      if ((i + 1) % highValueInterval === 0 && j < highValues.length) {
        result.push(highValues[j]);
        j++;
      }
    }

    if (highValues.length > 0) {
      result.push(...highValues.slice(result.length - lowValues.length));
    }

    return result
  }

  const handlePieChartData = (labels, value) => {
    let result = [];
    labels.forEach((label, index) => {
      result.push(
        { category: label, value: value[index] },
      )
    });

    setPieChartData(resortList(result))
  }
  
  //---------------------------------------------------------------------------
  const BAR_CHART = 0, PIE_CHART = 1;
  const [selectedChartType, setSelectedChartType] = useState(BAR_CHART)
  
  // AmDonutChartV4
  const [pieChartData, setPieChartData] = useState([]);

  const [peptideMultiFunLabels, setPeptideMultiFunLabels] = useState([])
  const [peptideMultiFunValue, setPeptideMultiFunValue] = useState([])
  
  const [multifunctionalPeptideListForTable, setMultifunctionalPeptideListForTable] = useState([])
  
  // BarChart
  const peptideClsStatisticsOptions = chartProp.options.axisTitleXY(words.peptideClsStatistics.x, words.peptideClsStatistics.y)
  const peptideClsStatisticsData = chartProp.data.carate(
    "",
    '',
    [''],
    peptideClsStatisticsLabels,
    peptideClsStatisticsValue,
  )

  const statisticsOfMultiPeptideOptions = chartProp.options.axisTitleXY(words.statisticsOfMultiPeptide.x, words.statisticsOfMultiPeptide.y)
  const statisticsOfMultiPeptideData = chartProp.data.carate(
    words.statisticsOfMultiPeptide.title,
    words.statisticsOfMultiPeptide.text,
    '',
    peptideMultiFunLabels,
    peptideMultiFunValue
  )
  
  //---------------------------------------------------------------------------
  return (
    <LayoutPage bread={texts.bread} desc={texts.desc}>
      <div className={scss.layout}>

        <div className={_tmcaScss.chart_type_btns}>
          {
            chartTypeBtnList.map((name, index) => {
              return <Button
                variant="outlined"
                className={selectedChartType === index ? _tmcaScss.selected_chart_type : ""}
                onClick={() => {
                  switch (index) {
                    case 0:
                      // Histogram
                      setSelectedChartType(BAR_CHART)
                      break;

                    // case 1:
                    //   // Line chart
                    //   break;

                    default:
                      setSelectedChartType(PIE_CHART)
                      // Pie chart
                      break;
                  }
                }}
              >{name}</Button>
            }
            )
          }
        </div>


        {state.isLoading ? <LoadingAnime /> : selectedChartType === BAR_CHART && <ChartSection
          chartBlockTitle={words.peptideClsStatistics.title}
          mainChart={
            <BarChart
              data={peptideClsStatisticsData}
              options={peptideClsStatisticsOptions}
              yAxisLabel={words.peptideClsStatistics.y}
            />
          } />
        }


        {selectedChartType === PIE_CHART && <ChartSection
          chartBlockTitle={words.peptideClsStatistics.title}
          mainChart={
            // <AmPieChart labels={peptideClsStatisticsLabels} value={peptideClsStatisticsValue} />
            // <AmDonutChartV5 data={pieChartData} height={600} />
            <AmDonutChartV4 data={pieChartData} height={600}  />
          } />}


        <MuiTableContainer size='small'>
          {/* <TableRow >
            <MuiTableHeadCell colSpan={2}>{texts.table.title}</MuiTableHeadCell>
          </TableRow > */}
          {stateMulti.isLoading ? <LoadingAnime /> :
            <TableBody>
              <TableRow >
                <MuiTableCell>
                  <div className={_tmcaScss.formula_block}>
                    <div className={_tmcaScss.multifunctional_peptides_counts} onClick={() => {
                      navigate(paths.spp.peptide.multi_search())
                    }}>
                      <center>
                        <u>
                          <span>
                            Multifunctional peptides
                          </span><br />
                          {formulaBlockData.multiPeptidesCounts}
                        </u>
                      </center>
                    </div>

                    <span className={_tmcaScss.symbol}>/</span>

                    <div className={_tmcaScss.total_peptide_sequences} onClick={() => {
                      navigate(paths.spp.tool.peptide_statistics())
                    }}>
                      <center>
                        <u>
                          <span>
                            Total Peptide Sequences
                          </span><br />
                          {formulaBlockData.totalPeptideSeq}
                        </u>
                      </center>
                    </div>

                    <span className={_tmcaScss.symbol}>=</span>

                    <div className={_tmcaScss.ratio}>
                      <span>
                        Ratio
                      </span>
                      {formulaBlockData.ratio}%
                    </div>
                  </div>

                  {/* <ChartSection
                    chartBlockTitle={words.statisticsOfMultiPeptide.title}
                    chartBlockSubTitle={words.statisticsOfMultiPeptide.text}
                    mainChart={
                      <BarChart
                        data={statisticsOfMultiPeptideData}
                        options={statisticsOfMultiPeptideOptions}
                        yAxisLabel={words.statisticsOfMultiPeptide.y}
                      />
                    } /> */}
                  <BarChart data={statisticsOfMultiPeptideData} options={statisticsOfMultiPeptideOptions}></BarChart>
                </MuiTableCell>

                <MuiTableCell>
                  <div style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    marginTop: "10px",
                    marginBottom: "10px",
                    border: `1px solid ${v.grey50}`
                  }}>
                    <MuiTableContainer size='small'>
                      {texts.table.list.head.map((item, index) => (
                        <MuiTableHeadCell key={index} className="bgc-grey50" align={index === 0 ? "center" : "left"}>{item}</MuiTableHeadCell>
                      ))}

                      <TableBody>
                        {multifunctionalPeptideListForTable.map(item => rowChildren(item))}
                      </TableBody>
                    </MuiTableContainer>
                  </div>

                  <div className={_tmcaScss.btns_block}>
                    <div className={_tmcaScss.white_btns_block}>
                      <WhiteButton handleClick={() => {
                        setMultifunctionalPeptideListForTable(prevItems => prevItems.map((item) => ({ ...item, isCheck: true })));
                      }} text="Check All" />
                        
                      <WhiteButton handleClick={() => {
                        setMultifunctionalPeptideListForTable(prevItems => prevItems.map((item) => ({ ...item, isCheck: false })));
                      }} text="Uncheck All" />
                      
                      <WhiteButton
                        handleClick={() => {
                          setMultifunctionalPeptideListForTable(prevItems => prevItems.map((item) => ({ ...item, isCheck: !item.isCheck })));
                        }} text="Reverse Check" />
                    </div>

                    <GreenButton handleClick={() => {
                      cookie.setCookie(cookie.keys.peptide.selectedMultiPeptideList, multifunctionalPeptideListForTable.filter(item => item.isCheck).map(item => item.id))

                      navigate(paths.spp.peptide.multi_search())
                    }} text="Search" />
                  </div>
                </MuiTableCell>
              </TableRow>
            </TableBody>
          }
        </MuiTableContainer>
        
      </div>
    </LayoutPage>
  )
}
export default ToolMultiCrossAnalysis
import React from 'react'

import { apiConfig } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import cookie from 'utils/cookie';
import { breadData } from 'components/bread/BreadBar';
import SearchResultLayout from 'layouts/search/SearchResultLayout';

//---------------------------------------------------------------------------
// Go in: homepage -> 'Protein' tab -> any text -> 'Search' button -> this page
// path: paths.spp.protein.quick_search(),
const ProteinQuickSearch = ({ setInfo }) => {
  let texts = {
    bread: breadData.create('Protein', 'Food-borne proteins', 'Search'),

    searchHint: 'Search by SPP ID, Organism, Protein name, Protein length, UniProtKB',
    tableHeadCell: spp.protein.search.quick.output_text,
    tableBodyCell: spp.protein.search.quick.output,
  }
  
  const filterName = cookie.getCookie(cookie.keys.quickSearch.filterSelection)
  const filterValue = cookie.getCookie(cookie.keys.quickSearch.filterValue)
  // console.log('filter:', filterName, ' value:', filterValue);
  
  return (
    <SearchResultLayout
      setInfo={setInfo}
      texts={texts}
      configSearch={apiConfig.protein.quick_search} //11
      jsonInput={spp.protein.search.quick.input}
      inputProp={[filterName, filterValue]}
      jsonInputKeyword={spp.protein.search.quick.input_keyword}
    />
  )
}
export default ProteinQuickSearch

export function getScssNameCell(json, tab, index, item = '') {
    // console.log(json);
    if (json === "Highlight sequence" //ToolHotSpotResult.jsx PeptideTargetProteinPage.jsx
      || json === "protein_sequence" //ProteinDFBPIDResult.jsx 
      || json === "Protein sequence") //ToolHotSpotResultPrecursor.jsx PeptideListHotspotResult.jsx
      return 'right_cell_code'
    else if (item === "Protein sequence") //title "Protein sequence" ToolHotSpotResultPrecursor.jsx PeptideListHotspotResult.jsx
      return 'right_cell_last'
    else if (index === (tab.json.length - 1)) //TabCellText in PeptideDFBPIDResult.jsx
      return 'right_cell_last'
    else if (index === (tab.head.length - 1))
      return 'right_cell_last'
    else
      return 'right_cell'
  }

export function getClassNameTitle(json) {
  if (json === "BIOPEP-UWM" || json === "APD" || json === "MBPDB") //BIOPEP-UWM ... MBPDB
    return 'b1strong-green4 link'
  return 'b1strong-darkGreen'
}

export function getClassName(json, output) {
  // console.log(json);
  // if (json === "specific_target_protein" && output[json] !== 'N.D') //Specific Target Protein(s)
  //   return 'b1-green4'
  if (json === "Enzymatic Hydrolysis")
    return 'b1-green4'
  return 'b1-grey100'
}

export function getLinkFromArray(links, title) {
  let resultLink = ""
  if( links !== undefined && Array.isArray(links) ) {
    links.forEach(item => {
      // console.log('title', title, 'item', Object.keys(item)[0]);
      if (Object.keys(item)[0] === title) {
        resultLink = item[title]
      }
  })}
  return resultLink
}

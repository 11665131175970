import { Navigate, useLocation, useRoutes } from "react-router-dom";

import UserTerms from "views/auth/UserTerms";
import Terms from "views/Terms";
import AboutUs from "views/AboutUs";
import Products from "views/product/Products";
import Tools from "views/tool/Tools";
import PeptideClassifyQuickSearch from "views/peptide/PeptideClassifyQuickSearch";
import PeptideMultiSearch from "views/peptide/PeptideMultiSearch";
import PeptideQuickSearchResult from "views/peptide/PeptideQuickSearchResult";
import PeptideList from "views/peptide/PeptideList";
import ProteinClassificationSearch from "views/protein/ProteinClassificationSearch";
import ProteinQuickSearch from "views/protein/ProteinQuickSearch";
import ProteinList from "views/protein/ProteinList";
import ProteinSourceSearch from "views/protein/ProteinSourceSearch";
import paths from "utils/network/apiPath";
import HomePage from "views/HomePage";
import Login from "views/auth/Login";
import NotFoundPage from "views/NotFoundPage";
import SignUp from "views/auth/SignUp";
import cookie from "utils/cookie";
import Verify from "views/auth/Verify";
import PeptideAdvanceSearch from "views/peptide/PeptideAdvancedSearch";
import PeptideAdvancedSearchResult from "views/peptide/PeptideAdvancedSearchResult";
import PeptideClassifySearch from "views/peptide/PeptideClassifySearch";
import PeptideDFBPIDResult from "views/peptide/PeptideDFBPIDResult";
import PeptideFunRelationships from "views/peptide/PeptideFunRelationships";
import PeptideStatisticalData from "views/peptide/PeptideStatisticalData";
import PeptideListHotspotResult from "views/tool/PeptideListHotspotResult";
import ProteinClassGraph from "views/protein/ProteinClassGraph";
import ProteinPeptideList from "views/protein/ProteinPeptideList";
import ToolHotSpotResultChart from "views/tool/ToolHotSpotResultChart";
import ProteinClassificationCount from "views/protein/ProteinClassificationCount";
import ProteinFragGraph from "views/protein/ProteinFragGraph";
import ProteinFragmentCount from "views/protein/ProteinFragmentCount";
import ToolEHPToolEnzymeTable from "views/tool/ToolEHPToolEnzymeTable";
import ToolEHPToolResult from "views/tool/ToolEHPToolResult";
import ToolEHPToolSearch from "views/tool/ToolEHPToolSearch";
import ToolHotSpotResult from "views/tool/ToolHotSpotResult";
import ToolHotSpotSearch from "views/tool/ToolHotSpotSearch";
import ToolMultiCrossAnalysis from "views/tool/ToolMultiCrossAnalysis";
import ToolPeptideBitternessPredict from "views/tool/ToolPeptideBitternessPredict";
import ToolPeptideBitternessPredictResult from "views/tool/ToolPeptideBitternessPredictResult";
import ToolPeptideCalculator from "views/tool/ToolPeptideCalculator";
import ToolPeptideCalculatorResult from "views/tool/ToolPeptideCalculatorResult";
import PeptideDFBPIDMultiResult from "views/peptide/PeptideDFBPIDMultiResult";

import ToolAASD from "views/tool/ToolAASD";
import ToolEHPToolEnzymePage from "views/tool/ToolEHPToolEnzymePage";
import FASGAI from "views/tool/aasd/01FASGAI";
import ZScales from "views/tool/aasd/02ZScales";
import NNAAIndex from "views/tool/aasd/03NNAAIndex";
import ISAECI from "views/tool/aasd/04ISA-ECI ";
import MSWHIM from "views/tool/aasd/05MS-WHIM";
import SZOTT from "views/tool/aasd/06SZOTT";
import STscales from "views/tool/aasd/07STscales";
import Tscales from "views/tool/aasd/08Tscales";
import VHSE from "views/tool/aasd/09VHSE";
import VSTV from "views/tool/aasd/10VSTV";
import GRID from "views/tool/aasd/11GRID";
import DPPS from "views/tool/aasd/12DPPS";
import BLOSUM62 from "views/tool/aasd/13BLOSUM26";
import HESH from "views/tool/aasd/14HESH";
import Lin from "views/tool/aasd/15Lin";
import ProtFP from "views/tool/aasd/16ProtFP";
import QTMS from "views/tool/aasd/17QTMS";
import SVRG from "views/tool/aasd/18SVRG";
import SVWG from "views/tool/aasd/19SVWG";
import VSW from "views/tool/aasd/20VSW";

import PeptideStatistics from "views/tool/PeptideStatistics";
import PeptideInductiveAnalysisResult from "views/peptide/PeptideInductiveAnalysisResult";
import ProteinDFBPIDResult from "views/protein/ProteinDFBPIDResult";
import PeptideStatisticalAnalysisNC from "views/tool/PeptideStatisticalAnalysisNC";
import PeptideStatisticalAnalysisA from "views/tool/PeptideStatisticalAnalysisA";
import Privacy from "views/Privacy";
import ToolCRSToolSearch from "views/tool/ToolCRSToolSearch";
import ToolCRSToolResultLv1 from "views/tool/ToolCRSToolResultLv1";
import ToolCRSToolResultLv2 from "views/tool/ToolCRSToolResultLv2";
import ToolCRSToolResultLv3 from "views/tool/ToolCRSToolResultLv3";
import ToolPDCAAS from "views/tool/ToolPDCAAS";
import ToolPDCAASTable from "views/tool/ToolPDCAASTable";
import ToolPDCAASResult from "views/tool/ToolPDCAASResult";
import PeptideTargetProteinPage from "views/peptide/PeptideTargetProteinPage";
import ToolHotSpotResultPrecursor from "views/tool/ToolHotSpotResultPrecursor";
import ToolHotSpotCSSToolSearch from "views/tool/ToolHotSpotCSSToolSearch";
import { getFullPath } from "utils/general";
import ToolPDCAASResult2 from "views/tool/ToolPDCAASResult2";
import ToolACEiPP from "views/tool/ToolACEiPP";
import ToolHotSpotCSSToolResultLv1 from "views/tool/ToolHotspotCSSToolResultLv1";
import ToolACEiPPPreLibraries from "views/tool/ToolACEiPPPreLibraries";
import ToolACEiPPSeqFeatures from "views/tool/ToolACEiPPSeqFeatures";
import ToolACEiPPAnOxPPPrePool from "views/tool/ToolACEiPPAnOxPPPrePool";
import ToolAnOxPPSeqFeatures from "views/tool/ToolAnOxPPSeqFeatures";
import ToolAnOxPP from "views/tool/ToolAnOxPP";
import ToolAnOxPPPreLibraries from "views/tool/ToolAnOxPPPreLibraries";
import ToolACEiPPAnOxPPManualResult from "views/tool/ToolACEiPPAnOxPPManualResult";
import ToolACEiPPAnOxPPManualMutation from "views/tool/ToolACEiPPAnOxPPManualMutation";
import ToolACEiPPAnOxPPUploadResult from "views/tool/ToolACEiPPAnOxPPUploadResult";
import ToolACEiPPAnOxPPManualPeptide from "views/tool/ToolACEiPPAnOxPPManualPeptide";

//---------------------------------------------------------------------------
function setRoute(path, element) {
  return {
    path: path,
    element: element,
  }
}

function setProtectedRoute(path, component) {
  return {
    path: path,
    element: (
      <ProtectedRoute redirectPath={() => this.path}>
        {component}
      </ProtectedRoute>
    ),
  }
}

function ProtectedRoute({ children, redirectPath }) {
  const token = cookie.getCookie(cookie.keys.auth.token);

  let location = useLocation();
  let fullPath = getFullPath(location)
  // console.log('fullPath', fullPath)
  return token !== "" && token !== undefined
  ? (children)
  : (<Navigate to={`${paths.auth.login}?redirect=${encodeURIComponent(fullPath)}`}/>)
}

function LoggedInRoute({ children }) {
  const token = cookie.getCookie(cookie.keys.auth.token);
  return token !== "" && token !== undefined
  ? (<Navigate to={paths.site.home} />)
  : (children);
}

//---------------------------------------------------------------------------
const Router = ({ setInfo }) => {
  // console.log(endpoint.auth.login);
  // console.log(endpoint.spp.getSppData);

  let element = useRoutes([
    {
      path: paths.site.home,
      element: <HomePage setInfo={setInfo} />,
    },
    setRoute("*", <HomePage setInfo={setInfo} />),
    // setRoute( , ),
    // setProtectedRoute( , ),
    //---------------------------------------------------------------------------
    // site
    setRoute( paths.site.about, <AboutUs />),
    setRoute( paths.site.terms, <Terms />), //TODO: need to fix C
    setRoute( paths.site.privacy, <Privacy />),
    
    //---------------------------------------------------------------------------
    // auth
    {
      path: paths.auth.login,
      element: (
        <LoggedInRoute>
          <Login setInfo={setInfo} />
        </LoggedInRoute>
      ),
    },
    setRoute( paths.auth.signup, <SignUp setInfo={setInfo} />),
    setRoute( paths.auth.terms, <UserTerms setInfo={setInfo} />),
    setRoute( `${paths.auth.verify}/:e_token?/:p_token?`, <Verify />),

    //---------------------------------------------------------------------------
    // Protein
    setProtectedRoute( paths.site.proteins, <ProteinList setInfo={setInfo} />),
    setProtectedRoute( paths.spp.protein.quick_search(), <ProteinQuickSearch setInfo={setInfo} />),
    setProtectedRoute( paths.spp.protein.class_search(), <ProteinClassificationSearch setInfo={setInfo} />),
    setProtectedRoute( paths.spp.protein.source_search(), <ProteinSourceSearch setInfo={setInfo} />),
    setProtectedRoute( paths.spp.protein.id_result(), <ProteinDFBPIDResult setInfo={setInfo} />), //13
    setProtectedRoute( paths.spp.protein.frag_graph(), <ProteinFragGraph setInfo={setInfo} />), //17
    setProtectedRoute( paths.spp.protein.fragment_count(), <ProteinFragmentCount setInfo={setInfo} />),
    setProtectedRoute( paths.spp.protein.class_count(), <ProteinClassificationCount setInfo={setInfo} />),
    setProtectedRoute( paths.spp.protein.class_graph(), <ProteinClassGraph setInfo={setInfo} />),
    setProtectedRoute( paths.spp.protein.peptide_list(), <ProteinPeptideList setInfo={setInfo} />),

    //---------------------------------------------------------------------------
    // Peptide
    setProtectedRoute( paths.site.peptides, <PeptideList setInfo={setInfo} />),
    setProtectedRoute( paths.spp.peptide.quick_search(), <PeptideQuickSearchResult setInfo={setInfo} />),
    setProtectedRoute( paths.spp.peptide.multi_search(), <PeptideMultiSearch setInfo={setInfo} />), //7
    setProtectedRoute( paths.spp.peptide.quick_class_search(), <PeptideClassifyQuickSearch setInfo={setInfo} />),
    setProtectedRoute( paths.spp.peptide.class_search(), <PeptideClassifySearch setInfo={setInfo} />), //9
    setProtectedRoute( paths.spp.peptide.advanced_search(), <PeptideAdvanceSearch setInfo={setInfo} />),
    setProtectedRoute( paths.spp.peptide.id_result(), <PeptideDFBPIDResult setInfo={setInfo} />),
    setProtectedRoute( paths.spp.peptide.id_multi_result(), <PeptideDFBPIDMultiResult setInfo={setInfo} />),
    setProtectedRoute( paths.spp.peptide.id_target_protein_page(), <PeptideTargetProteinPage setInfo={setInfo} />), //89
    setProtectedRoute( paths.spp.peptide.fun_relationships(), <PeptideFunRelationships setInfo={setInfo} />),
    setProtectedRoute( paths.spp.peptide.statistical_data(), <PeptideStatisticalData setInfo={setInfo} />),
    setProtectedRoute( paths.spp.peptide.class_advanced_search(), <PeptideAdvancedSearchResult setInfo={setInfo} />),
    setProtectedRoute( paths.spp.peptide.inductive_analysis(), <PeptideInductiveAnalysisResult setInfo={setInfo} />),

    //---------------------------------------------------------------------------
    setRoute( paths.site.products, <Products />),

    //---------------------------------------------------------------------------
    setRoute( paths.site.tools, <Tools />),
    // 4.1
    setProtectedRoute( paths.spp.tool.hotspot_search(), <ToolHotSpotSearch setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.hotspot_result(), <ToolHotSpotResult setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.hotspot_result_chart(), <ToolHotSpotResultChart setInfo={setInfo} />),
    setProtectedRoute( paths.spp.peptide.hotspot_result(), <PeptideListHotspotResult setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.hotspot_result_precursor(), <ToolHotSpotResultPrecursor setInfo={setInfo} />), //2.1.1.3
    
    // 4.2
    setProtectedRoute( paths.spp.tool.ehp_tool_search(), <ToolEHPToolSearch setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.ehp_tool_enzyme_table(), <ToolEHPToolEnzymeTable />),
    setProtectedRoute( paths.spp.tool.ehp_tool_enzyme_page(), <ToolEHPToolEnzymePage setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.ehp_tool_result(), <ToolEHPToolResult setInfo={setInfo} />),
    // 4.3
    setProtectedRoute( paths.spp.tool.bpp_tool(), <ToolPeptideBitternessPredict setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.bpp_tool_result(), <ToolPeptideBitternessPredictResult setInfo={setInfo} />),
    // 4.4
    setProtectedRoute( paths.spp.tool.aasd_tool(), <ToolAASD setInfo={setInfo} />),
    //---AASD_fakeData-------------------------------------------------------------
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[0], <FASGAI />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[1], <ZScales />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[2], <NNAAIndex />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[3], <ISAECI />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[4], <MSWHIM />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[5], <SZOTT />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[6], <STscales />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[7], <Tscales />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[8], <VHSE />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[9], <VSTV />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[10], <GRID />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[11], <DPPS />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[12], <BLOSUM62 />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[13], <HESH />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[14], <Lin />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[15], <ProtFP />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[16], <QTMS />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[17], <SVRG />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[18], <SVWG />),
    setProtectedRoute( paths.spp.tool.aasd_tool_resourse[19], <VSW />),
    // 4.5
    setProtectedRoute( paths.spp.tool.peptide_calculator(), <ToolPeptideCalculator setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.peptide_calculator_result(), <ToolPeptideCalculatorResult setInfo={setInfo} />),
    // 4.6
    setProtectedRoute( paths.spp.tool.multi_cross_analysis(), <ToolMultiCrossAnalysis setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.peptide_statistics(), <PeptideStatistics setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.peptide_statistical_nc_chart(), <PeptideStatisticalAnalysisNC setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.peptide_statistical_a_chart(), <PeptideStatisticalAnalysisA setInfo={setInfo} />),
    // 4.7
    setProtectedRoute( paths.spp.tool.pdcaas(), <ToolPDCAAS setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.pdcaas_table(), <ToolPDCAASTable setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.pdcaas_result(), <ToolPDCAASResult2 setInfo={setInfo} />),
    // 4.8
    // 4.9
    setProtectedRoute( paths.spp.tool.crs_tool(), <ToolCRSToolSearch setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.crs_tool_result(), <ToolCRSToolResultLv1 setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.crs_tool_result_lv2(), <ToolCRSToolResultLv2 setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.crs_tool_result_lv3(), <ToolCRSToolResultLv3 setInfo={setInfo} />),
    // 4.10
    setProtectedRoute( paths.spp.tool.hotspot_css_tool(), <ToolHotSpotCSSToolSearch setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.hotspot_css_tool_result(), <ToolHotSpotCSSToolResultLv1 setInfo={setInfo} />),
    // 4.11
    setProtectedRoute( paths.spp.tool.aceipp(), <ToolACEiPP setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.aceipp_sf(), <ToolACEiPPSeqFeatures setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.aceipp_pl(), <ToolACEiPPPreLibraries setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.aceipp_anoxpp_pp(), <ToolACEiPPAnOxPPPrePool setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.aceipp_anoxpp_mr(), <ToolACEiPPAnOxPPManualResult setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.aceipp_anoxpp_mm(), <ToolACEiPPAnOxPPManualMutation setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.aceipp_anoxpp_mp(), <ToolACEiPPAnOxPPManualPeptide setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.aceipp_anoxpp_ur(), <ToolACEiPPAnOxPPUploadResult setInfo={setInfo} />),
    // 4.12
    setProtectedRoute( paths.spp.tool.anoxpp(), <ToolAnOxPP setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.anoxpp_sf(), <ToolAnOxPPSeqFeatures setInfo={setInfo} />),
    setProtectedRoute( paths.spp.tool.anoxpp_pl(), <ToolAnOxPPPreLibraries setInfo={setInfo} />),
    
    //---------------------------------------------------------------------------
  ]);

  return element;
}

export default Router;


import { initBreadTextFontSize } from './BreadBar';
import scss from './BreadText.module.scss';
import Text from 'components/tag/Text';

//---------------------------------------------------------------------------
const BreadText = (props) => {
  const { bread } = props
  
  //---------------------------------------------------------------------------
  return (
    <div className={scss.frame}>
      <Text className={'h1-darkGreen'} fontSize={bread ? bread.fontSize : initBreadTextFontSize}>
        {bread ? bread.title : '...'}
      </Text>
    </div>)
};

export default BreadText
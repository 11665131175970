// -----official tools & third tools-------------------------------------------------------
import { Tooltip } from '@mui/material';
import { useNavigate } from "react-router-dom";

// -----custom tools-------------------------------------------------------
// import _v from "path/of/custom_tools";
import scss from "components/peptide/PercentageBar.module.scss";
import cookie from "utils/cookie";
import paths from "utils/network/apiPath";
//---------------------------------------------------------------------------
const PercentageBar = (props) => {
  // -----variables-------------------------------------------------------
  const navigate = useNavigate();
  const totalRecords = `Total bio-peptides: ${props.totalRecords}`

  // -----functions-------------------------------------------------------

  // -----render-------------------------------------------------------

  return (

    <div className={scss["main"]} style={{}}>
      {props.list.length !== 0 && props.list.map((item, index) => {

        const showText = `${item.label}: ${item.counts}`;

        return (<Tooltip title={showText} placement="top">
          <div className={scss["each-bar"]}
            key={index}
            onClick={() => {
              cookie.setCookie(cookie.keys.peptide.selectedPeptideClsName, item.label)
              cookie.setCookie(cookie.keys.peptide.selectedPeptideClsId, item.id)

              paths.openNewTabWithRelativePath(paths.spp.peptide.statistical_data())
            }}>
            <div className={scss["title"]}>{showText}</div>
            <div className={scss["bar-base"]}>
              <div className={scss["bar-proportion"]} style={{ width: `${item.percentage}%` }} />
            </div>
          </div>
        </Tooltip>)
      })}

      {props.list.length !== 0 && <Tooltip title={totalRecords} placement="top">
        <div className={scss["full-bar"]}>
          <div className={scss["title"]}>{totalRecords}</div>
          <div className={scss["bar-base"]}>
            <div className={scss["full-bar-proportion"]} />
          </div>
        </div>
      </Tooltip>}
    </div>
  )
};

export default PercentageBar;
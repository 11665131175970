import { useEffect } from "react"
import { Grid } from "@mui/material";
// import { Outlet } from "react-router-dom";

import register from "./Register.module.scss";
import SetUserInfo from "components/register/SetUserInfo";

//---------------------------------------------------------------------------
export default function SignUp({ setInfo }) {

  useEffect(() => {
  }, [])

  return (
    <Grid container className={register.main} >
      <Grid item className={register.content}>
        <SetUserInfo setInfo={setInfo} />
      </Grid>
    </Grid>
  )
}
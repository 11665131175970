import React from 'react';
// import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

//---------------------------------------------------------------------------
const NoneButton = (props) => (
  <Button variant="text"
    sx={{
      boxShadow: "none",
      textTransform: "none",
      // borderRadius: '100px',
      // border: "1px solid",
      // lineHeight: 1.5,
      // backgroundColor: "#0063cc",
      // borderColor: "#0063cc",
      // "&:hover": {
      //     boxShadow: "none",
      //     backgroundColor: "#0069d9",
      //     borderColor: "#0062cc",
      // },
      // "&:active": {
      //     boxShadow: "none",
      //     backgroundColor: "#0062cc",
      //     borderColor: "#005cbf",
      // },
      // "&:focus": {
      //     boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
      // },
    }}
     {...props}>
    {props.children}
  </Button>
)
export default NoneButton;

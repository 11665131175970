import 'assets/scss/temp.scss';
import 'assets/scss/common.scss';
import { NoneDiv } from 'components/GreenDiv';
import { images } from 'utils/imgs';

//---------------------------------------------------------------------------


//---------------------------------------------------------------------------
const FASGAI = () => {
  return (
    <NoneDiv style={{ gap: 20 }}>
             <img  src={images._04ToolAASD['01FASGAI.png']}alt="Lin" />
    </NoneDiv>
  )
}
export default FASGAI
import { Link } from 'react-router-dom';

import 'assets/scss/index.scss'

//---------------------------------------------------------------------------
export const RouterLink = (props) => {
  const {newTab, className, ...other} = props
  let _className = 'link'
  if( className !== undefined )
    _className += ' ' + className
  
  if (newTab !== undefined)
    return (
      <Link target="_blank" rel="noreferrer" className={_className} {...other}>
        {props.children}
      </Link>
    )
  else
    return (
      <Link className={_className} {...other}>
        {props.children}
      </Link>
    )
}
// -----official tools & third tools-------------------------------------------------------
import { Button, Paper, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { breadData } from 'components/bread/BreadBar';
import LayoutPage from 'layouts/LayoutPage';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// -----custom tools-------------------------------------------------------
import v from 'assets/scss/_variables.scss';
import scss from 'views/tool/Tools.module.scss';
import _tmcaScss from 'views/tool/ToolMultiCrossAnalysis.module.scss';
// import _v from "path/of/custom_tools";
// import exampleScss from "path/of/example.module.scss";
import SnackBar, { emptyResult, snackInfo, snackType } from "components/SnackBar";
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import chartProp from 'components/chart/chartProp';
import PercentageBar from 'components/peptide/PercentageBar';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { Space, determineHasEmptyListInTwoLevelObj, isEmptyObjOrArray } from 'utils/general';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from "utils/network/jsons";
import PeptideXYTable from 'components/tool/PeptideXYTable';
import { MuiTableContainer } from "components/table/MuiTable";

//---------------------------------------------------------------------------
const PeptideStatistics = ({ setInfo }) => {
  // -----variables-------------------------------------------------------
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  const texts = {
    bread: breadData.create('Tools', "Bioactive Peptide Statistics", bread3rd, 'Statistics'),
  }

  const [reportRecordsList, setReportRecordsList] = useState([])
  const [peptideReportRecordsTotal, setPeptideReportRecordsTotal] = useState(0)

  const [seqRecordsList, setSeqRecordsList] = useState([])
  const [peptideSeqRecordsTotal, setPeptideSeqRecordsTotal] = useState(0)

  const REPORT_RECORDS = 0, SEQUENCE_RECORDS = 1;
  const [selectedChartType, setSelectedChartType] = useState(REPORT_RECORDS)

  const totalPeptideSeqStr = "Total peptide sequences: ";
  const words = {
    barChartTitle: "Sequence characteristics of all bioactive peptides",
    ncTerminalCoding: {
      title: 'N- and C-terminal amino acid coding features',
      text: totalPeptideSeqStr,
      x: 'Amino acids',
      y: 'Amino acid percentage(%)',
    },
    distributionNTerminal: {
      title: 'The distribution of amino acids, from site 1 to 10 on N-terminal of peptides',
      text: totalPeptideSeqStr,
      x: 'Amino acids',
      y: 'Amino acid percentage(%)',
    },
    distributionCTerminal: {
      title: 'The distribution of amino acids, from site 1 to 10 on C-terminal of peptides',
      text: totalPeptideSeqStr,
      x: 'Amino acids',
      y: 'Amino acid percentage(%)',
    },
    lengthDistributionPeptides: {
      title: 'The length distribution characteristics of bioactive peptides',
      text: totalPeptideSeqStr,
      x: 'Peptide length',
      y: 'Percentage(%)',
    },
    aminoAcidPeptides: {
      title: 'The amino acid composition characteristics of bioactive peptides',
      text: totalPeptideSeqStr,
      x: 'Amino acid',
      y: 'Percentage(%)',
    },
    molecularWeight: {
      title: 'The molecular weight (MW) distribution characteristics of bioactive peptides',
      text: totalPeptideSeqStr,
      x: 'MW distribution',
      y: 'Percentage(%)',
    },
  }

  // getPeptideSeqChars
  const [totalSeq, setTotalSeq] = useState(0);
  
  //---------------------------------------------------------------------------
  // 1 set ncTerminalCoding
  const [ncTerminalCodingLabels, setNcTerminalCodingLabels] = useState([]);
  const [ncTerminalCoding_N_Value, setNcTerminalCoding_N_Value] = useState([]);
  const [ncTerminalCoding_C_Value, setNcTerminalCoding_C_Value] = useState([]);

  const ncTerminalCodingOptions = chartProp.options.axisTitleXY(words.ncTerminalCoding.x, words.ncTerminalCoding.y)
  const ncTerminalCodingData = chartProp.data.carateMulti(
    "",
    "",
    ncTerminalCodingLabels,
    [chartProp.data.singleDataset(
      "N-Terminal", ncTerminalCoding_N_Value, v.green, v.chartHoverGreen
    ), chartProp.data.singleDataset(
      "C-Terminal", ncTerminalCoding_C_Value, v.purple, v.hoverPurple
    )])

  // 2 distributionNTerminal
  const [distributionNTerminalLabels, setDistributionNTerminalLabels] = useState([]);
  const [distributionNTerminal_P1_P10_Value, setDistributionNTerminal_P1_P10_Value] = useState([]);

  const bgColors = [
    "#0dddcd", "#1CFCBE", "#29FCCF", "#36FCE0", "#43F7F2",
    "#50EDF3", "#5DD1F2", "#6AB5F1", "#7799F1", "#856DF0"
  ]

  const bgHoverColors = [
    "#2FEBD3", "#2DFFE1", "#3AFFF2", "#47FFF3", "#54FFF5",
    "#61F8F6", "#6EDAF5", "#7BBEF4", "#88ACF4", "#978FF3"
  ]

  const distributionNTerminalOptions = chartProp.options.axisTitleXY(words.distributionNTerminal.x, words.distributionNTerminal.y)
  const distributionNTerminalData = chartProp.data.carateMulti(
    "",
    "",
    distributionNTerminalLabels,
    distributionNTerminal_P1_P10_Value.map((item, index) => {
      return chartProp.data.singleDataset(
        `Position ${index + 1}`, item, bgColors[index], bgHoverColors[index]
      )
    }),
  )

  // 3 distributionCTerminal
  const [distributionCTerminalLabels, setDistributionCTerminalLabels] = useState([]);
  const [distributionCTerminal_P1_P10_Value, setDistributionCTerminal_P1_P10_Value] = useState([]);

  const distributionCTerminalOptions = chartProp.options.axisTitleXY(words.distributionCTerminal.x, words.distributionCTerminal.y)
  const distributionCTerminalData = chartProp.data.carateMulti(
    "",
    "",
    distributionCTerminalLabels,
    distributionCTerminal_P1_P10_Value.map((item, index) => {
      return chartProp.data.singleDataset(
        `Position ${index + 1}`, item, bgColors[index], bgHoverColors[index]
      )
    }),
  )

  // 4 lengthDistributionPeptides
  const [lengthDistributionPeptidesLabels, setLengthDistributionPeptidesLabels] = useState([]);
  const [lengthDistributionPeptidesValue, setLengthDistributionPeptidesValue] = useState([]);

  const lengthDistributionPeptidesOptions = chartProp.options.axisTitleXY(words.lengthDistributionPeptides.x, words.lengthDistributionPeptides.y)
  const lengthDistributionPeptidesData = chartProp.data.carate(
    "",
    "",
    [''],
    lengthDistributionPeptidesLabels,
    lengthDistributionPeptidesValue,
  )

  // 5 aminoAcidPeptides
  const [aminoAcidPeptidesLabels, setAminoAcidPeptidesLabels] = useState([]);
  const [aminoAcidPeptidesValue, setAminoAcidPeptidesValue] = useState([]);

  const aminoAcidPeptidesOptions = chartProp.options.axisTitleXY(words.aminoAcidPeptides.x, words.aminoAcidPeptides.y)
  const aminoAcidPeptidesData = chartProp.data.carate(
    "",
    "",
    [''],
    aminoAcidPeptidesLabels,
    aminoAcidPeptidesValue,
  )
  
  // 6 molecularWeight
  const [molecularWeightLabels, setMolecularWeightLabels] = useState([]);
  const [molecularWeightValue, setMolecularWeightValue] = useState([]);

  const molecularWeightOptions = chartProp.options.axisTitleXY(words.molecularWeight.x, words.molecularWeight.y)
  const molecularWeightData = chartProp.data.carate(
    "",
    "",
    [''],
    molecularWeightLabels,
    molecularWeightValue,
  )


  const chartTypeBtnList = [
    "Report records",
    "Sequence records"
  ]

  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()

  // -----functions-------------------------------------------------------
  
  const getPeptideReportRecords = () => {

    const config = apiConfig.peptide.list()
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        const data = result.data[spp.common.output.items]
        const records_total = result.data["records_total"]
        setPeptideReportRecordsTotal(records_total)

        if(isEmptyObjOrArray(data)){
          setInfo(snackInfo.open(snackType.info, emptyResult))
          return null;
        }

        let objList = [];
        data.forEach(element => {
          objList.push({
            label: element["classification"],
            counts: element["counts"],
            id: element["classification_id"],
            percentage: (element["counts"] / records_total) * 100,
          })
        });

        setReportRecordsList(objList)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  const [stateSeqRecords, setStateSeqRecords] = useState(axiosState.init())
  const getPeptideSeqRecords = () => {
    const config = apiConfig.peptide.statistics_seq_records()
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateSeqRecords(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setStateSeqRecords(axiosState.resultCode200())
        // console.log(result.data);
        
        const data = result.data[spp.common.output.items]
        const records_total = result.data["peptides_total"]
        setPeptideSeqRecordsTotal(records_total)

        if(isEmptyObjOrArray(data)){
          setInfo(snackInfo.open(snackType.info, emptyResult))
          return null;
        }

        let objList = [];
        data.forEach(element => {
          objList.push({
            label: element["classification"],
            counts: element["counts"],
            id: element["classification_id"],
            percentage: (element["counts"] / records_total) * 100,
          })
        });

        setSeqRecordsList(objList)
      }
    }).catch(err => {
      setStateSeqRecords(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  const [stateSeqChars, setStateSeqChars] = useState(axiosState.init())
  const getPeptideSeqChars = () => {
    let input = {
      "class_id": 0
    }

    const config = apiConfig.peptide.statistical_data(input)
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateSeqChars(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setStateSeqChars(axiosState.resultCode200())
        // console.log(result.data);
        
        let output = result.data
        // setOutput(output)
        setTotalSeq(output.total)

        if (determineHasEmptyListInTwoLevelObj(output)) {
          setInfo(snackInfo.open(snackType.info, emptyResult))
          return null;
        }
        // 1 set ncTerminalCoding
        setNcTerminalCodingLabels(output.features.amino_acids)
        setNcTerminalCoding_N_Value(output.features.n_terminal)
        setNcTerminalCoding_C_Value(output.features.c_terminal)

        // 2 set distributionNTerminal
        setDistributionNTerminalLabels(output.n_terminal.amino_acids)
        setDistributionNTerminal_P1_P10_Value(Object.values(output.n_terminal).slice(1));

        // 3 set distributionCTerminal
        setDistributionCTerminalLabels(output.c_terminal.amino_acids)
        setDistributionCTerminal_P1_P10_Value(Object.values(output.c_terminal).slice(1));

        // 4 set lengthDistributionPeptides
        setLengthDistributionPeptidesLabels(output.length_distribution.peptide_length)
        setLengthDistributionPeptidesValue(output.length_distribution.percentage)

        // 5 set aminoAcidPeptides  
        setAminoAcidPeptidesLabels(output.amino_acid.amino_acids)
        setAminoAcidPeptidesValue(output.amino_acid.percentage)

        // 6 set molecularWeight
        setMolecularWeightLabels(output.molecular_weight.MW_distribution)
        setMolecularWeightValue(output.molecular_weight.percentage)

      }
    }).catch(err => {
      setStateSeqChars(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  useEffect(() => {
    getPeptideReportRecords()
    getPeptideSeqRecords()
    getPeptideSeqChars()
    return () => {
    };
  }, []);

  // -----render-------------------------------------------------------

  return (
    <LayoutPage bread={texts.bread}>
      <div className={scss.layout}>

        <MuiTableContainer size='small'>
          <TableBody>
          </TableBody>
          <TableBody>
            <TableRow>
              <StyledTableCell>
                <div style={{ paddingTop: "10px", paddingBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span style={{ fontSize: '18px', width: "300px" }}>Total bio-peptides: {selectedChartType === REPORT_RECORDS ? peptideReportRecordsTotal : peptideSeqRecordsTotal} </span>

                  <div className={_tmcaScss.chart_type_btns} style={{ height: "35px", padding: "0px" }}>
                    {
                      chartTypeBtnList.map((name, index) => {
                        return <Button
                          key={index}
                          variant="outlined"
                          className={selectedChartType === index ? _tmcaScss.selected_chart_type : ""}
                          onClick={() => {
                            switch (index) {
                              case 0:
                                setSelectedChartType(REPORT_RECORDS)
                                break;
                              default:
                                setSelectedChartType(SEQUENCE_RECORDS)
                                break;
                            }
                          }}
                        >{name}</Button>
                      }
                      )
                    }
                  </div>
                </div>

                {selectedChartType === REPORT_RECORDS && <PercentageBar list={reportRecordsList} totalRecords={peptideReportRecordsTotal}/>}

                {selectedChartType === SEQUENCE_RECORDS && <PercentageBar list={seqRecordsList} totalRecords={peptideSeqRecordsTotal}/>}

              </StyledTableCell>
            </TableRow>
          </TableBody>
        </MuiTableContainer>


        {/* 1 ncTerminalCoding */}
        <Space />
        <Space />
        <ChartSection
          sectionTitle={words.barChartTitle}
          chartBlockTitle={words.ncTerminalCoding.title}
          chartBlockSubTitle={words.ncTerminalCoding.text}
          chartBlockSubTitleNumber={totalSeq}
          mainChart={
            <BarChart
              data={ncTerminalCodingData}
              options={ncTerminalCodingOptions}
              showTableLabel={true}
              yAxisLabel={words.ncTerminalCoding.y} />
          } />
          
        <Space />
        <PeptideXYTable setInfo={setInfo} />

        {/* 2 distributionNTerminal */}
        <Space />
        <ChartSection
          chartBlockTitle={words.distributionNTerminal.title}
          chartBlockSubTitle={words.distributionNTerminal.text}
          chartBlockSubTitleNumber={totalSeq}
          mainChart={
            <BarChart
              data={distributionNTerminalData}
              options={distributionNTerminalOptions}
              showTableLabel={true}
              yAxisLabel={words.distributionNTerminal.y} />
          } />

        {/* 3 distributionCTerminal */}
        <Space />
        <ChartSection
          chartBlockTitle={words.distributionCTerminal.title}
          chartBlockSubTitle={words.distributionCTerminal.text}
          chartBlockSubTitleNumber={totalSeq}
          mainChart={
            <BarChart data={distributionCTerminalData}
              options={distributionCTerminalOptions}
              yAxisLabel={words.distributionCTerminal.y}
              showTableLabel={true} />
          } />

        {/* 4 lengthDistributionPeptides */}
        <Space />
        <ChartSection
          chartBlockTitle={words.lengthDistributionPeptides.title}
          chartBlockSubTitle={words.lengthDistributionPeptides.text}
          chartBlockSubTitleNumber={totalSeq}
          mainChart={
            <BarChart
              data={lengthDistributionPeptidesData}
              options={lengthDistributionPeptidesOptions}
              yAxisLabel={words.lengthDistributionPeptides.y} />
          } />

        {/* 5 aminoAcidPeptides */}
        <Space />
        <ChartSection
          chartBlockTitle={words.aminoAcidPeptides.title}
          chartBlockSubTitle={words.aminoAcidPeptides.text}
          chartBlockSubTitleNumber={totalSeq}
          mainChart={
            <BarChart
              data={aminoAcidPeptidesData}
              options={aminoAcidPeptidesOptions}
              yAxisLabel={words.aminoAcidPeptides.y} />
          } />

        {/* 6 molecularWeight */}
        <Space />
        <ChartSection
          chartBlockTitle={words.molecularWeight.title}
          chartBlockSubTitle={words.molecularWeight.text}
          chartBlockSubTitleNumber={totalSeq}
          mainChart={
            <BarChart
              data={molecularWeightData}
              options={molecularWeightOptions}
              yAxisLabel={words.molecularWeight.y} />
          } />


      </div>
      
    </LayoutPage>)
};

export default PeptideStatistics;
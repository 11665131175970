import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Grid, IconButton, Input, MenuItem, Select, TableCell, TableHead, TableRow, tableCellClasses } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

// -----custom tools-------------------------------------------------------
import 'assets/scss/common.scss';
import 'assets/scss/temp.scss';
import v from 'assets/scss/_variables.scss';
import scss from './ToolACEiPPPreLibraries.module.scss';
import scssTools from 'views/tool/Tools.module.scss';
import { breadData } from 'components/bread/BreadBar';
import Text, { TagText } from 'components/tag/Text';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import LayoutPage from 'layouts/LayoutPage';
import { RouterLink } from 'components/router/RouterLink';
import { MuiTableCell, MuiTableContainer, TableHeadRow, TableRow2Body } from 'components/table/MuiTable';
import fs, {fileNames, files} from 'utils/files';
import { spp } from 'utils/network/jsons';
import chartProp from 'components/chart/chartProp';
import LoadingAnime from 'components/LoadingAnime';
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import { MuiTableCell4Head, TableBodyCellSeqFeat, TableCell4HeadTop, TableHeadAndRow, chartDataBtnList, chartTextData, chartTypeBtnList, datasetsData } from './ToolACEiPPSeqFeatures';

//---------------------------------------------------------------------------
// path: paths.spp.tool.anoxpp_sf,
const texts = {
  bread: breadData.create('Tools', 'Antioxidative Peptide Predictor', 'AnOxPP Seq-Features'),
  
  terminal_labels: [
    'AnOxPs N-terminal',
    'AnOxPs C-terminal',
    'non-AnOxPs N-terminal',
    'non-AnOxPs C-terminal',
  ],
  
  datasets: {
    title: 'Download the Datasets',
    tableHeadCell: [
      'DataSets',
      'Classifications',
      'Samples',
      'Download',
    ],
    tableBodyCell: [
      'datasets',
      'classifications',
      'samples',
      'download',
    ],
    
    value: [
      datasetsData('Total datasets', 0, 1060),
      datasetsData('', 1, 1060),
      datasetsData('Training dataset', 0, 848),
      datasetsData('', 1, 848),
      datasetsData('Independent test set', 0, 212),
      datasetsData('', 1, 212),
      datasetsData('New AnOxPs\n(Reported in 2021 and 2022)', 0, 72),
    ],
    
    classifications: [
      'AnOxPs',
      'non-AnOxPs',
    ],
  },
}

//---------------------------------------------------------------------------
const ToolAnOxPPSeqFeatures = ({ setInfo }) => {
  
  const chartLabelY = 'Percentage (%)'
  const words = {
    chartTitle: 'The sequence features of benchmark datasets',
    
    chartLength: chartTextData('Statistics of peptide length', 'Peptide length', chartLabelY),
    chartMass: chartTextData('Statistics of peptide mass', 'MW distribution', chartLabelY),
    chartTerminal: chartTextData('Statistics of peptide terminal', 'Amino acid', chartLabelY),
    chartComposition: chartTextData('Statistics of amino acid composition', 'Amino acid', chartLabelY),
  }
  const [chartTextResult, setChartTextResult] = useState(textChartResult(0,0))
  function textChartResult(numConfrom, numNon) {
    return `${texts.datasets.classifications[0]}: ${numConfrom} results, ${texts.datasets.classifications[1]}: ${numNon} results`;
  }
  
  //---------------------------------------------------------------------------
  // chart
  const jsons = spp.tool.aceipp_anoxpp_seq_chart
  const [outputLength, setLength] = useState({})
  const [chartLengthLabels, setLengthLabels] = useState([])
  const [chartLengthPos, setLengthPos] = useState([])
  const [chartLengthNeg, setLengthNeg] = useState([])
  
  const [outputMass, setMass] = useState({})
  const [chartMassLabels, setMassLabels] = useState([])
  const [chartMassPos, setMassPos] = useState([])
  const [chartMassNeg, setMassNeg] = useState([])
  
  const [outputTerminal, setTerminal] = useState({})
  const [chartTerminalLabels, setTerminalLabels] = useState([])
  const [chartTerminalPosN, setTerminalPosN] = useState([])
  const [chartTerminalNegN, setTerminalNegN] = useState([])
  const [chartTerminalPosC, setTerminalPosC] = useState([])
  const [chartTerminalNegC, setTerminalNegC] = useState([])
  
  const [outputComposition, setComposition] = useState({})
  const [chartCompositionLabels, setCompositionLabels] = useState([])
  const [chartCompositionPos, setCompositionPos] = useState([])
  const [chartCompositionNeg, setCompositionNeg] = useState([])
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiChartResult = (method) => {
    const input = jsons.input(jsons.dataset.AnOxPs, method)
    const config = apiConfig.tool.aceipp_anoxpp_seq_chart(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        // console.log(data)
        let labels = data[jsons.output[2]]
        let pos = data[jsons.output[3]] //"pos_value",
        let neg = data[jsons.output[4]] //"neg_value",
        switch(method) {
          case jsons.method.Length:
            // console.log('Length', data)
            setLength(data)
            setLengthLabels(labels)
            setLengthPos(pos)
            setLengthNeg(neg)
            break
          case jsons.method.Mass:
            // console.log('Mass', data)
            setMass(data)
            setMassLabels(labels)
            setMassPos(pos)
            setMassNeg(neg)
            break
          case jsons.method.Terminal:
            // console.log('Terminal', data)
            setTerminal(data)
            setTerminalLabels(labels)
            if( Array.isArray(pos) ) {
              setTerminalPosN(pos[0])
              setTerminalPosC(pos[1])
            }
            if( Array.isArray(neg) ) {
              setTerminalNegN(neg[0])
              setTerminalNegC(neg[1])
            }
            break
          case jsons.method.Composition:
            // console.log('Composition', data)
            setComposition(data)
            setCompositionLabels(labels)
            setCompositionPos(pos)
            setCompositionNeg(neg)
            break
          default:
            break
        }
        
        let numACEiPs = data[jsons.output[0]] //"pos_total"
        let numNonACEiPs = data[jsons.output[1]] //"neg_total"
        setChartTextResult(textChartResult(numACEiPs, numNonACEiPs))
        
        setSelectChartMethod(chartMethod.Length)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
      
      setChartTextResult(textChartResult(0, 0))
      setSelectChartOptions([])
      setSelectChartData([])
    })
  }
  
  //------------------------------------------------------------------------------
  // chart setting
  const chartLabel = texts.datasets.classifications
  const chartLengthOptions = chartProp.options.axisTitleXY(words.chartLength.x, words.chartLength.y)
  const chartLengthData = chartProp.data.carateMulti(
    "",
    "",
    chartLengthLabels, //"x_axis"
    [chartProp.data.singleDataset(
      chartLabel[0], chartLengthPos, v.green, v.chartHoverGreen //"pos_value"
    ), chartProp.data.singleDataset(
      chartLabel[1], chartLengthNeg, v.purple, v.hoverPurple //"neg_value"
    )]
  )
  
  const chartMassOptions = chartProp.options.axisTitleXY(words.chartMass.x, words.chartMass.y)
  const chartMassData = chartProp.data.carateMulti(
    "",
    "",
    chartMassLabels, //"x_axis"
    [chartProp.data.singleDataset(
      chartLabel[0], chartMassPos, v.green, v.chartHoverGreen //"pos_value"
    ), chartProp.data.singleDataset(
      chartLabel[1], chartMassNeg, v.purple, v.hoverPurple //"neg_value"
    )]
  )
  
  const bgColors = [
    "#0dddcd", "#43F7F2",
    "#6AB5F1", "#856DF0"
  ]
  const bgHoverColors = [
    "#2FEBD3", "#54FFF5",
    "#7BBEF4", "#978FF3"
  ]
  const chartTerminalOptions = chartProp.options.axisTitleXY(words.chartTerminal.x, words.chartTerminal.y)
  const chartTerminalData = chartProp.data.carateMulti(
    "",
    "",
    chartTerminalLabels, //"x_axis"
    [chartProp.data.singleDataset(
      texts.terminal_labels[0], chartTerminalPosN, bgColors[0], bgHoverColors[0] //"pos_value"
    ), chartProp.data.singleDataset(
      texts.terminal_labels[1], chartTerminalPosC, bgColors[1], bgHoverColors[1] //"neg_value"
    ), chartProp.data.singleDataset(
      texts.terminal_labels[2], chartTerminalNegN, bgColors[2], bgHoverColors[2] //"pos_value"
    ), chartProp.data.singleDataset(
      texts.terminal_labels[3], chartTerminalNegC, bgColors[3], bgHoverColors[3] //"neg_value"
    ),]
  )
  
  const chartCompositionOptions = chartProp.options.axisTitleXY(words.chartComposition.x, words.chartComposition.y)
  const chartCompositionData = chartProp.data.carateMulti(
    "",
    "",
    chartCompositionLabels, //"x_axis"
    [chartProp.data.singleDataset(
      chartLabel[0], chartCompositionPos, v.green, v.chartHoverGreen //"pos_value"
    ), chartProp.data.singleDataset(
      chartLabel[1], chartCompositionNeg, v.purple, v.hoverPurple //"neg_value"
    )]
  )
  
  //------------------------------------------------------------------------------
  const chartMethod = jsons.method
  const [selectChartIndex, setSelectChartIndex] = useState()
  const [selectChartMethod, setSelectChartMethod] = useState()
  const [selectChartOptions, setSelectChartOptions] = useState(chartLengthOptions)
  const [selectChartData, setSelectChartData] = useState(chartLengthData)
  const [selectChartY, setSelectChartY] = useState(words.chartLength.y)
  const [selectBlockTitle, setSelectBlockTitle] = useState(words.chartLength.title)
  
  function handleClickMethod(index) {
    setSelectChartIndex(index)
    let method = index+1
    setSelectChartMethod(method)
    // console.log('index', index);
    // console.log('method', method);
    
    switch(method) {
      case chartMethod.Mass:
        setSelectChartOptions(chartMassOptions)
        setSelectChartData(chartMassData)
        setSelectChartY(words.chartMass.y)
        setSelectBlockTitle(words.chartMass.title)
        break
      case chartMethod.Terminal:
        setSelectChartOptions(chartTerminalOptions)
        setSelectChartData(chartTerminalData)
        setSelectChartY(words.chartTerminal.y)
        setSelectBlockTitle(words.chartTerminal.title)
        break
      case chartMethod.Composition:
        setSelectChartOptions(chartCompositionOptions)
        setSelectChartData(chartCompositionData)
        setSelectChartY(words.chartComposition.y)
        setSelectBlockTitle(words.chartComposition.title)
        break
      case chartMethod.Length:
      default:
        setSelectChartOptions(chartLengthOptions)
        setSelectChartData(chartLengthData)
        setSelectChartY(words.chartLength.y)
        setSelectBlockTitle(words.chartLength.title)
        break
    }
  }
  
  //------------------------------------------------------------------------------
  useEffect(() => {
    apiChartResult(jsons.method.Length)
    apiChartResult(jsons.method.Mass)
    apiChartResult(jsons.method.Terminal)
    apiChartResult(jsons.method.Composition)
  }, [])
  
  useEffect(() => {
    //setup init chart data with chartMethod.Length
    handleClickMethod(0)
  }, [chartLengthLabels]) //after get any chartMethod.Length data
  
  //----render-----------------------------------------------------------------
  return (
    <LayoutPage bread={texts.bread} desc={texts.intro}>
      <Box className={scssTools.layout}>
        <div className={scss.chart_type_btns}>
          {chartDataBtnList.map((name, index) => {
              return (
                <Button
                  key={index}
                  variant="outlined"
                  index={index}
                  className={selectChartIndex === index ? scss.selected_chart_type : ""}
                  onClick={() => {handleClickMethod(index)}}
                >{name}</Button>)
            })
          }
        </div>
        
        {state.isLoading ? <LoadingAnime />
        : <ChartSection
            sectionTitle={words.chartTitle}
            chartBlockTitle={selectBlockTitle}
            chartBlockSubTitle={chartTextResult}
            mainChart={
              <BarChart
                data={selectChartData}
                options={selectChartOptions}
                showTableLabel={true}
                yAxisLabel={selectChartY}
              />
            } />
        }
        
        <MuiTableContainer>
          <TableHeadAndRow>{/* text */}
            <TableCell4HeadTop colSpan={4}>
              {texts.datasets.title}</TableCell4HeadTop>
          </TableHeadAndRow>
          <TableHeadRow className="bgc-dark-green"> {/* title */}
            {texts.datasets.tableHeadCell.map((title, index) => (
              <MuiTableCell4Head key={index} index={index} align={index<=1 ? 'right' : 'center'}>{title}</MuiTableCell4Head>
            ))}
          </TableHeadRow>
          <TableBody>{/* value */}
            {texts.datasets.value.map((item, index) => (
              <TableRow2Body key={index}>
                <TableBodyCellSeqFeat key={index} index={index}
                  dataset={jsons.dataset.AnOxPs}
                  tableBodyCell={texts.datasets.tableBodyCell}>
                  {item}
                </TableBodyCellSeqFeat>
              </TableRow2Body>
            ))}
          </TableBody>
        </MuiTableContainer>
      </Box >
    </LayoutPage >
  )
}
export default ToolAnOxPPSeqFeatures
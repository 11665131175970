import React from 'react'
// import { useState } from "react";

import GreenDiv from 'components/GreenDiv';

//---------------------------------------------------------------------------
const texts = {
  notfound: 'Page Not Found',
}

//---------------------------------------------------------------------------
const NotFoundPage = () => {
  return (
    <GreenDiv>{texts.notfound}</GreenDiv>
  );
};

export default NotFoundPage;

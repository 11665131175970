import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Grid, IconButton, Input, MenuItem, Select, TableCell, TableHead, TableRow, tableCellClasses } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useRef, useState } from 'react';
import { Link, json, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

// -----custom tools-------------------------------------------------------
import 'assets/scss/common.scss';
import 'assets/scss/temp.scss';
import v from 'assets/scss/_variables.scss';
import scss from './ToolACEiPPPreLibraries.module.scss';
import scssACEiPP from './ToolACEiPP.module.scss';
import scssTools from 'views/tool/Tools.module.scss';
import { breadData } from 'components/bread/BreadBar';
import Text, { TagText } from 'components/tag/Text';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import LayoutPage from 'layouts/LayoutPage';
import { RouterLink } from 'components/router/RouterLink';
import { MuiTableCell, MuiTableContainer, TableHeadRow, TableRow2Body } from 'components/table/MuiTable';
import fs, {fileNames, files} from 'utils/files';
import { spp } from 'utils/network/jsons';
import chartProp from 'components/chart/chartProp';
import LoadingAnime from 'components/LoadingAnime';
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import { getKeyByValue } from 'utils/object';
import { Space } from 'utils/general';
import { MuiTableCell4Head, chartDataBtnList, chartTextData } from './ToolACEiPPSeqFeatures';

//---------------------------------------------------------------------------
// path: paths.spp.tool.aceipp_anoxpp_ur
const jsons = spp.tool.aceipp_anoxpp_upload_result
let texts = {
  tableHeadCell: jsons.items_text_aceipp,
  tableBodyCell: jsons.items,
  tableBodyCellError: jsons.error_items,
  
  tool_names: [
    'ACEiPP',
    'AnOxPP',
  ],
  nonActivity: 'non-',
}

//---------------------------------------------------------------------------
const ToolACEiPPAnOxPPUploadResult = ({ setInfo }) => {
  const [queryParameters] = useSearchParams()
  const datasetStr = queryParameters.get(paths.params.dataset)
  const dataset = Number(datasetStr)
  // console.log('dataset', dataset);
  
  let uploadResult = cookie.getCookie(cookie.keys.tool.aceippUploadResult)
  if( dataset === jsons.dataset.AnOxPs ) {
    uploadResult = cookie.getCookie(cookie.keys.tool.anoxppUploadResult)
    texts.tableHeadCell = jsons.items_text_anoxpp
  }
  // console.log('uploadResult', uploadResult);
  
  const datasetName = getKeyByValue(jsons.dataset, dataset)
  const datasetNameNon = texts.nonActivity + datasetName
  // console.log(datasetName);
  const toolIndex = dataset - 1
  const toolName = (toolIndex >= 0 && toolIndex < texts.tool_names.length) ? texts.tool_names[toolIndex] : ''
  
  const keyOutput = jsons.output
  const [tableOutput, setTableOutput] = useState({})
  const words = {
    bread: breadData.create('Tools', `${toolName}-1.0 Server Output`, `${toolName} Pre-${datasetName}`, `Upload Result` ),
    
    mission: 
`Mission completed:
    # Upload time: ${tableOutput[keyOutput[0]]}
    # Time cost: ${tableOutput[keyOutput[1]]}s.
    # A total of ${tableOutput[keyOutput[2]]} sample(s) were/was submitted for this task.
    # ${tableOutput[keyOutput[3]]} error(s) occurred.
    # A total of ${tableOutput[keyOutput[4]]} ${datasetName} and ${tableOutput[keyOutput[5]]} ${datasetNameNon} were/was predcited.
For complete information, please see the following output:`
    ,
    
    dstasets: [
      'Upload file',
      'Prediction file',
      datasetName,
      datasetNameNon,
      'Error log',
    ],
    dstasets_error: 'Error',
  }
  
  //------------------------------------------------------------------------------
  // table
  const [tableItems, setTableItems] = useState([])
  const [errorItems, setErrorItems] = useState([])
  const [isNoErrorItems, setIsNoErrorItems] = useState(true)
  const [stateTable, setStateTable] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiTableResult = () => {
    let data = uploadResult
    setTableOutput(data)
    // console.log(data)
    
    let items = data[spp.common.output.items]
    if(items !== undefined)
      setTableItems(items)
    // console.log('items', items);
    
    let isNoErr = true
    let itemsErr = data[keyOutput[6]] //"error_item(s)"
    if(itemsErr !== undefined && (Array.isArray(itemsErr) && itemsErr.length > 0)) {
      setErrorItems(itemsErr)
      isNoErr = false
    }
    setIsNoErrorItems(isNoErr)
    // console.log('itemsErr', itemsErr);
    // console.log('isNoErr', isNoErr);
    
    setStateTable(axiosState.resultCode200())
  }
  
  function getCellAlign(index) {
    if(index === 0) //'Data Sets'
      return 'right'
    if(index === 1) //Name
      return 'left'
    return 'center'
  }
  
  const TableBodyCell = (props) => {
    const { tableBodyCell, children, index } = props
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('children', props.children);
    let isDownload = false
    let value, text, align, rowSpan = 1, name
    return (
      tableBodyCell.map((cell, indexX) => {
        value = children[cell]
        text = value
        if(text === undefined)
          text = '-'
        // console.log('cell', cell, 'value', value);
        
        align = 'center'
        if(indexX === 0 ) //Data Sets
          align = 'right'
        if(cell === 'Data Sets') {
          text = words.dstasets[index]
        }
        if(cell === "name" ) { //Name
          align = 'left'
        }
        if(cell === "link") { //Download
          isDownload = true
          name = children['name']
        }
        
        return (
          <MuiTableCell key={index+''+indexX+''+cell} align={align} rowSpan={rowSpan}
            style = {{paddingTop: '2px', paddingBottom: '2px', height: '32px'}}>
            {isDownload
            ? cellDownload(value, name)
            : text}
          </MuiTableCell>)
      }))
  }
  
  const cellDownload = (file, name) => {
    // console.log(name);
    // console.log(file);
    if( file !== undefined && file !== null ) {
      return (
        <a href={file} download={name} target="_blank">
          <CloudDownloadOutlinedIcon />
        </a>)
    } else
      return null
  }
  
  //------------------------------------------------------------------------------
  const TableRowBodyCellErorr = (props) => {
    const { tableBodyCell, items } = props
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('items', props.items);
    let _style = {paddingTop: '2px', paddingBottom: '2px', height: '32px'}
    return (
      <TableRow2Body>
        <MuiTableCell align={'right'} rowSpan={1} style={_style}>
          {words.dstasets_error}
        </MuiTableCell>
        <MuiTableCell align={'left'} style={_style}>
          <Text pre>{cellErrorText(tableBodyCell, items)}</Text>
        </MuiTableCell>
      </TableRow2Body>)
  }
  
  function cellErrorText(tableBodyCell, items) {
    let value, text, ans = ''
    if(Array.isArray(items)) {
      items.map((item) => {
        tableBodyCell.map((cell, indexX) => {
            value = item[cell]
            text = value + '\n'
            if(indexX === 0)
              ans += text
            else
              ans += '    ' + text
        })
    })}
    return ans
  }
  
  //------------------------------------------------------------------------------
  useEffect(() => {
    apiTableResult()
  }, [])
  
  const tableCol = texts.tableHeadCell.length
  //----render-----------------------------------------------------------------
  return (
    <LayoutPage bread={words.bread}>
      <Box className={scssTools.layout}>
        <div className={scssACEiPP.frame_intro}>
          <Text pre className={'h1-grey100'}>{words.mission}</Text>
        </div>
        
        <MuiTableContainer>
          <TableHeadRow className="bgc-dark-green"> {/* title */}
            {texts.tableHeadCell.map((title, index) => (
              <MuiTableCell4Head key={index} index={index} align={getCellAlign(index)}>
                {title}</MuiTableCell4Head>
            ))}
          </TableHeadRow>
          <TableBody>{/* value */}
            {stateTable.isLoading
            ? <MuiTableCell colSpan={tableCol}>
                <LoadingAnime />
              </MuiTableCell>
            : <>
                {tableItems.map((item, index) => (
                  <TableRow2Body key={index}>
                    <TableBodyCell key={index} index={index} tableBodyCell={texts.tableBodyCell}>
                      {item}
                    </TableBodyCell>
                  </TableRow2Body>
                ))}
                {isNoErrorItems ? <></>
                : <TableRowBodyCellErorr tableBodyCell={texts.tableBodyCellError}
                    items={errorItems}>
                  </TableRowBodyCellErorr>
                }
              </>}
          </TableBody>
        </MuiTableContainer>
        
      </Box >
    </LayoutPage >
  )
}
export default ToolACEiPPAnOxPPUploadResult
import { Box, Button, Checkbox, FormControlLabel, MenuItem, Select, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import 'assets/scss/common.scss';
import 'assets/scss/temp.scss';
import v from 'assets/scss/_variables.scss';
import scss from './ToolHotSpotSearch.module.scss';
import scssTools from 'views/tool/Tools.module.scss';

import { breadData } from 'components/bread/BreadBar';
import LoadingAnime from 'components/LoadingAnime';
import { TableRow2Body } from 'components/table/MuiTable';
import { snackInfo, snackType } from 'components/SnackBar';
import GreenButton from 'components/button/GreenButton';
import WhiteButton from 'components/button/WhiteButton';
import StyledTextField from 'components/tool/StyledTextField';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { checkInvalidSeq, getFullPath, isEmptyObjOrArray, isOnlySpacesOrNewlines } from 'utils/general';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import LayoutPage from 'layouts/LayoutPage';
import NoneButton from 'components/button/NoneButton';
import Text from 'components/tag/Text';
import MuiCheckbox from 'components/mui/MuiCheckbox';
import { linkData } from 'utils/links';
import MuiSelect from 'components/mui/MuiSelect';

//---------------------------------------------------------------------------
// Go in: homepage -> 'Tools' tab -> 'HotSpot Search' button -> this page
// path: paths.spp.tool.hot_spot_search,
const texts = {
  bread: breadData.create('Tools', 'HotSpot Search', 'HotSpot'),

  text: 'HotSpot Search Tool is to find the position distribution of the peptides in different proteins.',
  searchHint: 'Please enter single letter sequences',
  required: 'Required',

  step: [
    'Step 01',
    'Step 02 (Optional)',
    'Step 03'
  ],

  step1_1: [
    'choose a certain type of protein',
    'Select a single protein',
    'Enter single letter sequence'
  ],

  select: {
    class: 'Classification:',
    source: 'Source:',
    protein: 'Protein name:',
  },

  button: {
    reset: "Reset",
    search: "Search",
    checked: [
      'Check All',
      'Uncheck All',
    ],
  }
}

function indexData(start, end, label) {
  return { start, end, label }
}

function optionData(label, value) {
  return { label, value }
}

const MuiTableContainer = (props) => (
  <TableContainer variant="outlined" className={"table_container"} sx={{ border: 0 }}>
    <Table aria-label="a dense table" sx={{ border: 0 }} {...props}>
      {props.children}
    </Table>
  </TableContainer>
)

export const MuiTableHead = (props) => (
  <TableHead className={props.className ? props.className : "bgc-dark-green"} >
    {props.children}
  </TableHead>
)

export const MuiTableHeadCell = (props) => (
  <MuiTableHead className={props.className} sx={{ border: 0 }}>
    <TableRow sx={{ border: 0 }}>
      <StyledTableCell style={{ color: v.darkGreen, fontSize: '24px' }} sx={{ border: 0 }} {...props}>
        {props.children}
      </StyledTableCell>
    </TableRow>
  </MuiTableHead>
)

const sppList = spp.protein.list
export function getClassifyIndexArr(proteinList, colSize) {
  let classIndex = []
  // for (const i of Array(Math.ceil(item.length/6)).keys())
  //   index.push(indexData(i * 6, (i * 6) + 6))
  let className = ''
  let startIndex = 0
  proteinList.forEach((obj, index) => {
    let name = obj[sppList.items.class]
    // console.log('class name', name);
    if (className === '')
      className = name
    if (className !== name && className !== '') {
      // console.log('startIndex', startIndex, 'index', index);
      classIndex.push(indexData(startIndex, index - 1, className))
      className = name
      startIndex = index
    }

    if (className === name && (index - startIndex + 1) === colSize) {
      classIndex.push(indexData(startIndex, index, className))
      startIndex = index + 1
    }
    
    if (index === proteinList.length - 1)
      classIndex.push(indexData(startIndex, index, className))
  })
  
  return classIndex
}

export function getOptions(items, db_label, db_value) {
  let tmp = []
  tmp = items.map(a => a[db_label]);
  let labels = [...new Set(tmp)];
  tmp = items.map(a => a[db_value]);
  let values = [...new Set(tmp)];
  let options = []
  labels.forEach((label, index) => (
    options.push(optionData(label, values[index]))
  ))
  // let options = labels.map((label, index) => optionData(label, values[index]))
  // console.log('options', options);
  return options
}

// using for tools page
export function cleanHotSpotState() {
  cookie.setCookie(cookie.keys.tool.hotspotUserClass, '')
  cookie.setCookie(cookie.keys.tool.hotspotUserSource, '')
  cookie.setCookie(cookie.keys.tool.hotspotUserName, '')
  cookie.setCookie(cookie.keys.tool.hotspotUserProtein, '')
  cookie.setCookie(cookie.keys.tool.hotspotUserProteinClass, '')
  cookie.setCookie(cookie.keys.tool.hotspotUserKeyword, '')
}

//---------------------------------------------------------------------------
const ToolHotSpotSearch = ({ setInfo }) => {
    
  let location = useLocation()
  let bread = linkData('HotSpot', getFullPath(location))
  let json3rd = JSON.stringify(bread)
  // console.log('bread3rd', bread);
  cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
  
  //---------------------------------------------------------------------------
  const colSize = 3
  const [outputList, setOutputList] = useState({})
  const [itemsList, setItemsList] = useState([])
  const [classifyList, setClassifyList] = useState([])
  const [classifyIndex, setClassifyIndex] = useState([])
  const [stateList, setStateList] = useState(axiosState.init())
  const navigate = useNavigate()
  const apiList = () => {
    const config = apiConfig.protein.list() //10
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateList(axiosState.error(false, stateList.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setStateList(axiosState.resultCode200())
        // console.log(result.data);

        setOutputList(result.data)
        let item = result.data[spp.common.output.items]
        if( !isEmptyObjOrArray(item) )
          item = item.map((item) => ({...item, isCheck: false, isDisable: false}))
        setItemsList(item)
        // console.log('items', item);

        let classes = getOptions(item, sppList.items.class, sppList.items.class_id)
        if( !isEmptyObjOrArray(classes) )
          classes = classes.map((item) => ({...item, isCheck: false, isDisable: false}))
        setClassifyList(classes)
        // console.log('classify', classes);

        let classIndex = []
        // for (const i of Array(Math.ceil(item.length/6)).keys())
        //   index.push(indexData(i * 6, (i * 6) + 6))
        let className = ''
        let startIndex = 0
        item.forEach((obj, index) => {
          let name = obj[sppList.items.class]
          // console.log('class name', name);
          if (className === '')
            className = name
          if (className !== name && className !== '') {
            // console.log('startIndex', startIndex, 'index', index);
            classIndex.push(indexData(startIndex, index - 1, className))
            className = name
            startIndex = index
          }

          if (className === name && (index - startIndex + 1) === colSize) {
            classIndex.push(indexData(startIndex, index, className))
            startIndex = index + 1
          }

          if (index === item.length - 1)
            classIndex.push(indexData(startIndex, index, className))
        })

        setClassifyIndex(classIndex)
        // console.log('classIndex', classIndex);
        
        //load user select option from cookie on init state
        loadUserStateStep1(item)
        loadUserStateStep2(item)
      }
    }).catch(err => {
      setStateList(axiosState.error(axiosCatch.isTimeout(err), stateList.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  
  //---------------------------------------------------------------------------
  const [isGetting, setIsGetting] = useState(false)
  const [proteinList, setProteinList] = useState([])
  const [proteinNameStartIdx, setProteinNameStartIdx] = useState(0)
  const [state, setState] = useState(axiosState.init())
  const jsonsHot = spp.tool.hot_spot
  const apiProteinName = () => {
    setIsGetting(false)
    let input = jsonsHot.input(classifyId, sourceId, proteinNameStartIdx)
    setInput(input)
    
    const config = apiConfig.tool.hotspot_protein_name(input) //38
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      input = jsonsHot.input(classifyId, sourceId, proteinNameStartIdx)
      setInput(input)

      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let items = result.data[spp.common.output.items]
        let itemsFix = items.map((item) => ({...item,
          "protein_name": String(item['protein_name']).removeHTMLTags() }))
        // console.log('items', items, 'itemsFix', itemsFix);
        setProteinList(oldList => [...oldList, ...itemsFix]);
        setProteinNameStartIdx(result.data.end_index)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  // Step 01
  const handleChangeClassify = (id, prevClassify) => {
    let bCheck
    setClassifyList(prevItems => prevItems.map((item) => {
      if(item.value === id) {
        bCheck = !item.isCheck
        // console.log('bCheck', bCheck);
        setItemsList(prevItems => prevItems.map((item) => {
          if(item.classification_id === id)
            return { ...item, isCheck: bCheck };
          return item
        }))
        
        return { ...item, isCheck: bCheck };
      }
      return item
    }))
    
    if(bCheck)
      setProteinId('')
  }
  
  const handleChangeSource = (id, prevSource) => {
    // console.log(prevSource);
    let bCheck, class_id
    setItemsList(prevItems => prevItems.map((item) => {
      if(item.source_id === id) {
        bCheck = !item.isCheck
        class_id = item.classification_id
        return { ...item, isCheck: bCheck };
      }
      return item
    }))
        
    let bCheckClass = bCheck
    if(bCheck) {
      setProteinId('')
      
      let total = 0, checked = 0
      prevSource.forEach((item) => {
        if(item.classification_id === class_id) {
          total++
          if(item.isCheck)
            checked++
      }})
      // console.log('class_id', class_id, '=', total, 'v=', checked);
      if( total > 0 && total - checked <= 1 )
        bCheckClass = true
      else
        bCheckClass = false
    }
    
    if( bCheckClass !== undefined )
      setClassifyList(prevItems => prevItems.map((item) => {
          if(item.value === class_id)
            return { ...item, isCheck: bCheckClass };
          return item
      }))
  }
  
  const [isDisableUncheck, setDisableUncheck] = useState(false)
  const handleClickOption = (value) => {
    let bCheck
    switch (value) {
      case texts.button.checked[0]: //'Check All'
        bCheck = true
        setProteinId('')
        setDisableUncheck(false)
        break
      case texts.button.checked[1]: //'Uncheck All',
        bCheck = false
        break
      default:
    }
    
    setClassifyList(prevItems => prevItems.map((item) => ({ ...item,
      isCheck: bCheck,
      isDisable: false,
    })))
    setItemsList(prevItems => prevItems.map((item) => ({...item, 
      isCheck: bCheck,
      isDisable: false,
    })))
  }
  
  function loadUserStateStep1(itemsList) {
    const userProteinId = cookie.getCookie(cookie.keys.tool.hotspotUserProtein) //Protein ID list
    const userProteinIdArr = userProteinId ? userProteinId.split(',') : ''
    const userClassId = cookie.getCookie(cookie.keys.tool.hotspotUserProteinClass) //Protein Classify list
    const userClassIdArr = userClassId ? userClassId.split(',') : ''
    // console.log('userClassIdArr', userClassIdArr);
    
    //Source e.g. Cow milk, Horse milk, Sheep milk...
    if( userProteinIdArr.length > 0 )
      userProteinIdArr.forEach((id) => handleChangeSource(id, itemsList))
    
    //Source Classify e.g. Milk, Plant, Animal...
    if( userClassIdArr.length > 0 ) {
      userClassIdArr.forEach((id) => 
        setClassifyList(prevItems => prevItems.map((item) => {
          if(item.value === id)
            return { ...item, isCheck: true };
          return item
      })))
    }
  }
  
  //---------------------------------------------------------------------------
  // Step 02 (Optional)
  const [classifyId, setClassifyId] = useState('')
  const handleChangeClassifyId = (value) => {
    setClassify(value, itemsList)
    setSourceId('')
    setProteinId('')
    changeOptionStep1(false)
  }
  function setClassify(value, itemsList) {
    setClassifyId(value);
    // console.log('classify id:', value);
    let list = itemsList.filter(e => e[sppList.items.class_id] === value)
    let source = getOptions(list, sppList.items.source, sppList.items.source_id)
    setSourceList(source)
    // console.log('source list', list);
    // console.log('source option', source);
  }
  const [input, setInput] = useState({})
  const [sourceId, setSourceId] = useState('')
  const [sourceList, setSourceList] = useState([])
  const handleChangeSourceId = (value) => {
    // console.log('value', value, 'sourceId', sourceId);
    // console.log('source id:', value);
    setSourceId(value);
    setProteinId('')

    setProteinNameStartIdx(0)
    setProteinList([])

    if (value !== '') {
      setState(axiosState.init())
      setIsGetting(true)
    }
    changeOptionStep1(false)
  }
  const [proteinId, setProteinId] = useState('')
  const [sppId, setSppId] = useState('')
  const handleChangeProteinId = (value) => {
    setProteinId(value);
    setSppId(value)
    // console.log('protein name:', value);
    
    let bDisable = (value !== '')
    changeOptionStep1(bDisable)
  }
  
  function changeOptionStep1(bDisable) {
    setClassifyList(prevItems => prevItems.map((item) => ({...item, 
      isDisable: bDisable,
    })))
    setItemsList(prevItems => prevItems.map((item) => ({...item, 
      // isCheck: false,
      isDisable: bDisable,
    })))
    setDisableUncheck(bDisable)
  }
  
  function loadUserStateStep2(itemsList) {
    const userClass = cookie.getCookie(cookie.keys.tool.hotspotUserClass)
    const userSource = cookie.getCookie(cookie.keys.tool.hotspotUserSource)
    const userName = cookie.getCookie(cookie.keys.tool.hotspotUserName)
    if( userClass !== undefined ) {
      setClassify(userClass, itemsList)
      
      if( userSource !== undefined ) {
        handleChangeSourceId(userSource)
        if( userName !== undefined )
          handleChangeProteinId(userName)
    }}
  }
  
  //---------------------------------------------------------------------------
  // Step 03
  let userKeyword = cookie.getCookie(cookie.keys.tool.hotspotUserKeyword)
  if( userKeyword === undefined )
    userKeyword = ''
  const [isSearchError, setSearchError] = useState(false)
  const [isSeqInputError, setIsSeqInputError] = useState(false)
  const [seqInputErrMsg, setSeqInputErrMsg] = useState("")
  const [keyword, setKeyword] = useState(userKeyword)
  const handleChangeSearch = (value) => {
    // console.log('value', value);
    setKeyword(value)
    if (value === "") {
      setIsSeqInputError(false)
      setSeqInputErrMsg("")
    }
    setSearchError(false)
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter')
      handleClickSearch()
  }

  //---------------------------------------------------------------------------
  // Bottom
  const handleClickReset = () => {
    setClassifyId('')
    setSourceId('')
    setProteinId('')
    setKeyword('')
    
    setClassifyList(prevItems => prevItems.map((item) => ({ ...item,
      isCheck: false, isDisable: false
    })))
    setItemsList(prevItems => prevItems.map((item) => ({ ...item,
      isCheck: false, isDisable: false
    })))
    setDisableUncheck(false)
    
    cleanHotSpotState()
  }
  
  const handleClickSearch = () => {
    const isInvalidCharObj = checkInvalidSeq(keyword);
    const onlySpaceNewlines = isOnlySpacesOrNewlines(keyword)

    if (isInvalidCharObj.isInvalid) {
      setIsSeqInputError(true)
      setSeqInputErrMsg(isInvalidCharObj.errMsg)
      setInfo(snackInfo.open(snackType.error, isInvalidCharObj.errMsg))
    } else if (onlySpaceNewlines.isInvalid) {
      setIsSeqInputError(true)
      setSeqInputErrMsg(onlySpaceNewlines.errMsg)
      setInfo(snackInfo.open(snackType.error, onlySpaceNewlines.errMsg))
    } else {
      setIsSeqInputError(false)
      setSeqInputErrMsg("")
      
      // console.log('itemsList', itemsList);
      let src = (itemsList.filter(item => item.isCheck))
      let cls = (classifyList.filter(item => item.isCheck))
      // console.log('source', src);
      // console.log('class', cls);
      
      if (keyword !== '') {
        if (classifyId !== '' && sourceId !== '' && proteinId !== '') {
          cookie.setCookie(cookie.keys.tool.hotspotUserClass, classifyId)
          cookie.setCookie(cookie.keys.tool.hotspotUserSource, sourceId)
          cookie.setCookie(cookie.keys.tool.hotspotUserName, proteinId)
          cookie.setCookie(cookie.keys.tool.hotspotUserProtein, '')
          cookie.setCookie(cookie.keys.tool.hotspotUserKeyword, keyword)
          // navigate(`${paths.spp.tool.hot_spot_result}?class=${classifyId}&source=${sourceId}&protein=${proteinName}&keyword=${keyword}`)
          
          // Modify only send spp id to next page
          navigate(paths.spp.tool.hotspot_result(sppId, keyword))
        } else if (src.length > 0) {
          let srcId = src.map(item => item[sppList.items.source_id])
          let srcIdStr = srcId.toString()
          let clsId = cls.map(item => item.value)
          let clsIdStr = clsId.toString()
          // console.log('sourceIdStr', srcIdStr);
          // console.log('classIdStr', clsIdStr);
          cookie.setCookie(cookie.keys.tool.hotspotUserClass, '')
          cookie.setCookie(cookie.keys.tool.hotspotUserSource, '')
          cookie.setCookie(cookie.keys.tool.hotspotUserName, '')
          cookie.setCookie(cookie.keys.tool.hotspotUserProtein, srcIdStr)
          cookie.setCookie(cookie.keys.tool.hotspotUserProteinClass, clsIdStr)
          cookie.setCookie(cookie.keys.tool.hotspotUserKeyword, keyword)
          
          navigate(paths.spp.tool.hotspot_result_chart(keyword, { cls: srcIdStr }))
        } else {
          setInfo(snackInfo.open(snackType.error, "Please finish step1 & step2(optional)"))
          setSearchError(true)
        }
      } else {
        setSearchError(true)
      }
    }
  }

  //---------------------------------------------------------------------------
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      if (proteinNameStartIdx !== -1)
        apiProteinName();
    }
  };
  
  useEffect(() => {
    if (axiosState.keepRest(stateList))
      apiList()
    if (axiosState.keepRest(state) && isGetting === true)
      apiProteinName()
    
    // console.log(outputList);
    // console.log(proteinList);
    // eslint-disable-next-line
  }, [isGetting])
  
  const css = {
    text_select: 'b1-darkGreen',
  }
  
  //---------------------------------------------------------------------------
  return (
    <LayoutPage bread={texts.bread} desc={texts.text}>
      <Box className={scssTools.layout}>
        <ToolSingleBlock stepText={texts.step[0]} title={texts.step1_1[0]}
          mainBlockContent={
            stateList.isLoading
            ? <LoadingAnime />
            : classifyList.map((option, idx) => (
                <MuiTableContainer key={option.value}>
                  <MuiTableHeadCell key={option.value} className={'bgc-white'} colSpan={colSize}>
                    <FormControlLabel
                      key={`form-${idx}`}
                      control={
                        <MuiCheckbox
                          key={option.value}
                          name={option.label}
                          value={option.value}
                          checked={option.isCheck}
                          disabled={option.isDisable}
                          onChange={() => handleChangeClassify(option.value, classifyList)}
                          inputProps={{ 'aria-label': 'controlled' }} />}
                      label={
                        <span className={option.isDisable ? 'h4-green75' : 'h4-darkGreen'}>{option.label}</span>}
                    />
                    </MuiTableHeadCell>

                  <TableBody key={option.label} sx={{ border: 0 }}>
                    {classifyIndex.map((value, count) => {
                      //console.log('value:', value, 'count:', count);
                      if (value.label === option.label) {
                        return (
                          <TableRow2Body key={`body-${count}`} style={{ border: 0 }} sx={{ border: 0 }}>
                            { // slice end position start index from 1
                              itemsList.slice(value.start, value.end + 1).map((item, index) => {
                                // console.log('start:', value.start, 'index:', index);
                                let idx = value.start + index
                                return (
                                  <StyledTableCell key={`cell-${count}-${index}`} sx={index === 0 ? { padding: 0, paddingLeft: 2, border: 0, width: 280 } : { padding: 0, border: 0, width: 240 }} >
                                    <FormControlLabel
                                      key={`form-${count}-${index}`}
                                      style={{ whiteSpace: 'pre-line' }}
                                      control={
                                        <MuiCheckbox
                                          key={item.source_id}
                                          name={item.source}
                                          value={idx}
                                          checked={item.isCheck}
                                          disabled={item.isDisable}
                                          onChange={() => handleChangeSource(item.source_id, itemsList)}
                                          inputProps={{ 'aria-label': 'controlled' }} />}
                                      label={`${(item.source).trimClassifyName()}`}
                                    />
                                  </StyledTableCell>
                                )
                              })}
                          </TableRow2Body>
                        )
                      }
                      return null
                    })}
                  </TableBody>
                </MuiTableContainer>
              ))
          }
          anotherBlockContent={
            <div className={scss.selectItem_button}>
              <NoneButton //Check All
                className={scss.button_checked}
                variant="contained"
                size="small"
                disabled={isDisableUncheck}
                onClick={() => handleClickOption(texts.button.checked[0])}
              >{texts.button.checked[0]}</NoneButton>
              <NoneButton //Uncheck All
                className={scss.button_checked}
                variant="contained"
                size="small"
                disabled={isDisableUncheck}
                onClick={() => handleClickOption(texts.button.checked[1])}
              >{texts.button.checked[1]}</NoneButton>
            </div>
            // texts.button.checked.map((item) => (
            //   selectOption(item)
            // ))
          }>
        </ToolSingleBlock>

        <ToolSingleBlock stepText={texts.step[1]} title={texts.step1_1[1]} mainBlockContent={
          <MuiTableContainer>
            <TableBody sx={{ border: 0 }}>
              <TableRow2Body sx={{ border: 0 }}>
                <StyledTableCell sx={{ border: 0 }}>
                  <div className={scss.row_select} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div className={scss.select_each_block} >
                      <Text className={css.text_select}>{texts.select.class}</Text>
                      <MuiSelect id="standard-select_class"
                        className={scss.select_class}
                        value={classifyId}
                        onChange={(event) => handleChangeClassifyId(event.target.value)}
                        disabled={(itemsList.filter(item => item.isCheck)).length !== 0}
                      >
                        {stateList.isLoading ? <LoadingAnime /> :
                          classifyList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                          ))}
                      </MuiSelect>
                    </div>

                    <div className={scss.select_each_block}>
                      <Text className={css.text_select}>{texts.select.source}</Text>
                      <MuiSelect id="standard-select-source"
                        className={scss.select_source}
                        value={sourceId}
                        onChange={(event) => handleChangeSourceId(event.target.value)}
                        disabled={(itemsList.filter(item => item.isCheck)).length !== 0}
                      >
                        {sourceList.map((option) => (
                          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        ))}
                      </MuiSelect>
                    </div>

                    <div className={scss.select_each_block}>
                      <Text className={css.text_select}>{texts.select.protein}</Text>
                      {<MuiSelect
                        id="standard-select-protein"
                        className={scss.select_protein}
                        value={proteinId}
                        onChange={(event) => handleChangeProteinId(event.target.value)}
                        disabled={(itemsList.filter(item => item.isCheck)).length !== 0}
                        MenuProps={{
                          PaperProps: {
                            onScroll: handleScroll,
                          }
                        }}
                      >
                        {proteinList.map((item, index) => {
                          let protein_name = item[jsonsHot.items[0]]
                          let SPP_ID = item[jsonsHot.items[1]]
                          return <MenuItem key={index} value={SPP_ID}>
                            {protein_name.length > 100 ? <Tooltip title={protein_name}>
                              {protein_name}
                            </Tooltip> : protein_name}
                          </MenuItem>
                        })}
                      </MuiSelect>}
                    </div>
                  </div>
                </StyledTableCell>
              </TableRow2Body>
            </TableBody>
          </MuiTableContainer >}>
        </ToolSingleBlock >

        <ToolSingleBlock stepText={texts.step[2]} title={texts.step1_1[2]} mainBlockContent={
          <div className={scss.third_textfield}>
            <StyledTextField
              style={{ borderRadius: '8px' }}
              placeholder={texts.searchHint}
              // label={texts.required}
              label={texts.searchHint}
              required
              value={keyword}
              onChange={(event) => { handleChangeSearch(event.target.value) }}
              onKeyDown={handleKeyDown}
              // inputProps={{ style: {borderColor: '#DFDFDF' } }}
              error={isSeqInputError}
              helperText={seqInputErrMsg}
            />
          </div>
        }>
        </ToolSingleBlock>

        <div className={scss.row_button}>
          <WhiteButton handleClick={handleClickReset} text={texts.button.reset} />
          <GreenButton handleClick={handleClickSearch} text={texts.button.search} />
        </div>
      </Box >
    </LayoutPage >
  )
}
export default ToolHotSpotSearch
import React from 'react'

import scss from './Cta.module.scss';
// import NoneButton from 'components/button/NoneButton';
import Text from 'components/tag/Text';

//---------------------------------------------------------------------------
const texts = {
  get: 'Get any questions? We are here to help.',
  contact: 'Contact us',
  email: 'cs@amway.com',
}

//---------------------------------------------------------------------------
const Cta = () => {
  return (
    <div className={scss.layout}>
      <div className={scss.frame}>
        <div className={scss.frame_text}>
          <Text className='h3-darkGreen'>{texts.get}</Text>
        </div>
        <div className={scss.frame_button}>
          <a href={`mailto:${texts.email}`}
            className={scss.button_contact}>
            {texts.contact}</a>
        </div>
      </div>
    </div>);
};

export default Cta;

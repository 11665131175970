import { Tabs } from "@mui/material";

import scss from 'components/mui/SinglePage.module.scss';

//---------------------------------------------------------------------------
const StyledTabs = (props) => (
  <Tabs
    indicatorColor={''} //line color of under tab text
    orientation="vertical"
    className={scss.frame_left}
    // TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    {...props} />
)

export default StyledTabs;
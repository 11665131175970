

//---------------------------------------------------------------------------
export const A = (props) => {
  return (
    <a target="_blank" rel="noopener noreferrer" {...props}>
      {props.children}
    </a>
  )
}

import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { useEffect, useRef, useState } from 'react';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';

import v from "assets/scss/_variables.scss";
import { breadData } from 'components/bread/BreadBar';
import chartProp from 'components/chart/chartProp';
import LayoutPage from 'layouts/LayoutPage';
import scss from "./PeptideList.module.scss";
// import BarChart from 'components/BarChart';
import LoadingAnime from 'components/LoadingAnime';
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import AmDonutChartV4 from "components/chart/AmDonutChart_v4";
import MediumStyledTableCell from 'components/table/DenseStyledTableCell';
import { TagText } from 'components/tag/Text';
import { hoverListItem, updatePeptideListHoverStatus } from 'store/mainSlice';
import cookie from 'utils/cookie';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';


//---------------------------------------------------------------------------
// path: paths.site.peptides,
const texts = {
  bread: breadData.create('Peptide', 'Bioactive Peptide Classification List'),

  table: {
    name: 'Peptide Classification List',
    count: 'Counts',
  },
  title: {
    number: '#',
    name: 'Peptide Classification List',
    counts: 'Counts',
    percentage: '%',
    pieChart: 'Pie Chart',
    download: 'Download'
  },
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  /* [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  }, */
}));

Chart.register(CategoryScale);
Chart.defaults.font.size = 12;
//---------------------------------------------------------------------------
const PeptideList = ({ setInfo }) => {
  // console.log('Peptides', texts.bread);

  const [data, setData] = useState([{
    classification: '',
    counts: 0
  }])
  function compareProperty(a, b) { //for object
    return a.classification.localeCompare(b.classification)
  }

  const [clsTotal, setClsTotal] = useState(0);
  const [peptideTotal, setPeptideTotal] = useState(0);
  const [recordTotal, setRecordTotal] = useState(0);
  const [multiTotal, setMultiTotal] = useState(0);

  const words = {
    caption: [
      // SmartPP currently contains 4000 food-derived bioactive peptides (6289 report entries),
      // which can be divided into 31 categories according to their biological functions.
      `SmartPP currently contains ${peptideTotal} food-derived bioactive peptides (${recordTotal} report entries), \
      which can be divided into ${data.length} categories according to their biological functions.`,
      // Each peptide is characterized by 30 attributes in SmartPP,
      `Each peptide is characterized by ${clsTotal - 1} attributes in SmartPP, \
      including physicochemical properties, functional attributes, enzymatic stability, toxicity, \
      source, preparation methods, and so on.`,
      // Out of all peptides stored in SPP, 763 peptides have two or more activities (Multifunctional bioactive peptides).
      `Out of all peptides stored in SmartPP, ${multiTotal} peptides have two or more activities (Multifunctional bioactive peptides).`
    ],
    peptides: {
      title: "The classification of food-derived bioactive peptides",
      subtitle: `Classification: ${clsTotal}, Peptides: ${peptideTotal}, Records: ${recordTotal}`,
    }
  }

  const chartData = chartProp.data.carate(
    'The classification of food-derived bioactive peptides',
    // Classifications: 31, Peptides: 4000, Records: 6289
    `Classifications: ${clsTotal}, Peptides: ${peptideTotal}, Records: ${recordTotal}`,
    'Records',
    data.map((data) => data.classification),
    data.map((data) => data.counts),
  )

  const ContentData = () => {
    const sumList = [{
      "title": "Classifications #",
      "value": clsTotal
    },
    {
      "title": "Peptides",
      "value": peptideTotal
    },
    {
      "title": "Counts",
      "value": recordTotal
    }]
    return (
      <div className={scss.summary}>
        {sumList.map((item, index) =>
        (<span key={index}>
          {item.title}:<span className={scss.high_light_text}>{item.value}</span>
        </span>
        )
        )}
      </div>
    )
  }

  // const chartOptions = chartProp.options.axisTitleXY('Year', 'Lost')
  const selectedPeptideCls = useSelector(state => state.main.selectedPeptideCls)
  function getPath(classify) {
    // return (`${paths.spp.peptide.classify_search}?cls=${peptideClass[classify]}`)
    return (paths.spp.peptide.class_search(classify))
  }

  const [hoverIndex, setHoverIndex] = useState(null);
  
  const [state, setState] = useState(axiosState.init())

  const [donutChartData, setDonutChartData] = useState([]);


  const navigate = useNavigate()
  let location = useLocation()

  const handlePieChartData = (labels, value) => {
    let result = [];
    labels.forEach((label, index) => {
      result.push(
        { category: label, value: value[index] },
      )
    });

    setDonutChartData(resortList(result))
  }

  const resortList = (data) => {
    const totalValue = data.reduce((sum, item) => sum + item.value, 0);

    const dataWithPercentage = data.map(item => ({
      ...item,
      percentage: (item.value / totalValue * 100).toFixed(2)
    }));

    const highValues = dataWithPercentage.filter(item => parseFloat(item.percentage) > 5).sort((a, b) => b.value - a.value);
    const lowValues = dataWithPercentage.filter(item => parseFloat(item.percentage) <= 5).sort((a, b) => a.value - b.value);

    let result = [];
    const highValueInterval = Math.floor(lowValues.length / highValues.length);

    for (let i = 0, j = 0; i < lowValues.length; i++) {
      result.push(lowValues[i]);

      if ((i + 1) % highValueInterval === 0 && j < highValues.length) {
        result.push(highValues[j]);
        j++;
      }
    }

    if (highValues.length > 0) {
      result.push(...highValues.slice(result.length - lowValues.length));
    }

    return result
  }


  const apiList = () => {
    const config = apiConfig.peptide.list()
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setState(axiosState.resultCode200())
        const data = result.data;
        setClsTotal(data.classification_total)
        setPeptideTotal(data.peptides_total)
        setRecordTotal(data.records_total)

        let peptideItems = result.data[spp.common.output.items]


        const multiPeptide = peptideItems.filter(item => item["classification_col"] === "multifunctionalpeptides")
        setMultiTotal(multiPeptide[0].counts)

        const labels = peptideItems.map(peptide => peptide.classification)
        const value = peptideItems.map(peptide => peptide.counts)
        setPeptideLabels(labels)
        setPeptideValue(value)
        handlePieChartData(labels, value)

        peptideItems.sort(compareProperty)
        setData(peptideItems)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  const [peptideLabels, setPeptideLabels] = useState([])
  const [peptideValue, setPeptideValue] = useState([])

  useEffect(() => {
    if (axiosState.keepRest(state))
      apiList()
  }, [])


  const rowRefs = useRef({});
  const dispatch = useDispatch()

  const rowChildren = (allData, item, index, navigate, selectedPeptideCls, setHoverIndex, getPath) => {

    const total = allData.reduce((sum, item) => sum + item.counts, 0)
    const percentage = ((item.counts / total) * 100).toFixed(2)
    const toolTipContent = `${item.classification}: ${percentage}%`

    return (
      // <Tooltip title={toolTipContent} arrow>
      <TableRow
        id={item.classification}
        key={item.classification}
        ref={(el) => rowRefs.current[item.classification] = el}
        onMouseOver={() => {
          dispatch(hoverListItem({ value: item.classification }))
          dispatch(updatePeptideListHoverStatus({ value: true }))
        }}
        onMouseOut={() => {
          dispatch(updatePeptideListHoverStatus({ value: false }))
        }}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: selectedPeptideCls === item.classification ? v.grey25 : "", '&:hover': {
            bgcolor: v.grey25,
            cursor: "pointer"
          }
        }}
        className={scss.table_row}
        onMouseEnter={() => setHoverIndex(index)}
        onMouseLeave={() => setHoverIndex(null)}
        onClick={() => {
          cookie.setCookie(cookie.keys.peptide.selectedPeptideClsId, item.classification_id)
          cookie.setCookie(cookie.keys.peptide.selectedPeptideClsName, item.classification)
          cookie.setCookie(cookie.keys.peptide.selectedPeptideClsTableName, item.classification_col)

          if (item.classification === 'Multifunctional peptides') {
            navigate(paths.spp.peptide.multi_search())
          } else {
            navigate(getPath(item.classification_col))
          }
        }}
      >
        {/* <StyledTableCell className={scss.row_data_number}> */}
        <MediumStyledTableCell component="th" scope="row" align="right" className={scss.text_header_number}>
          {index + 1}
        </MediumStyledTableCell>
        {/* <StyledTableCell className={scss.row_data_name}> */}
        <MediumStyledTableCell className={scss.text_header_name}>
          {item.classification}
        </MediumStyledTableCell>
        <MediumStyledTableCell align="right" className={scss.row_data_counts}>
          {item.counts}
        </MediumStyledTableCell>
      </TableRow>
      // </Tooltip>
    )
  }

  const outerRef = useRef();
  const peptideBlockRef = useRef();

  const [isMouseInsidePeptide, setIsMouseInsidePeptide] = useState(false);


  useEffect(() => {
    const checkMousePosition = (e) => {
      if (peptideBlockRef.current.contains(e.target)) {
        setIsMouseInsidePeptide(true);
      } else {
        setIsMouseInsidePeptide(false);
        document.body.style.overflow = 'auto';
      }
    };

    const handlePeptideBlockScroll = (e) => {
      const rect = peptideBlockRef.current.getBoundingClientRect();

      if (rect.top <= 5 && rect.top >= -5 && isMouseInsidePeptide) {
        document.body.style.overflow = 'hidden';
      }

    };

    window.addEventListener("mousemove", checkMousePosition);
    window.addEventListener('scroll', handlePeptideBlockScroll);


    return () => {
      window.removeEventListener("mousemove", checkMousePosition);
      window.removeEventListener('scroll', handlePeptideBlockScroll);

    };
  }, [isMouseInsidePeptide]);


  return (
    <LayoutPage bread={texts.bread}>
      <div className={scss.layout} ref={outerRef}>
        <div className={scss.frame_top} ref={peptideBlockRef}
        >
          <div className={scss.top_table}>
            <div className={scss.table_left}>
              <div className={scss.table_header_left}>
                <div className={scss.header_title}>
                  <div className={scss.header_left}>
                    <div className={scss.text_header_number}>{texts.title.number}</div>
                    <div className={scss.text_header_name}>{texts.title.name}</div>
                    <div className={scss.text_header_counts}>{texts.title.counts}</div>
                  </div>
                </div>
              </div>
              <div className={scss.table_row_left}>
                <div style={{ paddingTop: "128px" }} />
                <div className={scss.top_row}>
                  <TableContainer component={Paper} variant="outlined" square>
                    <Table stickyHeader aria-label="a dense table">
                      <TableBody>
                        {state.isLoading ? <LoadingAnime /> :
                          data.map((item, index) => (
                            rowChildren(data, item, index, navigate, selectedPeptideCls, setHoverIndex, getPath)
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>

            <div className={scss.table_right}>
              <div className={scss.table_header_right}>
                <div className={scss.header_title}>
                  <div className={scss.header_right}>
                    <div className={scss.text_header} style={{ flex: '80%' }}>{texts.title.pieChart}</div>
                  </div>
                </div>
              </div>
              <div className={scss.row_right}>
                {/* <DoughnutChart data={chartData} hoverIndex={hoverIndex}></DoughnutChart> */}
                {/* <AmPieChart data={donutChartData}/> */}
                {/* <PeptideAmPieChart labels={peptideLabels} value={peptideValue} innerRadius={70} /> */}

                {/* <AmDonutChartV5 data={donutChartData} rowRefs={rowRefs} /> */}
                <AmDonutChartV4 data={donutChartData} rowRefs={rowRefs} />
              </div>
            </div>
          </div>

          <div className={scss.top_footer}><ContentData /></div>
        </div >

        <div className={scss.frame_bottom}>
          {words.caption.map((text, index) => (
            <div key={index} style={{ flex: "1", padding: "0px 20px" }}
              className={index + 1 !== words.caption.length ? scss.caption_divider : ""}>
              <TagText html={text} />
            </div>))}
        </div>
        
      </div >
    </LayoutPage >
  )
}

export default PeptideList;
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

import v from 'assets/scss/_variables.scss';
import scss from 'components/mui/SinglePage.module.scss';

//---------------------------------------------------------------------------
const StyledTab = styled((props) =>
  <Tab disableRipple
    // orientation="vertical"
    sx={{ textTransform: "none", color: v.darkGreen, display: "flex", alignItems: "flex-start", textAlign: "left" }}
    {...props} />)(
      ({ theme }) => ({
        '&.Mui-selected': {
          borderRadius: scss.selectBorderRadius,
          backgroundColor: scss.selectBg,
          color: v.darkGreen,
        },
      }),
    );


export default StyledTab

import React from 'react'
// import { useState } from "react";

import scss from './Purpose.module.scss';
import imgs from 'utils/imgs';
import Text from 'components/tag/Text';
import RefImg from 'components/tag/Img';

//---------------------------------------------------------------------------
function purposeData(index, heading, text) {
  return { index, heading, text };
}

const texts = {
  purpose: 'Purpose of the Database',
  what: 'What is this database?',

  head: [
    purposeData('01', 'Data Retrieval',
      'A comprehensive database for foodborne protein & peptide.'),
    purposeData('02', 'Discovery \ndrug design & functional Food',
      'An integrated database of sequence, function, source, prediction, and other information.'),
    purposeData('03', 'Modeling Analysis',
      'A versatile database to look up nutritional and functional values of protein & peptide, as well as to be used as target product design.'),
  ],
}

const Img = (props) => (
  <RefImg className={scss.center_image} {...props} />
)

const TextHeading = (props) => (
  <div className={scss.center_text}>
    <div className={scss.text_frame}>
      {/* <div className={scss.frame_heading}>
        <Text className='b1-darkGreen'>{props.item ? props.item.index : ''}</Text>
        <Text className='h2-darkGreen'>{props.item ? props.item.heading : ''}</Text>
      </div> */}
      <Text className='size20strong-grey100'>{props.item ? props.item.text : ''}</Text>
    </div>
  </div>
)

//---------------------------------------------------------------------------
const Purpose = () => {
  return (
    <div className={scss.layout}>
      <div className={scss.frame_top}>
        <div className={scss.top_purpose}>
          <Text className='b1-darkGreen'>{texts.purpose}</Text>
        </div>
        <div className={scss.top_what}>
          <Text className='h1-darkGreen'>{texts.what}</Text>
        </div>
      </div>
      <div className={scss.frame_center1}>
        <Img imgName={imgs.site.home.rectangle1}></Img>
        <TextHeading item={texts.head[0]}></TextHeading>
      </div>
      <div className={scss.frame_center2}>
        <TextHeading item={texts.head[1]}></TextHeading>
        <Img imgName={imgs.site.home.rectangle2}></Img>
      </div>
      <div className={scss.frame_center3}>
        <Img imgName={imgs.site.home.rectangle3}></Img>
        <TextHeading item={texts.head[2]}></TextHeading>
      </div>
    </div>
  );
};

export default Purpose;

import styled from 'styled-components';
import {IconButton} from '@mui/material';

//---------------------------------------------------------------------------
const StyledIconButton = styled(IconButton)`
  &:hover {
    background-color: transparent !important;
  }
`;

export default StyledIconButton;

import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import scss from 'views/protein/ProteinList.module.scss';
import BreadCard from 'components/bread/BreadCard';
import BreadText from 'components/bread/BreadText';
import paths from 'utils/network/apiPath';
import Breadcrumb from "components/bread/Breadcrumb";

//---------------------------------------------------------------------------
const pathShowBreadText = [
  paths.spp.tool.hotspot_result(),
  paths.spp.tool.ehp_tool_enzyme_page(),
  paths.spp.tool.ehp_tool_result(),
  paths.spp.peptide.id_target_protein_page(),
  paths.spp.peptide.hotspot_result(),
  paths.spp.tool.hotspot_result_precursor(),
]
//---------------------------------------------------------------------------
export default function LayoutPage2({ children, ...props }) {
  // console.log('Layout props', props);
  // console.log('Layout', props.bread);
  
  const [isShowBreadCard, setShowBreadCard] = useState(true)
  const [isShowBreadText, setShowBreadText] = useState(false)
  
  let location = useLocation();
  let pathname = location.pathname
  
  useEffect(() => {
    if (pathShowBreadText.includes(pathname)) {
      setShowBreadText(true)
      setShowBreadCard(false)
    }
  }, [location]);

  return (
    <div className={scss.layout}>
      <Breadcrumb bread={props.bread}/>
      
      {isShowBreadText
      ? <BreadText bread={props.bread} />
      : null}
      
      {isShowBreadCard
      ? <BreadCard card={props.card} output={props.output}></BreadCard>
      : null}
      
      <main>{children}</main>
    </div>
  )
}

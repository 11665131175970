import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TableCell, TableHead, TableRow } from '@mui/material';

import 'assets/scss/index.scss';
import v from 'assets/scss/_variables.scss';
import scssCommon from 'assets/scss/common.scss';
import scss from './SearchFullResultTable.module.scss'
import LoadingAnime from 'components/LoadingAnime';
import { MuiTableCell, MuiTableContainer, TableHeadRow, TableRow2Body } from 'components/table/MuiTable';
import scssSelect from 'views/protein/ProteinSelection.module.scss';
import cookie from 'utils/cookie';
import paths from 'utils/network/apiPath';
import { spp } from 'utils/network/jsons';
import { RouterLink } from 'components/router/RouterLink';
import { getKeyByValue } from 'utils/object';
import { cellMutation, cellMutationText, cellPeptide } from 'views/tool/ToolACEiPPAnOxPPManualResult';
import { useEffect } from 'react';

//---------------------------------------------------------------------------
let texts = {
  menus: [
    'Show up to',
  ],
  aceipp_anoxpp_mm: {
    title: 'Peptide Mutation',
    head_key: 'head',
    head: [
      'Amino acids',
      'Site',
    ],
  },
}

const XYTableCell = (props) => {
  const {children, className, ...other} = props
  let cls = scss.table_body_cell
  if( className !== undefined )
    cls += ` ${className}`
  return (
    <TableCell align="center" size="small" className={cls} {...other}>
      {children}
    </TableCell>)
}

const XYTableCell1st = (props) => {
  const {children, ...other} = props
  return (
    <XYTableCell align="right" component="th" scope="row" className={scss.table_body_cell_1st} {...other}>
      {children}
    </XYTableCell>)
}

//---------------------------------------------------------------------------
const SearchFullResultTable = (props) => {
  const { tableBodyCell, inputProp } = props
  const isCount = (tableBodyCell === spp.protein.search.fragment_count.output)
    || (tableBodyCell === spp.protein.search.classify_count.output)
  const isPeptideList = (tableBodyCell === spp.protein.search.peptide_list.output)
  const isACEiPPAnOxPPMutation = (tableBodyCell === spp.tool.aceipp_anoxpp_manual_mutation.items)
  
  // console.log('inputProp', inputProp);
  let dataset, datasetName = '', datasetNameNon = ''
  let keyword = '', arrKeyword, site
  if(isACEiPPAnOxPPMutation) {
    dataset = inputProp[0]
    datasetName = getKeyByValue(spp.tool.aceipp_anoxpp_manual_mutation.dataset, dataset)
    datasetNameNon = 'non-' + datasetName
    
    keyword = inputProp[1]
    let arr = keyword.split('')
    arrKeyword = [texts.aceipp_anoxpp_mm.head_key].concat(arr)
    if(!arrKeyword)
      arrKeyword = []
    
    site = inputProp[2] ? inputProp[2] : 1
    // console.log('dataset', dataset);
    // console.log('keyword=', keyword, 'arrKeyword=', arrKeyword);
    // console.log('site=', site);
  }
  
  const itemsPerPageList = [10, 50, 100,]
  itemsPerPageList.forEach((number) => (texts.menus.push(`${number} items`)))
  // console.log(texts.menus);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      props.handleClickSearch();
    }
  }
  
  //------------------------------------------------------------------------------
  const navigate = useNavigate()
  const TableBodyCell = (props) => {
    // console.log('props', props);
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('children', props.children);
    const { tableBodyCell, children, inputProp, index } = props
    const isPeptideList = (tableBodyCell === spp.protein.search.peptide_list.output)

    const [queryParameters] = useSearchParams()
    let peptideClass = queryParameters.get(paths.params.class)

    let link = '', key = '', array = []
    const generateSppIdList = (index, peptideClass) => {
      // console.log('tableBodyCell[index]', tableBodyCell[index]);
      // console.log(children[tableBodyCell[index]]);
      key = tableBodyCell[index]
      if( key !== '' )
        array = children[key]
      if(peptideClass !== 'multifunctionalpeptides'){
        if( array !== undefined && array !== null )
        return (<>
          {array.map((element, index) => <div key={index}>
            <RouterLink newTab to={paths.spp.peptide.id_result(element, peptideClass)}>
              {element}
            </RouterLink></div>)}
        </>)
      }else{
        if( array !== undefined && array !== null )
        return (<>
          {array.map((element, index) => <div key={index}>
            <RouterLink newTab to={paths.spp.peptide.id_multi_result(element, peptideClass)}>
              {element}
            </RouterLink></div>)}
        </>)
      }

    }

    if(isPeptideList) {
      // link = paths.spp.peptide.hotspot_result()
      // props.inputProp[1]
      const key0 = tableBodyCell[0]
      const key3 = tableBodyCell[3] //Report entries (SPP ID)
      const val3 = children[key3] || []
      const isNullValue3 = (val3 === null)
      const key4 = tableBodyCell[4] //Other entries (SPP ID)
      const val4 = children[key4] || []
      const allPeptideId = [...val3,...val4]
      // console.log('key0=', key0, 'key3=', key3, 'val3=', val3, 'key4=', key4, 'val4=', val4);
      
      let peptideID = val3[0]
      if( peptideID === undefined )
        peptideID = val4[0]

      return (
        tableBodyCell.map((cell, index) => {
          // const peptideClass = cookie.getCookie(cookie.keys.peptide.selectedPeptideClsTableName)
          return <MuiTableCell key={index}>
            {(index === 5) && !isNullValue3 //HotSpot Search
              ? <RouterLink newTab to={paths.spp.peptide.hotspot_result(props.inputProp[0],
                  peptideID, 
                  children[key0], 
                  allPeptideId
                  )}>{`GO >>`}</RouterLink>
              : (index === 4) ?
                generateSppIdList(4, peptideClass)
                : (index === 3)
                  ? generateSppIdList(3, peptideClass)
                  : <div dangerouslySetInnerHTML={{ __html: children[cell] }} />}
          </MuiTableCell>
        }))
    }
    if(isACEiPPAnOxPPMutation) {
      return (
        tableBodyCell.map((cell, indexX) => {
          let value = children[cell]
          let text = value
          if(cell === "pre_activity") {
            text = datasetNameNon
            if( value )
              text = datasetName
            return (
              <MuiTableCell key={indexX} className={getCellClassName(index)} align={getCellAlign(cell, indexX)}>
                {text}
              </MuiTableCell>)
          } else if(cell === "mutation" || cell === "peptide") {
            let value = children["sequence"]
            if(index === 0)
              return <MuiTableCell key={indexX} />
            return (
              <MuiTableCell key={indexX} align={getCellAlign(cell, indexX)}>
                {cell === "mutation"
                ? cellMutation(navigate, value, dataset)
                : cellPeptide(navigate, value, dataset)}
              </MuiTableCell>)
          }
          return (
            <MuiTableCell key={indexX} className={getCellClassName(index)} align={getCellAlign(cell, indexX)}>
              {text}
            </MuiTableCell>)
      }))
    }
  }
  
  function getCellClassName(index) {
    if(isACEiPPAnOxPPMutation && index === 0)
      return 'color-red'
    return null
  }
  function getCellAlign(cell, index) {
    if(isACEiPPAnOxPPMutation) {
      if( cell==='Pre-Activity' || cell==="pre_activity"
        || cell==="pi" || cell==='pI')
        return 'right'
      if(cell==='Mutation' || cell==="mutation")
        return 'center'
      if(cell==='Peptide' || cell==="peptide")
        return 'center'
    }
    return 'left'
  }

  let tableHeadCell = []
  if (props.tableHeadCell === spp.protein.search.fragment_count.output_text
    || props.tableHeadCell === spp.protein.search.classify_count.output_text) {
    let head = props.items[props.info[0]]
    // console.log(head)
    let head1D = getArray1D(head)
    // console.log(head1D)
    let head1D4 = head1D.slice(4)
    let headFix = props.tableHeadCell.slice(0, 4 + 1)
    headFix = headFix.concat(head1D4)
    let index = headFix.indexOf('Database_reference')
    let length = props.tableHeadCell.length
    if (index !== -1)
      headFix[index] = props.tableHeadCell[length - 1] //'UniProtKB'

    tableHeadCell = headFix
    // console.log(headFix)
  } else {
    tableHeadCell = props.tableHeadCell
  }
  // console.log('tableHeadCell', tableHeadCell)

  let items = []
  if (props.tableBodyCell === spp.protein.search.fragment_count.output
    || props.tableBodyCell === spp.protein.search.classify_count.output) {
    let body = props.items[props.info[1]]
    // console.log(body)
    if (body !== undefined) {
      let bodyFix = []
      if (Array.isArray(body)) {
        body.forEach((item) => {
          // console.log('item', item)
          if (Array.isArray(item)) {
            let bodyFix2 = []
            item.forEach((item2) => {
              // console.log('item2', item2)
              if (Array.isArray(item2)) {
                let ary = item2.flat()
                bodyFix2 = bodyFix2.concat(ary)
                // console.log(ary)
              } else
                bodyFix2.push(item2)
            })
            bodyFix.push(bodyFix2)
          } else
            bodyFix.push(item)
        })
      }
      // console.log(bodyFix)
      items = bodyFix
    } else {
      items = []
    }
  } else {
    items = props.items
  }
  // console.log('items', items)
  function getArray1D(ary) {
    let ary1d = []
    if (ary !== undefined) {
      ary.forEach((item) => {
        if (Array.isArray(item))
          return (item.forEach((i) => (ary1d.push(i[0]))))
        else
          return ary1d.push(item)
      })
    }
    return ary1d
  }
  
  //------------------------------------------------------------------------------
  const xyTableRowCell4Body = (arr) => {
    let text, value = keyword
    return (
      <>
        <TableRow>
          {arr.map((item, index) => { //'Amino acids' row
            if( item === texts.aceipp_anoxpp_mm.head_key ) {
            text = texts.aceipp_anoxpp_mm.head[0]
            return (
              <XYTableCell1st key={index}>{text}</XYTableCell1st>) //'Amino acids'
          } else {
            text = item
            return (
              <XYTableCell key={index}>{text}</XYTableCell>)
          }})}
        </TableRow>
        <TableRow>
        {arr.map((item, index) => { //'Site' row
          if( item === texts.aceipp_anoxpp_mm.head_key ) {
            text = texts.aceipp_anoxpp_mm.head[1]
            return (
              <XYTableCell1st key={index}>{text}</XYTableCell1st>) //'Site'
          } else {
            text = index
            return (
              <XYTableCell key={index}>
                {Number(text) !== Number(site)
                ? cellMutationText(navigate, value, dataset, text)
                : text}
                </XYTableCell>)
          }})}
      </TableRow>
    </>)
  }
  
  //------------------------------------------------------------------------------
  return (
    <>
        { isACEiPPAnOxPPMutation
        ? <div className={scss.frame_muation}>
            <h3>{texts.aceipp_anoxpp_mm.title}</h3>
            <div className={scss.muation_table}>
              <MuiTableContainer>
                <Table aria-label="table">
                  <TableBody>
                    {xyTableRowCell4Body(arrKeyword)}
                  </TableBody>
                </Table>
              </MuiTableContainer>
            </div>
          </div>
        : <div className={scssSelect.frame_search}>
            <h4 className={scssSelect["color-white"]}>{props.texts.searchHint}</h4>
          </div>
        }

      <div className={scssSelect['frame_table']}>
        <TableContainer component={Paper} variant="outlined" className={scssCommon.table_container}>
          {props.isLoading ? <LoadingAnime />
          : props.items.length === 0
            ? <Table sx={{ display: "flex", justifyContent: "center", pt: 2, pb: 2 }}>No matching data found</Table>
            : <Table aria-label="a dense table">
                <TableHeadRow className="bgc-dark-green">
                  {tableHeadCell.map((cell, index) => (
                    <MuiTableCell key={index} align={getCellAlign(cell, index)}>
                      {cell}</MuiTableCell>
                  ))}
                </TableHeadRow>
                <TableBody>
                  {items.map((item, index) => (
                    <TableRow2Body key={index}>
                      <MuiTableCell component="th" scope="row">{index + props.itemNo.start}</MuiTableCell>
                      <TableBodyCell tableBodyCell={props.tableBodyCell} inputProp={props.inputProp} index={index}>
                        {item}</TableBodyCell>
                    </TableRow2Body>
                  ))}
                </TableBody>
              </Table>}
        </TableContainer>
      </div>
    </>
  )
}
export default SearchFullResultTable
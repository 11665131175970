
//initial
const inits = {
  ItemNum: 10,
}

class itemNoData {
  static init() {
    return {
      start: 1,
      end: inits.ItemNum,
    }
  }
  static create(start, end) {
    return { start, end }
  }
}

class pageNoData {
  static init() {
    return {
      now: 1,
      last: 1,
    }
  }
  static create(now, last) {
    return { now, last }
  }
}

export { inits, itemNoData, pageNoData }

import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { snackInfo, snackType } from "components/SnackBar";
import scss from './BreadResult.module.scss';
import paths from 'utils/network/apiPath';
import { spp } from 'utils/network/jsons';
import NoneButton from 'components/button/NoneButton';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import cookie from 'utils/cookie';
import Text from "components/tag/Text";
import { navigateNewTab } from "utils/general";

//---------------------------------------------------------------------------
const texts = {
  protein: [
    'Fragment Count', 'Frag-Graph', 'Classification Count', 'Class-Graph',
  ],
  peptide: [
    'PathWay Map', 'Fun-Relationships', 'Statistical Data', 'Advanced Search',
  ],
  peptide_multi: [
    'Multi-Cross', 'Fun-Relationships', 'Statistical Data', 'Advanced Search',
  ],
  peptide_quick: [
    'Advanced Search',
  ],
  result: 'Results',
}

//---------------------------------------------------------------------------
const BreadResult = (props) => {
  const isProteinSearch = props.isProteinSearch
  const isPeptideMultiSearch = props.isPeptideMultiSearch
  const isPeptideQuickSearch = props.isPeptideQuickSearch
  const jsonInput = props.jsonInput
  let id = props.searchInput[0]
  // console.log('BreadResult props', props);
  // console.log('id', id);

  // const isProtein = (jsonInput === spp.protein.search.classify.input)
  //   || (jsonInput === spp.protein.search.source.input)
  const isCount = (jsonInput === spp.protein.search.fragment_count.input)
    || (jsonInput === spp.protein.search.classify_count.input)

  if (isCount && id === 0)
    id = props.searchInput[1] //[class_code, source_code]
  
  //---------------------------------------------------------------------------
  // button
  const ButtonRow = (props) => {
    if( isProteinSearch ) {
      return (
        texts.protein.map((item, index) => (
          <LabelButton key={index} index={index}>{item}</LabelButton>)) 
    )}
    if( isPeptideMultiSearch ) {
      return (
        texts.peptide_multi.map((item, index) => (
          <LabelButton key={index} index={index}>{item}</LabelButton>))
    )}
    if( isPeptideQuickSearch ) {
      return (
        texts.peptide_quick.map((item, index) => (
          <LabelButton key={index} index={index}>{item}</LabelButton>))
    )}
    return (
      texts.peptide.map((item, index) => (
        <LabelButton key={index} index={index}>{item}</LabelButton>))
    )
  }
  
  const LabelButton = (props) => {
    return (
      <NoneButton
        className={scss.button}
        onClick={() => { handleClick(props.index, props.typeName) }}
        {...props}
      >
        <Text className={'b2-darkGreen'}>{props.children}</Text>
      </NoneButton>)
  }
  //------------------------------------------------------------------------------
  const handleClick = (index, typeName) => {
    if( isProteinSearch )
      clickProtein(index)
    else if( isPeptideMultiSearch )
      clickPeptide_multi(index) 
    else if( isPeptideQuickSearch )
      clickPeptide_quick(index)
    else
      clickPeptide(index)
  }
  function clickProtein(index) {
    switch (index) {
      case 0:
        //'Fragment Count'
        navigateNewTab(paths.spp.protein.fragment_count(id))
        break;
      case 1:
        // Frag-Graph
        navigateNewTab(paths.spp.protein.frag_graph())
        break;
      case 2:
        //'Classification Count'
        navigateNewTab(paths.spp.protein.class_count(id))
        break
      case 3:
        // Class-Graph
        navigateNewTab(paths.spp.protein.class_graph())
        break;
      default:
        break;}
  }
  function clickPeptide_multi(index) {
    switch (index) {
      case 0:
        // Multi Cross
        navigateNewTab(paths.spp.tool.multi_cross_analysis())
        break;
      case 1:
        // Fun-Relationships
        navigateNewTab(paths.spp.peptide.fun_relationships())
        break;
      case 2:
        // Statistical Data
        navigateNewTab(paths.spp.peptide.statistical_data())
        break;
      case 3:
        // Advanced Search
        navigateNewTab(paths.spp.peptide.advanced_search())
        break;
      default:
        break;}
  }
  function clickPeptide_quick(index) {
    switch (index) {
      case 0:
        // Advanced Search
        navigateNewTab(paths.spp.peptide.advanced_search())
        break;
      default:
        break;}
  }
  const [info, setInfo] = useState(snackInfo.init())
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate();
  let location = useLocation()
  function clickPeptide(index) {
    switch (index) {
      case 0:
        //Pathway map
        const selectedName = cookie.getCookie(cookie.keys.peptide.selectedPeptideClsName)
        const input = {
          "display_name": selectedName
        }

        const getPathwayMap = () => {
          const config = apiConfig.peptide.pathway(input)
          //console.log(config);
          axios(config).then(result => {
            if (result.data.result_code !== 200) {
              setState(axiosState.error(false, state.numResultError + 1))
              setInfo(snackInfo.open(snackType.error, result.data.message))
            } else {
              setState(axiosState.resultCode200())
              // console.log(result.data);
          
              window.open( result.data.link )
            }
          }).catch(err => {
            setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
            setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
            if (axiosCatch.needLogin(err))
              cookie.removeCookieAndJump(navigate, location)
          })
        }
        getPathwayMap();
        break;
      case 1:
        // Fun-Relationships
        navigateNewTab(paths.spp.peptide.fun_relationships())
        break;
      case 2:
        // Statistical Data
        navigateNewTab(paths.spp.peptide.statistical_data())
        break;
      case 3:
        // Advanced Search
        navigateNewTab(paths.spp.peptide.advanced_search())
        break;
      default:
        break;}
  }
  
  //---------------------------------------------------------------------------
  return (
    <div className={scss.top_result} >
      <div className={scss.result_buttons}>
        <ButtonRow />
      </div>

      <div className={scss.result_line}></div>

      <div className={scss.result_texts}>
        <Text className='b1strong-darkGreen'>{props.searchOutput.total}</Text>
        <Text className='b1-grey100'>{texts.result}</Text>
      </div>
    </div>
  )
}
export default BreadResult
import { Button } from "@mui/material";

import scss from "./Buttons.module.scss";

//---------------------------------------------------------------------------
const GreenButton = (props) => {
  return <Button
    className={scss.green_button}
    variant="contained"
    size="small"
    onClick={props.handleClick}
    sx={{
      boxShadow: "none",
      textTransform: "none",
    }}
  >{props.text}</Button>
}

export default GreenButton;
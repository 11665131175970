import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { breadData } from 'components/bread/BreadBar';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import SearchFullResultLayout from 'layouts/search/SearchFullResultLayout';
import cookie from 'utils/cookie';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import paths from 'utils/network/apiPath';
import { getKeyByValue } from 'utils/object';
import SearchResultLayout from 'layouts/search/SearchResultLayout';
import ToolResultLayout from 'layouts/search/ToolResultLayout';

//---------------------------------------------------------------------------
// path: paths.spp.tool.aceipp_anoxpp_mp
let tool_names = [
  'ACEiPP',
  'AnOxPP',
]

const jsons = spp.tool.hot_spot_result_chart //42 multi_hotspot
//------------------------------------------------------------------------------
const ToolACEiPPAnOxPPManualPeptide = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const datasetStr = searchParams.get(paths.params.dataset)
  const sequence = searchParams.get(paths.params.sequence)
  
  const jsonsMR = spp.tool.aceipp_anoxpp_manual_result
  const dataset = Number(datasetStr)
  const datasetName = getKeyByValue(jsonsMR.dataset, dataset)
  // console.log('dataset', dataset);
  // console.log(datasetName);
  const toolIndex = dataset - 1
  const toolName = (toolIndex >= 0 && toolIndex < tool_names.length) ? tool_names[toolIndex] : ''
  
  let texts = {
    bread: breadData.create('Tools', `${toolName}-1.0 Search ${sequence} sequence`, `${toolName} Pre-${datasetName}`,
      `Manual Result`, 'Peptides'),
    
    tableHeadCell: jsons.items_adv_text,
    tableBodyCell: jsons.items_adv,
  }

  return (
    <>
      <ToolResultLayout
        setInfo={setInfo}
        texts={texts}
        configSearch={apiConfig.tool.hotspot_result_chart}
        jsonInput={jsons.input_adv}
        inputProp={[sequence, [], true]} //sequence, sourceId, is_search_all
        jsonInputKeyword={jsons.input_adv_keyword}
        jsonItems={jsons.output[3]} //"Count result",
      />
    </>
  )
}
export default ToolACEiPPAnOxPPManualPeptide
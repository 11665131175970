

export function handleScroll(ref, setTabValue) {
  // console.log('ref', ref)
  if( ref !== undefined && Object.isObject(ref)
    && ref.current !== undefined && Array.isArray(ref.current) ) {
    // console.log('>handleScroll');
    ref.current.forEach((refElement, index) => {
      if (refElement) {
        // let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
        // let scrollObj = refElement
        // let scrollTop = scrollObj.scrollTop
        // let scrollHeight = scrollObj.scrollHeight
        // let isBottom = (scrollTop + clientHeight) === scrollHeight
        
        const rect = refElement.getBoundingClientRect();
        if (rect.top <= 0 && rect.bottom > 0)
          // console.log(index, 'top=', parseInt(rect.top), 'bottom=', parseInt(rect.bottom));
          setTabValue(index)
      }
  })}
}
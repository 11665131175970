// import { useState } from "react"
import { Snackbar, Alert } from "@mui/material"

//---------------------------------------------------------------------------
export const snackType = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  success: 'success',
}

export const emptyResult = "No matching data found";

export class snackInfo {
  static create(isOpen, type, msg) {
    return { isOpen, type, msg }
  }
  static open(type, msg) {
    return {
      isOpen: true,
      type: type,
      msg: msg,
    }
  }
  static close(type, msg) {
    return {
      isOpen: false,
      type: type,
      msg: msg,
    }
  }
  static init() {
    return {
      isOpen: false,
      type: snackType.error,
      msg: '',
    }
  }
}

//---------------------------------------------------------------------------
const SnackBar = (props) => {
  // console.log('info', props.info);
  const isOpen = (props.info ? props.info.isOpen : props.isOpen)
  const type = (props.info ? props.info.type : props.type)
  const msg = (props.info ? props.info.msg : props.msg)
  // console.log(isOpen);
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={props.handleClose}
    >
      <Alert
        onClose={props.handleClose}
        severity={type}
        sx={{ width: '100%' }}
        data-cy="register-snackbar">
        {msg}
      </Alert>
    </Snackbar>
  )
}

export default SnackBar
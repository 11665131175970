import Chart from "chart.js/auto";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { breadData } from 'components/bread/BreadBar';
import SnackBar, { emptyResult, snackInfo, snackType } from 'components/SnackBar';
import chartProp from 'components/chart/chartProp';
import ListAndBarChart from 'layouts/withCharts/ListAndBarChart';
import cookie from 'utils/cookie';
import { isEmptyObjOrArray } from "utils/general";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';

//---------------------------------------------------------------------------
// path: paths.spp.peptide.fun_relationships(),
const fontSizeOfLongText = 25;
Chart.defaults.font.size = 16;

//---------------------------------------------------------------------------
const PeptideFunRelationships = ({ setInfo }) => {


  const [pagePeptideClsName, setPagePeptideClsName] = useState("")
  const [list, setList] = useState({}) //peptide_list data
  const [items, setItems] = useState([]) //items data in peptide_list
  
  const [peptideClsCount, setPeptideClsCount] = useState(30)
  const [peptideRelationshipLabels, setPeptideRelationshipLabels] = useState([])
  const [peptideRelationshipValue, setPeptideRelationshipValue] = useState([])

  const [rowData, setRowData] = useState([])

  let texts = {
    bread: breadData.create('Peptide',
      `Activity relationships between ${pagePeptideClsName} and other ${peptideClsCount} bioactive peptides`,
      '', '', '', fontSizeOfLongText),
  }

  const words = {
    chart_fun_relationships: {
      title: `Activity relationships between ${pagePeptideClsName} and other ${peptideClsCount} kind of peptides`,
      x: 'Classification',
      y: 'Peptide count',
    }
  }

  const chartFunRelationshipsOptions = chartProp.options.axisTitleXY(words.chart_fun_relationships.x, words.chart_fun_relationships.y)
  const chartFunRelationshipsData = chartProp.data.carate(
    "",
    "",
    "",
    peptideRelationshipLabels,
    peptideRelationshipValue,
  )

  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiResult = () => {
    let sendData = {
      "cls_id": Number(cookie.getCookie(cookie.keys.peptide.selectedPeptideClsId))
    }
    const config = apiConfig.peptide.fun_relationship(sendData)
    // console.log(config);
    
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        const output = result.data.items
        // console.log('items', output);

        if (isEmptyObjOrArray(output)) {
          setInfo(snackInfo.open(snackType.info, emptyResult))
          return null
        }
        
        // console.log('length', Object.keys(output).length);
        setPeptideClsCount(Object.keys(output).length)
        setPeptideRelationshipLabels(Object.keys(output).map(item => item))
        setPeptideRelationshipValue(Object.values(output).map(item => {
          return item.count
        }))
        
        // Object.entries(output).map(item => {
        //   return item
        // })

        const data = Object.entries(output).map(([displayName, { id, count, table_name }]) => ({
          displayName,
          tableName: table_name,
          id,
          value: count
        }))

        setRowData(data);
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
      console.log("error1:", err)
    })
  }

  useEffect(() => {
    setPagePeptideClsName(cookie.getCookie(cookie.keys.peptide.selectedPeptideClsName))
    
    apiResult()
    return () => {
    };
  }, []);

  return (
    <>
      <ListAndBarChart
        setInfo={setInfo}
        texts={texts}
        title={""}
        subtitle={""}
        yAxisLabel={words.chart_fun_relationships.y}
        chartFragmentData={chartFunRelationshipsData}
        chartFragmentOptions={chartFunRelationshipsOptions}
        isLoading={state.isLoading}
        rowData={rowData} />
    </>

  )
}
export default PeptideFunRelationships
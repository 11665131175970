// import { useEffect } from 'react';
// import { useState } from "react";
import { Link } from 'react-router-dom';

import scss from './BreadCard.module.scss';
import Text, { TagText } from 'components/tag/Text';
// import linkList from 'utils/linkList';
// import paths from 'utils/network/apiPath';

//---------------------------------------------------------------------------
// const texts = {
// }

export function cardData(label, text) {
  return {label, text}
}

const Line = ({ ...props }) => (
  <span className={scss.line} {...props}></span>
)

//---------------------------------------------------------------------------
export default function BreadCard({card, output, ...props}) {
  /*
  const card = [
    cardData('SPP ID', 'DEBPACEI0003'),
    cardData('Peptide Sequence', 'IHPF'),
    cardData('Type', 'Native peptide'),
    cardData('Peptide/Function Name', 'ACE-inhibitorv peptide'),
  ]*/
  
  return (
    <div className={scss.layout}>
      <div className={scss.frame}>
        {card.map((item, index) => {
          let scssDiv = scss.frame_34
          let classText = 'b1-darkGreen'
          if ( index === 0 ) {
            scssDiv = scss.frame_1
            classText = 'h2-darkGreen'
          } else if ( index === 1 ) {
            scssDiv = scss.frame_2
            classText = 'b1-red'
          }
          let text = item.text
          if(text !== undefined) {
            text = text.removeAll('<p>')
            text = text.removeAll('</p>')
          }
          return (
            <> {/* Warning: Each child in a list should have a unique "key" prop. */}
              <div key={`div-${item.text}`} className={scssDiv}>
                <Text key={`label-${item.text}`} className={'b3-grey100'}>{item.label}</Text>
                <Text key={`text-${item.text}`} className={classText}>
                  <TagText html={text} />
                </Text>
              </div>
              {index < (card.length -1) ? <Line key={`line-${item.text}`}/> : null}
            </>
          )
        })}
      </div>
    </div>
  )
}
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Grid, Input, MenuItem, Select, TableCell, TableRow } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// -----custom tools-------------------------------------------------------
import 'assets/scss/common.scss';
import 'assets/scss/temp.scss';
import v from 'assets/scss/_variables.scss';
import scss from './ToolAASD.module.scss';
import scssTools from 'views/tool/Tools.module.scss';
import { breadData } from 'components/bread/BreadBar';
import { TagText } from 'components/tag/Text';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import LayoutPage from 'layouts/LayoutPage';
import { RouterLink } from 'components/router/RouterLink';
import MuiSelect from 'components/mui/MuiSelect';

//---------------------------------------------------------------------------
// path: paths.spp.tool.aasd,
const texts = {
  bread: breadData.create('Tools', 'AASD-Tool(Amino acid structural descriptors)', 'AASD-Tool'),

  intro: 'AASD-Tool is to generate the peptide feature vectors in batches, which contains 20 structural characterization scales. By uploading txt text containing peptide sequences, you can quickly generate the feature vectors of bioactive peptides, which can be used for molecular modeling and bioinformatics prediction, etc.',
  // searchHint: 'Please enter single letter sequences',
  title: {
    fileUpload: "File upload",
    aasd: 'Descriptors',
  },
  feature: ['User name', 'Upload *.txt file', 'Please select descriptor'],

  selectDes: ['FASGAI', 'Z-scales', 'NNAAIndex', 'NNAAIndex', 'ISA-ECI', 'MS-WHIM', 'SZOTT', 'ST-scales', 'T-scales', 'VHSE', 'VSTV', 'GRID', 'DPPS', 'BLOSUM62', 'HESH', 'Lin’s scales', 'ProtFP', 'QTMS(ADFQ) indices', 'SVRG', 'SVWG', 'VSW'],
  table: {
    descriptors: ['FASGAI', ' Z-scales', 'NNAAIndex', 'ISA-ECI', 'MS-WHIM', 'SZOTT', 'ST-scales', 'T-scales', 'VHSE', 'VSTV', 'GRID', 'DPPS', 'BLOSUM62', 'HESH', 'Lin’s scales', 'ProtFP', 'QTMS(ADFQ) indices', 'SVRG', 'SVWG', 'VSW'],
    explanation: [
      'Through factor analysis, the FASGAI descriptors cluster 334 physicochemical properties of each of 20 coded amino acids into 6 factors, which are related to hydrophobicity, alpha and turn propensities, bulky properties, compositional characteristics, local flexibility and electronic properties, respectively.',
      'The 3 principal component scores are derived from PCA of a matrix of 29 physicochemical variables for 20 coded amino acid are related to hydrophilicity (z1), bulk (z2), and electronic properties (z3).',
      'The NNAAIndex scales characterize a total of 155 physiochemical properties of 22 natural and 593 non-natural amino acids, followed by clustering the structural matrix into 6 representative property patterns by factor analysis. The six factors are geometric characteristics, H-bond, connectivity, accessible surface area, integy moments index, and volume and shape, respectively.',
      'The isotropic surface area (ISA) approximates the hydrophobic character of the side chain substituent;',
      'The electronic charge index (ECI) is a measure of the charge concentration of the amino acid. Each residue was described by a combination of ISA and ECI descriptors.',
      'MS-WHIM indexes, which are three principal components derived from PCA, are a collection of 36 statistical indexes aimed at extracting and condensing steric and electrostatic 3D-properties of a molecule.',
      'The SZOTT descriptors are derived from PCA of a matrix of 1369 structural variables including 0D, 1D, 2D and 3D information for 20 coded amino acids.',
      'The 8 principal component scores of ST-scales are derived from 827 structural variables of 167 amino acids by PCA.',
      'The 5 principal component scores of T-scales are derived from PCA on the collected 67 kinds of structural and topological variables of 135 amino acids.',
      'The VHSE descriptors are derived from the PCA on independent families of 18 hydrophobic properties, 17 steric properties, and 15 electronic properties, respectively. Among them, VHSE1 and VHSE2 are related to hydrophobic properties, VHSE3 and VHSE4 to steric properties, and VHSE5–VHSE8 to electronic properties.',
      'The 3 principal component scores as VSTV descriptors are derived from PCA on a matrix of 25 structural and topological variables of 20 coded amino acids.',
      'The 7 principal properties of the GRID scales are derived from PCA on interaction energies of 20 coded amino acids, with six different probes mimicking various functional groups which can be involved in peptide-peptide interactions.',
      'The DPPS descriptors for 20 amino acids are derived by PCA. The electronic properties of the amino acid are characterized by V1-V4, steric properties by V5 and V6, hydrophobic properties by V7 and V8, and hydrogen bond contributions by V9 and V10.',
      'The BLOSUM matrix-derived descriptors (BLOSUM) including 10 indices, representing hydrophobicity, alpha-helix propensity, beta-sheet propensity, bulkiness, charge, and composition, are based on both physicochemical properties that have been subjected to a ARIMAX analyses and an alignment matrix of 20 coded amino acids, the BLOSUM62 matrix.',
      'These 12 vectors of HESH for 20 amino acids were obtained by PCA. The V1–V4 were hydrophobic properties, V5–V6 were steric properties, V7–V10 were electronic properties, and V11–V12 were hydrogen bond contribution properties.',
      'Three kinds of physicochemical parameters selected from properties of 20 coded amino acids, namely Van Der Waal’s volume, net charge index and hydrophobic parameter, construct the vectors to characterize the structures of peptides.',
      'The 8 principal component scores based on a PCA analysis of 58 amino acids properties explain 92% of the variances. For these predominant components, the first one is related to hydrophobicity and the second one is related to the size of amino acids.',
      'The amino acids indices of QTMS are obtained by application of PCA on the unfolded loadings of the a data matrix of QTMS of all bonds of amino acids.',
      'The 16 principal component scores are derived from PCA of 150 radial distribution function and 74 geometrical descriptors.',
      'The 10 principal component scores are obtained by PCA on 99 WHIM and 197 GETWAY descriptors.',
      'The 9 principal component scores are obtained by PCA on 99 WHIM descriptors.']
  }
}

const MuiTableContainer = (props) => (
  <TableContainer variant="outlined" className={"table_container"} sx={{ border: 0 }}>
    <Table aria-label="a dense table" sx={{ border: 0 }} {...props}>
      {props.children}
    </Table>
  </TableContainer>
)
const MuiTableCell = (props) => (
  <StyledTableCell style={{ whiteSpace: 'pre-wrap' }} {...props}>
    {props.children}
  </StyledTableCell>
)
const MuiTableHeadCell1st = (props) => (
  <StyledTableCell style={{ width: 282, padding: '24px' }}
    component="th" scope="row" className="bgc-dark-green" {...props}>
    {props.children}
  </StyledTableCell>
)

export function cleanAASDState() {
  cookie.setCookie(cookie.keys.tool.aasdUserDescriptor, '')
}

//---------------------------------------------------------------------------
const ToolAASD = ({ setInfo }) => {

  const [rowsArray, setRowsArray] = useState([])
  const result = () => {
    const newArray = [];
    for (let i = 0; i < texts.table.descriptors.length; i++) {
      const aasdDesc = {
        [texts.table.descriptors[i]]: texts.table.explanation[i]
      };
      newArray.push(aasdDesc);
      // console.log(aasdDesc)
    }
    setRowsArray(newArray)
  }
  
  useEffect(() => {
    result();
  }, [])
  
  //---------------------------------------------------------------------------
  // User name
  let username = cookie.getCookie(cookie.keys.auth.nickname)
  // console.log(username);
  if( username === undefined || username === '' )
    username = 'user'
  
  const usernameInputRef = useRef(null);
  const [keyword, setKeyword] = useState(username)
  const [isSearchError, setSearchError] = useState(false)
  const handleChangeSearch = (value) => {
    // console.log('value', value);
    setKeyword(value)
    setSearchError(false)
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter')
      handleClickSearch()
  }
  const handleClickSearch = () => {
    if (keyword !== '') {
      navigate(paths.spp.tool.peptide_calculator_result(keyword))
    } else {
      setSearchError(true)
    }
  }
  
  //----file upload ↓-----------------------------------------------------------------
  // Upload *.txt file
  const fileInputRef = useRef(null);
  const handleFileSelect = () => { //Browse
    fileInputRef.current.click();
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      setFileName(file.name);
    }
  };
  
  const clearInputFileds = () => { // Reset
    setFileName('');
    // setKeyword('');
    setClassifyId('');
    cleanAASDState()
  }

  const [s3FileUrl, setS3FileUrl] = useState('')
  const [stateList, setStateList] = useState(axiosState.set(false, false, false, 0))
  const navigate = useNavigate()
  let location = useLocation()
  const handleUpload = async () => {
    setStateList(axiosState.loadingState())
    const formData = new FormData();
    formData.append('file', file);

    const config = apiConfig.tool.aasd_tool_upload_file(formData)
    axios(config).then((result) => {
      if (result.data.result_code !== 200) {
        setStateList(axiosState.error(false, stateList.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setStateList(axiosState.resultCode200())

        setS3FileUrl(result.data.s3_uri[0])
        getDownloadFileUrl(result.data.s3_uri[0])
      }
    }).catch(err => {
      setStateList(axiosState.error(axiosCatch.isTimeout(err), stateList.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  };
  
  //---------------------------------------------------------------------------
  // Please select descriptor
  let userClass = cookie.getCookie(cookie.keys.tool.aasdUserDescriptor)
  // console.log('userClass', userClass);
  if( userClass === undefined )
    userClass = ''
  
  const [classifyId, setClassifyId] = useState(userClass)
  const selectChange = (value) => {
    // let value = event.target.value
    setClassifyId(value);
  };
  
  //---------------------------------------------------------------------------
  const [downloadUrl, setDownloadUrl] = useState('')
  const downloadFile = () => {
    window.open(downloadUrl, '_blank');
  }
  
  const [downloadStateList, setDownloadStateList] = useState(axiosState.init())
  const getDownloadFileUrl = (s3_uri) => {
    setDownloadStateList(axiosState.loadingState())

    const config = apiConfig.tool.aasd_tool_get_download_file_url({
      "descriptor_mark": classifyId,
      "file": [
        s3_uri
      ]
    })
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setDownloadStateList(axiosState.error(false, stateList.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setDownloadStateList(axiosState.resultCode200())
        setDownloadUrl(result.data.link)
      }
    }).catch(err => {
      setDownloadStateList(axiosState.error(axiosCatch.isTimeout(err), stateList.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  //----file upload ↑-----------------------------------------------------------------

  const firstGrid = 3, secondGrid = 4, thirdGrid = 5;
  //----render-----------------------------------------------------------------
  return (
    <LayoutPage bread={texts.bread} desc={texts.intro}>
      <Box className={scssTools.layout}>
        {/* File upload */}
        <ToolSingleBlock title={texts.title.fileUpload} mainBlockContent={
          <MuiTableContainer size="small">
            {/* <TableBody> */}
            {/* <StyledTableCell > */}
            <div className={scss.fields_block} style={{ display: 'flex', flexWrap: 'wrap' }}>
              {/* User name */}
              {/* <Grid container spacing={1} direction="row" alignItems="center">
                <Grid item xs={firstGrid}>
                  <h3>{texts.feature[0]}</h3>
                </Grid>
                <Grid item xs={secondGrid}>
                  <Input className='b2-grey100'
                    style={{ width: '200px' }}
                    sx={{ m: 0, flex: 1, width: '100%' }}
                    placeholder={texts.searchHint}
                    ref={usernameInputRef}
                    value={keyword}
                    onChange={(event) => { handleChangeSearch(event.target.value) }}
                    onKeyDown={handleKeyDown}
                    error={isSearchError ? true : false}
                  />
                </Grid>
                <Grid item s={thirdGrid}>
                </Grid>
              </Grid> */}
              
              {/* Upload *.txt file */}
              <Grid container spacing={1} direction="row" alignItems="center">
                <Grid item xs={firstGrid}>
                  <h3>{texts.feature[1]}</h3>
                </Grid>

                <Grid item xs={secondGrid}>
                  <input accept=".txt" type="file"
                    hidden
                    className="custom-file-input" data-target="file-uploader" id="file-uploader"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />

                  <LoadingButton
                    style={{ marginRight: '10px', backgroundColor: '#dfdfdf', color: '#2c2c2c', width: '90px' }}
                    onClick={handleFileSelect}
                    disabled={stateList.isLoading}
                  >Browse</LoadingButton>

                  {fileName !== "" && <span style={{ fontSize: "16px" }}>{fileName}</span>}
                </Grid>

                <Grid item xs={thirdGrid}>
                </Grid>
              </Grid>
              
              {/* Please select descriptor */}
              <Grid container spacing={1} direction="row" alignItems="center">
                <Grid item s={firstGrid}>
                  <h3>{texts.feature[2]}</h3>
                </Grid>
                <Grid item s={secondGrid}>
                  <MuiSelect id="standard-select-descriptor"
                    className={scss.select_descriptor}
                    value={classifyId}
                    onChange={(event) => selectChange(event.target.value)}
                  >
                    {texts.selectDes.map((option, index) => (
                      <MenuItem key={index} value={option}>{option}</MenuItem>
                    ))}
                  </MuiSelect>
                </Grid>
                <Grid item s={thirdGrid}>
                  <Box >
                    <Button style={{ marginRight: '10px', backgroundColor: '#dfdfdf', color: '#2c2c2c', width: '90px' }}
                      onClick={clearInputFileds}
                    >Reset</Button>

                    <LoadingButton
                      style={{ marginRight: '10px', backgroundColor: '#dfdfdf', color: '#2c2c2c', width: '90px' }} onClick={() => {
                        if (keyword === "") {
                          if (usernameInputRef.current) {
                            usernameInputRef.current.focus();
                          }
                          setInfo(snackInfo.open(snackType.error, "User name cannot be empty"))
                        } else if (file === null) {
                          setInfo(snackInfo.open(snackType.error, "Please select a text file"))
                        } else if (classifyId === "") {
                          setInfo(snackInfo.open(snackType.error, "Please select a descriptor"))
                        } else {
                          cookie.setCookie(cookie.keys.tool.aasdUserDescriptor, classifyId)
                          handleUpload()
                        }
                      }}
                      disabled={stateList.isLoading}
                      loading={stateList.isLoading}
                    >Submit</LoadingButton>
                  </Box>
                </Grid>
              </Grid>

              <Box height={10} />
              { downloadUrl !== "" &&
                <>
                  <Box height={10} />
                  <Grid container spacing={1} direction="row" alignItems="center">
                    <Grid item xs={firstGrid}>
                    </Grid>

                    <Grid item xs={secondGrid}>
                      <Box>
                        <Button style={{ marginRight: '10px', backgroundColor: '#dfdfdf', color: '#2c2c2c', width: '90px' }}
                          onClick={() => {downloadFile()}}
                        >Download</Button>
                        <span style={{ fontSize: "16px" }} className={scss.truncate}>
                          {s3FileUrl.split(".")[0]}.xlsx
                        </span>
                      </Box>
                    </Grid>
                  </Grid>
                </>}
            </div>
            {/* </StyledTableCell> */}
            {/* </TableBody> */}
          </MuiTableContainer>
        }>
        </ToolSingleBlock>
        
        {/* FASGAI...VSW */}
        <MuiTableContainer size="small">
          <TableBody  >
            <TableCell sx={{ padding: 0 }}>
              {rowsArray.map((item, index) => (
                <TableRow key={index} style={{ width: '100%', margin: '10px', maxWidth: '1040px', }} sx={{ border: 0 }}>
                  <MuiTableHeadCell1st>
                    <RouterLink newTab style={{ textDecoration: 'underline' }} className={scss.table_title}
                      to={paths.spp.tool.aasd_tool_resourse[index]}>
                        <TagText html={Object.keys(item)} />
                      </RouterLink>
                  </MuiTableHeadCell1st>
                  <MuiTableCell>
                    <TagText html={Object.values(item)} />
                  </MuiTableCell>
                </TableRow>))
              }
            </TableCell>
          </TableBody>
        </MuiTableContainer>
        
      </Box >
    </LayoutPage >
  )
}
export default ToolAASD
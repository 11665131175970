import React from 'react';
// import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';

//---------------------------------------------------------------------------
export default function NoneTextField(props) {
  return (
    <TextField
      fullWidth
      variant="standard"
      InputProps={{
        disableUnderline: true,
      }}
    /* // disableUnderline
    variant="outlined"
    sx={{'& input:valid + fieldset': {
        borderWidth: 0,
      },
      '& input:invalid + fieldset': {
        borderColor: 'red',
        borderWidth: 1,
      },
      '& input:valid:focus + fieldset': {
        borderWidth: 0,
      },
    }}*/
      {...props}>
      {props.children}
    </TextField>
  )
}

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Input, TableHead, TableRow } from '@mui/material';

import 'assets/scss/temp.scss';
import 'assets/scss/common.scss';
import v from 'assets/scss/_variables.scss';
import { NoneDiv } from 'components/GreenDiv';
import { TableHeadRow, TableRow2Body } from 'components/table/MuiTable';
import paths from "utils/network/apiPath";
import StyledTableCell from 'components/table/StyledTableCell';
import { MuiTableContainer } from "components/table/MuiTable";
import { images } from 'utils/imgs';

//---------------------------------------------------------------------------
const texts = {
  title: 'Descriptors of MS-WHIM for 20 coded amino acids.',

  additional_info: 'ᵃ MS-WHIM indexes, which are three principal components derived from PCA, are a collection of 36 statistical indexes aimed at extracting and condensing steric and electrostatic 3D-properties of a molecule.',

  references: {
    title: 'References',
    context: '[1] A. Zaliani, E. Gancia, MS-WHIM scores for amino acids: A new 3D-description for peptide QSAR and QSPR studies, J. Chem. Inf. Comput. Sci., 39 (1999) 525-533'
  },
  table: {
    text: 'Descriptors of MS-WHIM for 20 coded amino acids.',
    title: [
      'AA', 'Abbr.', 'Firstᵃ', 'Second', 'Third',
    ]
  }
}

// class itemData {
//   static create(amino, three, single, molecular, isoelectric, hydrophilicity) {
//     return { amino, three, single, molecular, isoelectric, hydrophilicity }
//   }
//   static output = ['amino', 'three', 'single', 'molecular', 'isoelectric', 'hydrophilicity']
// }
class itemData {
  static create(aa, abbr, first, second, third) {
    return { aa, abbr, first, second, third}
  }
  static output = ['aa', 'abbr', 'first', 'second', 'third']
}

const items = [ 
itemData.create('Ala', 'A', '-0.73', '0.20', '-0.62'),
itemData.create('Arg', 'R', '-0.22', '0.27', '1.00'),
itemData.create('Asn', 'N', '0.14', '0.20', '-0.66'),
itemData.create('Asp', 'D', '0.11', '-1.00', '-0.96'),
itemData.create('Cys', 'C', '-0.66', '0.26', '-0.27'),
itemData.create('Gln', 'Q', '0.30', '1.00', '-0.30'),
itemData.create('Glu', 'E', '0.24', '-0.39', '-0.04'),
itemData.create('Gly', 'G', '-0.31', '-0.28', '-0.75'),
itemData.create('His', 'H', '0.84', '0.67', '-0.78'),
itemData.create('Ile', 'I', '-0.91', '0.83', '-0.25'),
itemData.create('Leu', 'L', '-0.74', '0.72', '-0.16'),
itemData.create('Lys', 'K', '-0.51', '0.08', '0.60'),
itemData.create('Met', 'M', '-0.70', '1.00', '-0.32'),
itemData.create('Phe', 'F', '0.76', '0.85', '-0.34'),
itemData.create('Pro', 'P', '-0.43', '0.73', '-0.60'),
itemData.create('Ser', 'S', '-0.80', '0.61', '-1.00'),
itemData.create('Thr', 'T', '-0.58', '0.85', '-0.89'),
itemData.create('Trp', 'W', '1.00', '0.98', '-0.47'),
itemData.create('Tyr', 'Y', '0.97', '0.66', '-0.16'),
itemData.create('Val', 'V', '-1.00', '0.79', '-0.58')
]

const MuiTableHead = (props) => (
  <TableHead className="bgc-dark-green">
    {props.children}
  </TableHead>
)
const MuiTableHeadCell = (props) => (
  <MuiTableHead>
    <TableRow>
      <StyledTableCell style={{ color: v.white }} {...props}>
        {props.children}
      </StyledTableCell>
    </TableRow>
  </MuiTableHead>
)

const MuiTableFooterCell = (props) => (
  <MuiTableHead className="bgc-grey100">
    <StyledTableCell style={{ color: v.white,backgroundColor:v.grey100 }} {...props}>
      {props.children}
    </StyledTableCell>
  </MuiTableHead>
)

const rowChildren = (item, index) => {
  return (
    <TableRow2Body key={index}>
      {itemData.output.map((output, count) => (
        <StyledTableCell key={count}>{item[output]}</StyledTableCell>
      ))}
    </TableRow2Body>
  )
}

//---------------------------------------------------------------------------
const  SZOTT = () => {
  return (
    <NoneDiv style={{ gap: 20 }}>
       <img  src={images._04ToolAASD['06SZOTT.png']}alt="SZOTT" />
      {/* <h1>{texts.title}</h1>

      <MuiTableContainer size="small">
        <TableHeadRow className="bgc-dark-green">
          {texts.table.title.map((title, index) => (
            <StyledTableCell key={index} style={{ color: v.white }}>{title}</StyledTableCell>
          ))}
        </TableHeadRow>
        <TableBody>
          {items.map((item, index) => (
            rowChildren(item, index)
          ))}
        </TableBody>
        <MuiTableHeadCell colSpan={texts.table.title.length} align='flex-end'>{texts.additional_info}</MuiTableHeadCell>

        <MuiTableFooterCell colSpan={texts.table.title.length} align='flex-end'>{texts.references.title}:{texts.references.context}</MuiTableFooterCell>
      </MuiTableContainer> */}
    </NoneDiv>
  )
}
export default  SZOTT
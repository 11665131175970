import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Input, TableHead, TableRow } from '@mui/material';

import 'assets/scss/temp.scss';
import 'assets/scss/common.scss';
import v from 'assets/scss/_variables.scss';
import { NoneDiv } from 'components/GreenDiv';
import { TableHeadRow, TableRow2Body } from 'components/table/MuiTable';
import paths from "utils/network/apiPath";
import StyledTableCell from 'components/table/StyledTableCell';
import { MuiTableContainer } from "components/table/MuiTable";
import { images } from 'utils/imgs';

//---------------------------------------------------------------------------
const texts = {
  title: 'Descriptors of Z-scales for 20 coded amino acids.',

  additional_info: 'ᵃ The 3 principal component scores are derived from PCA of a matrix of 29 physicochemical variables for 20 coded amino acid are related to hydrophilicity (z1), bulk (z2), and electronic properties (z3).',

  references: {
    title: 'References',
    context: '[1] S. Hellberg, M. Sjostrom, B. Skagerberg, S. Wold, Peptide Quantitative structure-activity-relationships, a multivariate approach, J. Med. Chem., 30 (1987) 1126-1135.'
  },
  table: {
    text: 'Descriptors of Z-scales for 20 coded amino acids.',
    title: [
      'AA', 'Abbr.', 'Z1ᵃ', 'Z2', 'Z3',
    ]
  }
}

// class itemData {
//   static create(amino, three, single, molecular, isoelectric, hydrophilicity) {
//     return { amino, three, single, molecular, isoelectric, hydrophilicity }
//   }
//   static output = ['amino', 'three', 'single', 'molecular', 'isoelectric', 'hydrophilicity']
// }
class itemData {
  static create(aa, abbr, z1, z2, z3) {
    return { aa, abbr, z1, z2, z3 }
  }
  static output = ['aa', 'abbr', 'z1', 'z2', 'z3']
}

const items = [
  itemData.create('Ala', 'A', '0.07', '-1.73', '0.09'),
  itemData.create('Arg', 'R', '2.88', '2.52', '-3.44'),
  itemData.create('Asn', 'N', '3.22', '1.45', '0.84'),
  itemData.create('Asp', 'D', '3.64', '1.13', '2.36'),
  itemData.create('Cys', 'C', '0.71', '-0.97', '4.13'),
  itemData.create('Gln', 'Q', '2.18', '0.53', '-1.14'),
  itemData.create('Glu', 'E', '3.08', '0.39', '-0.07'),
  itemData.create('Gly', 'G', '2.23', '-5.36', '0.30'),
  itemData.create('His', 'H', '2.41', '1.74', '1.11'),
  itemData.create('Ile', 'I', '-4.44', '-1.68', '-1.03'),
  itemData.create('Leu', 'L', '-4.19', '-1.03', '-0.98'),
  itemData.create('Lys', 'K', '2.84', '1.41', '-3.14'),
  itemData.create('Met', 'M', '-2.49', '-0.27', '-0.41'),
  itemData.create('Phe', 'F', '-4.92', '1.30', '0.45'),
  itemData.create('Pro', 'P', '-1.22', '0.88', '2.23'),
  itemData.create('Ser', 'S', '1.96', '-1.63', '0.57'),
  itemData.create('Thr', 'T', '0.92', '-2.09', '-1.40'),
  itemData.create('Trp', 'W', '-4.75', '3.65', '0.85'),
  itemData.create('Tyr', 'Y', '-1.39', '2.32', '0.01'),
  itemData.create('Val', 'V', '-2.69', '-2.53', '-1.29')
]

const MuiTableHead = (props) => (
  <TableHead className="bgc-dark-green">
    {props.children}
  </TableHead>
)
const MuiTableHeadCell = (props) => (
  <MuiTableHead>
    <TableRow>
      <StyledTableCell style={{ color: v.white }} {...props}>
        {props.children}
      </StyledTableCell>
    </TableRow>
  </MuiTableHead>
)

const MuiTableFooterCell = (props) => (
  <MuiTableHead className="bgc-grey100">
    <StyledTableCell style={{ color: v.white,backgroundColor:v.grey100 }} {...props}>
      {props.children}
    </StyledTableCell>
  </MuiTableHead>
)

const rowChildren = (item, index) => {
  return (
    <TableRow2Body key={index}>
      {itemData.output.map((output, count) => (
        <StyledTableCell key={count}>{item[output]}</StyledTableCell>
      ))}
    </TableRow2Body>
  )
}

//---------------------------------------------------------------------------
const ZScales = () => {
  return (
    <NoneDiv style={{ gap: 20 }}>
      <img  src={images._04ToolAASD['02Z-scales.png']}alt="ZScales" />

      {/* <h1>{texts.title}</h1> */}

      {/* <MuiTableContainer>
        <MuiTableHeadCell colSpan={texts.table.title.length}>{texts.input}</MuiTableHeadCell>
        <TableBody>
          <StyledTableCell colSpan={texts.table.title.length}>
            <h3>{texts.searchHint}</h3>
            <Input className='b2-grey100'
              sx={{ m: 0, flex: 1, width: '97%' }}
              placeholder={texts.searchHint}
              value={keyword}
              onChange={(event) => { handleChangeSearch(event.target.value) }}
              onKeyDown={handleKeyDown}
              error={isSearchError ? true : false}
            />
            <IconButton type="button" sx={{ padding: '0px' }} aria-label="search" onClick={handleClickSearch}>
              <SearchIcon />
            </IconButton>
          </StyledTableCell>
        </TableBody>
      </MuiTableContainer>
       */}
    </NoneDiv>
  )
}
export default ZScales
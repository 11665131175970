import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

//---------------------------------------------------------------------------
export function listDate(label, init) { //initial
  return { label, init }
}

//---------------------------------------------------------------------------
export default function TextList(props) {
  const index = props.index ? props.index : 0;
  const label = props.option ? props.option[index].label : '';
  const fontSize= props.fontSize ? props.fontSize : '20px';
  // console.log('index=', index, ' props.index=', props.index);
  // console.log('label=', label, ' props.option[index].label=', props.option[index].label);
  /* 
  const [value, setValue] = React.useState(init);
  const handleChange = (event) => {
    // console.debug(value);
        // console.debug(event.target.value);
        setValue(event.target.value);
    };
   */
  return (
    <TextField
      select
      fullWidth
      defaultValue={label}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        style: { fontSize: fontSize} 
        // endAdornment: (
        // 	<KeyboardArrowDownIcon position="end"></KeyboardArrowDownIcon>
        // ),
      }}
      {...props}>
      {props.option.map((option) => (
        <MenuItem key={option.label} value={option.label}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

import React from 'react'
import scss from 'views/tool/Tools.module.scss';
import { breadData } from 'components/bread/BreadBar';
import LayoutPage from 'layouts/LayoutPage';


const texts = {
  bread: breadData.create('Terms & Conditions', 'Terms & Conditions'),
}

//---------------------------------------------------------------------------
const Terms = () => {
  return (
    <LayoutPage bread={texts.bread}>
      <div className={scss.layout}>
      </div>
    </LayoutPage>
  )
}

export default Terms;
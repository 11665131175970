import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { breadData } from 'components/bread/BreadBar';
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import chartProp from 'components/chart/chartProp';
import SearchResultLayout from 'layouts/search/SearchResultLayout';
import cookie from 'utils/cookie';
import paths from 'utils/network/apiPath';
import { apiConfig } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';

//---------------------------------------------------------------------------
const jsonsResult = spp.tool.crs_tool_result
const ToolCRSToolResultLv3 = ({ setInfo }) => {
  /* Table related */
  const [queryParameters] = useSearchParams()
  const source = queryParameters.get(paths.params.source)
  const cls = queryParameters.get(paths.params.class)
  const venn = queryParameters.get(paths.params.venn)
  const keyword = queryParameters.get(paths.params.keyword)
  const sourceArr = source ? source.split(',') : ''
  const clsArr = cls ? cls.split(',') : ''
  const vennArr = venn ? venn.split(',') : ''
  // console.log('venn', venn);
  const hasVenn = (venn !== null)
  
  const isSearchFragment = (keyword && keyword !== '')
  // console.log(isSearchFragment);

  // const clsName = cookie.getCookie(cookie.keys.tool.crsSelectClsLv3)
  let sourceName = cookie.getCookie(cookie.keys.tool.crsSelectSourceLv3)
  // console.log('clsName:', clsName, ' sourceName:', sourceName)
  if(hasVenn) {
    sourceName = cookie.getCookie(cookie.keys.tool.crsSelectVennLv3)
  }
  let breadText = `${sourceName} protein result`
  let breadTextFontSize = breadData.getFontSize(breadText)
  
  let jsons = {}
  if( cls === "multifunctionalpeptides" ) {
    jsons.tableHeadCell = jsonsResult.items_text_multi
    jsons.tableBodyCell = jsonsResult.items_multi
  } else {
    jsons.tableHeadCell = jsonsResult.items_text
    jsons.tableBodyCell = jsonsResult.items
  }
  
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  let texts = {
    bread: breadData.create('Tools', breadText, bread3rd, 'Result', '', breadTextFontSize),

    searchHint: 'Search by SPP ID, AA sequence, Peptide name, AA length, Precursor protein, Pubdate',
    tableHeadCell: jsons.tableHeadCell,
    tableBodyCell: jsons.tableBodyCell,
    
    multi: {
      tableHeadCell: jsonsResult.items_text_multi,
      tableBodyCell: jsonsResult.items_multi,
    },
  }
  
  //---------------------------------------------------------------------------
  if( isSearchFragment ) {
    if( hasVenn ) {
      return (
        <SearchResultLayout
          setInfo={setInfo}
          texts={texts}
          configSearch={apiConfig.tool.crs_tool_result} //86
          jsonInput={jsonsResult.input_fragment_venn}
          inputProp={[sourceArr, keyword, vennArr]}
          jsonInputKeyword={jsonsResult.input_fragment_venn_keyword}
          isSearchFragment={isSearchFragment}
          hasVenn={hasVenn}
        />)
    } else {
      return (
          <SearchResultLayout
            setInfo={setInfo}
            texts={texts}
            configSearch={apiConfig.tool.crs_tool_result} //86
            jsonInput={jsonsResult.input_fragment_lv3}
            inputProp={[[source], cls, keyword]}
            jsonInputKeyword={jsonsResult.input_fragment_keyword_lv3}
            isSearchFragment={isSearchFragment}
          />)
    }
  } else {
    if( hasVenn )
      return (
        <SearchResultLayout
          setInfo={setInfo}
          texts={texts}
          configSearch={apiConfig.tool.crs_tool_result} //86
          jsonInput={jsonsResult.input_venn}
          inputProp={[sourceArr, clsArr, vennArr]}
          jsonInputKeyword={jsonsResult.input_venn_keyword}
          hasVenn={hasVenn}
        />)
    else 
      return (
        <SearchResultLayout
          setInfo={setInfo}
          texts={texts}
          configSearch={apiConfig.tool.crs_tool_result} //86
          jsonInput={jsonsResult.input}
          inputProp={[[source], [cls]]}
          jsonInputKeyword={jsonsResult.input_keyword}
        />)
  }
}
export default ToolCRSToolResultLv3
import React from 'react'

import scss from 'views/tool/Tools.module.scss';
import { breadData } from 'components/bread/BreadBar';
import LayoutPage from 'layouts/LayoutPage';

//---------------------------------------------------------------------------
const texts = {
  bread: breadData.create('Privacy Policy', 'Privacy Policy'),
}

//---------------------------------------------------------------------------
const Privacy = () => {
  return (
    <LayoutPage bread={texts.bread}>
      <div className={scss.layout}>
      </div>
    </LayoutPage>
  )
}

export default Privacy;
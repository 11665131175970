import { Button } from "@mui/material";

import v from 'assets/scss/_variables.scss';
import scss from 'components/mui/MuiButton.module.scss';

//------------------------------------------------------------------------------
const MuiButton = (props) => {
  return (
    <Button
      className={scss.mui_button}
      variant="standard"
      style={{
        boxShadow: "none",
        textTransform: "none",
        padding: "8px 24px",
        backgroundColor: v.green,
        borderRadius: "100px",
        '&:hover': {
          // backgroundColor: v.green,
        },
      }}
      {...props}
    >
      {props.children}
    </Button>)
}

export default MuiButton
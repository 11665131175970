// define router path
//---------------------------------------------------------------------------
const params = {
  id: "id",
  class: "cls",
  source: "source",
  cross: "cross",
  select: "select",
  value: "value",
  keyword: "keyword",

  proteinID: "proteinID",
  peptideID: "peptideID",
  fragment: "fragment",
  peptides: "peptides",

  protein: "protein", //protein name
  all: "all",

  enzyme: "enzyme",
  amino_acid: "aa",
  // 4.7
  organism: "organism",
  weight: "weight",
  method: "method",
  // 4.9
  venn: "venn",
  // 4.11 4.12
  dataset: "dataset",
  ind: "i",
  input: 'input',
  site: 'site',
  sequence: 'seq',
};

function addParamStart(args) {
  if (args.length > 0) return "?";
  else return "";
}
function addParam(variable, value, separator = "") {
  if (value !== undefined) return `${separator}${variable}=${value}`;
  return "";
}
//---------------------------------------------------------------------------
// Authentication
const auth = {
  login: "/login",
  signup: "/signup",
  terms: "/user_terms", //tnc
  verify: "/verify",
};
//---------------------------------------------------------------------------
const site = {
  home: "/",
  privacy: "/privacy",
  terms: "/terms",

  proteins: "/proteins",
  peptides: "/peptides",
  tools: "/tools",
  products: "/products",
  about: "/about",
};
//---------------------------------------------------------------------------
const spp = {
  protein: {
    quick_search: function () {
      let path = "/protein_quick";
      return path;
    },
    class_search: function () {
      let path = "/protein_cls";
      return path;
    },
    source_search: function () {
      let path = "/protein_source";
      return path;
    },
    id_result: function (id) {
      let path = "/protein_id";
      path += addParamStart(arguments);
      path += addParam(params.id, id);
      return path;
    },
    class_count: function (id) {
      let path = "/protein_cls_count";
      path += addParamStart(arguments);
      path += addParam(params.id, id);
      return path;
    },
    fragment_count: function (id) {
      let path = "/protein_frag_count";
      path += addParamStart(arguments);
      path += addParam(params.id, id);
      return path;
    },
    class_graph: function () {
      let path = "/protein_cls_graph";
      return path;
    },
    frag_graph: function () {
      let path = "/protein_frag_graph";
      return path;
    },
    peptide_list: function (id, cls) {
      let path = "/protein_peptide_list";
      path += addParamStart(arguments);
      path += addParam(params.id, id);
      path += addParam(params.class, cls, "&");
      return path;
    },
  },
  //---------------------------------------------------------------------------
  peptide: {
    quick_search: function () {
      let path = "/peptide_quick";
      return path;
    },
    multi_search: function () {
      let path = "/peptide_multi";
      return path;
    },
    quick_class_search: function (cls) {
      let path = "/peptide_quick_cls";
      path += addParamStart(arguments);
      path += addParam(params.class, cls);
      return path;
    },
    class_search: function (cls, cross) {
      let path = "/peptide_cls";
      path += addParamStart(arguments);
      path += addParam(params.class, cls);
      path += addParam(params.cross, cross, "&");
      return path;
    },
    advanced_search: function () {
      let path = "/peptide_adv";
      return path;
    },
    id_result: function (id, cls) {
      let path = "/peptide_id";
      path += addParamStart(arguments);
      path += addParam(params.id, id);
      path += addParam(params.class, cls, "&");
      return path;
    },
    id_multi_result: function (id, cls) {
      let path = "/peptide_id_multi";
      path += addParamStart(arguments);
      path += addParam(params.id, id);
      path += addParam(params.class, cls, "&");
      return path;
    },
    id_target_protein_page: function (id) {
      let path = "/peptide_id_target";
      path += addParamStart(arguments);
      path += addParam(params.id, id);
      return path;
    },
    fun_relationships: function () {
      let path = "/peptide_fun_rel";
      return path;
    },
    statistical_data: function () {
      let path = "/peptide_stat_data";
      return path;
    },
    class_advanced_search: function (cls, select, value) {
      let path = "/peptide_cls_adv";
      path += addParamStart(arguments);
      path += addParam(params.class, cls);
      path += addParam(params.select, select, "&");
      path += addParam(params.value, value, "&");
      return path;
    },
    hotspot_result: function (proteinID, peptideID, fragment, peptides) {
      let path = "/peptide_list_hotspot";
      path += addParamStart(arguments);
      path += addParam(params.proteinID, proteinID);
      path += addParam(params.peptideID, peptideID, "&");
      path += addParam(params.fragment, fragment, "&");
      path += addParam(params.peptides, peptides, "&");
      return path;
    },
    inductive_analysis: function (keyword, cls) {
      let path = "/peptide_ia";
      path += addParamStart(arguments);
      path += addParam(params.keyword, keyword);
      path += addParam(params.class, cls, "&");
      return path;
    },
  },
  //---------------------------------------------------------------------------
  tool: {
    // 4.1
    hotspot_search: function () {
      let path = "/tool_hs_s";
      return path;
    },
    hotspot_result: function (id, keyword) {
      let path = "/tool_hs_r";
      path += addParamStart(arguments);
      path += addParam(params.id, id);
      path += addParam(params.keyword, keyword, "&");
      return path;
    },
    //A hotspot_result_chart(keyword, {cls: className})
    //B hotspot_result_chart(keyword, {all: true, id: peptideID})
    //B hotspot_result_chart(keyword, {all: 1, id: peptideID})
    hotspot_result_chart: function (keyword, { cls, all, id } = {}) {
      let path = "/tool_hs_rc";
      path += addParamStart(arguments);
      path += addParam(params.keyword, keyword);
      path += addParam(params.class, cls, "&");
      path += addParam(params.all, all, "&");
      path += addParam(params.id, id, "&");
      return path;
    },
    // 2.1.1.3
    hotspot_result_precursor: function (peptideID, proteinID, fragment) {
      let path = "/tool_hs_rp";
      path += addParamStart(arguments);
      path += addParam(params.peptideID, peptideID);
      path += addParam(params.proteinID, proteinID, "&");
      path += addParam(params.fragment, fragment, "&");
      return path;
    },
    // 4.2
    ehp_tool_search: function () {
      let path = "/tool_ehp_s";
      return path;
    },
    ehp_tool_enzyme_table: function () {
      let path = "/tool_ehp_et";
      return path;
    },
    ehp_tool_enzyme_page: function (id) {
      let path = "/tool_ehp_ep";
      path += addParamStart(arguments);
      path += addParam(params.id, id);
      return path;
    },
    //A paths.spp.tool.ehp_tool_result(enzyme, {keyword: key})
    //B paths.spp.tool.ehp_tool_result(enzyme, {id: proteinId})
    ehp_tool_result: function (enzyme, { keyword, id } = {}) {
      let path = "/tool_ehp_r";
      path += addParamStart(arguments);
      path += addParam(params.enzyme, enzyme);
      path += addParam(params.keyword, keyword, "&");
      path += addParam(params.id, id, "&");
      return path;
    },
    // 4.3
    bpp_tool: function () {
      let path = "/tool_bpp";
      return path;
    },
    bpp_tool_result: function () {
      let path = "/tool_bpp_r";
      return path;
    },
    // 4.4
    aasd_tool: function () {
      let path = "/tool_aasd";
      return path;
    },
    aasd_tool_resourse: [
      "/tool_aasd/fasgai",
      "/tool_aasd/zScales",
      "/tool_aasd/nnaaindex",
      "/tool_aasd/isaeci",
      "/tool_aasd/mswhim",
      "/tool_aasd/szoot",
      "/tool_aasd/stScales",
      "/tool_aasd/tScales",
      "/tool_aasd/vhse",
      "/tool_aasd/vstv",
      "/tool_aasd/grid",
      "/tool_aasd/dpps",
      "/tool_aasd/blosum26",
      "/tool_aasd/hesh",
      "/tool_aasd/lin",
      "/tool_aasd/protfp",
      "/tool_aasd/qtms",
      "/tool_aasd/svrg",
      "/tool_aasd/svwg",
      "/tool_aasd/vsw",
    ],
    // 4.5
    peptide_calculator: function () {
      let path = "/tool_pc";
      return path;
    },
    peptide_calculator_result: function (keyword) {
      let path = "/tool_pc_r";
      path += addParamStart(arguments);
      path += addParam(params.keyword, keyword);
      return path;
    },
    // 4.6
    multi_cross_analysis: function () {
      let path = "/tool_mca";
      return path;
    },
    peptide_statistics: function () {
      let path = "/tool_mca_ps";
      return path;
    },
    peptide_statistical_nc_chart: function (aa) {
      let path = "/tool_mca_ps_ncc";
      path += addParamStart(arguments);
      path += addParam(params.amino_acid, aa);
      return path;
    },
    peptide_statistical_a_chart: function (aa) {
      let path = "/tool_mca_ps_ac";
      path += addParamStart(arguments);
      path += addParam(params.amino_acid, aa);
      return path;
    },
    // 4.7
    pdcaas: function () {
      let path = "/tool_pdcaas"
      return path
    },
    pdcaas_table: function () {
      let path = "/tool_pdcaas_t"
      return path
    },
    pdcaas_result: function (organism, weight, method) {
      let path = "/tool_pdcaas_r"
      path += addParamStart(arguments);
      path += addParam(params.organism, organism, "&");
      path += addParam(params.weight, weight, "&");
      path += addParam(params.method, method, "&");
      return path
    },
    // 4.8
    // 4.9
    crs_tool: function () {
      //CRS=Chickpea Rice Soybean
      let path = "/tool_crs";
      return path;
    },
    crs_tool_result: function (source, cls) {
      let path = "/tool_crs_r";
      path += addParamStart(arguments);
      path += addParam(params.source, source, "&");
      path += addParam(params.class, cls, "&");
      return path;
    },
    crs_tool_result_lv2: function (source, {cls,  keyword } = {}) {
      let path = "/tool_crs_r2";
      path += addParamStart(arguments);
      path += addParam(params.source, source, "&");
      path += addParam(params.class, cls, "&");
      path += addParam(params.keyword, keyword, "&");
      return path;
    },
    crs_tool_result_lv3: function (source, {cls, venn, keyword } = {}) {
      let path = "/tool_crs_r3";
      path += addParamStart(arguments);
      path += addParam(params.source, source, "&");
      path += addParam(params.class, cls, "&");
      path += addParam(params.venn, venn, "&");
      path += addParam(params.keyword, keyword, "&");
      return path;
    },
    // 4.10
    hotspot_css_tool: function () {
      let path = "/tool_hc";
      return path;
    },
    hotspot_css_tool_result: function ({source, id} = {}, { keyword, cls } = {}) {
      let path = "/tool_hc_r";
      path += addParamStart(arguments);
      path += addParam(params.source, source, "&");
      path += addParam(params.id, id, "&");
      path += addParam(params.keyword, keyword, "&");
      path += addParam(params.class, cls, "&");
      // path += addParam(params.all, all, "&");
      return path;
    },
    // 4.11
    aceipp: function () {
      let path = "/tool_aceipp"
      return path;
    },
    aceipp_sf: function() {
      let path = '/tool_aceipp_sf'
      return path;
    },
    aceipp_pl: function() {
      let path = '/tool_aceipp_pl'
      return path;
    },
    // 4.11 4.12
    aceipp_anoxpp_pp: function(dataset, ind) {
      let path = '/tool_aceipp_anoxpp_pp'
      path += addParamStart(arguments);
      path += addParam(params.dataset, dataset, "&");
      path += addParam(params.ind, ind, "&");
      return path;
    },
    aceipp_anoxpp_mr: function(dataset) {
      let path = '/tool_aceipp_anoxpp_mr'
      path += addParamStart(arguments);
      path += addParam(params.dataset, dataset, "");
      return path;
    },
    aceipp_anoxpp_mm: function(dataset, keyword, {site} = {}) {
      let path = '/tool_aceipp_anoxpp_mm'
      path += addParamStart(arguments);
      path += addParam(params.dataset, dataset, "&");
      path += addParam(params.keyword, keyword, "&");
      path += addParam(params.site, site, "&");
      return path;
    },
    aceipp_anoxpp_mp: function(dataset, sequence) {
      let path = '/tool_aceipp_anoxpp_mp'
      path += addParamStart(arguments);
      path += addParam(params.dataset, dataset, "&");
      path += addParam(params.sequence, sequence, "&");
      return path;
    },
    aceipp_anoxpp_ur: function(dataset) {
      let path = '/tool_aceipp_anoxpp_ur'
      path += addParamStart(arguments);
      path += addParam(params.dataset, dataset, "");
      return path;
    },
    // 4.12
    anoxpp: function () {
      let path = "/tool_anoxpp"
      return path;
    },
    anoxpp_sf: function() {
      let path = '/tool_anoxpp_sf'
      return path;
    },
    anoxpp_pl: function() {
      let path = '/tool_anoxpp_pl'
      return path;
    },
  },
};

const openNewTabWithRelativePath = (relativePath) => {
  const isHasHistory = true;
  const baseUrl = window.location.origin;
  const fullUrl = `${baseUrl}${relativePath}`;
  window.open(fullUrl, "_blank");
};

//---------------------------------------------------------------------------
const paths = {
  // root: '/',
  auth,
  // user,
  site,
  spp,

  params,

  openNewTabWithRelativePath,
};
export default paths;

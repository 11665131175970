import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import v from "assets/scss/_variables.scss";
import scss from './Verify.module.scss';
import paths from "utils/network/apiPath";
import axios, { apiConfig } from "utils/network/axios";
import jsons from "utils/network/jsons";

//---------------------------------------------------------------------------


//---------------------------------------------------------------------------
const Verify = () => {

  const { e_token, p_token } = useParams();
  const navigate = useNavigate();

  const UnverifiedIcon = () => {
    return (<HighlightOffIcon style={{ fontSize: "60px", color: v.alert }} />)
  }

  const RedirectRegisterButton = (props) => {
    return (<Button variant="outlined" style={{ textTransform: "none" }} component={Link} to={props.path} >Go to {props.pageTitle}</Button>
    )
  }

  const [isVerifying, setIsVerifying] = useState(false);
  const [verified, setVerified] = useState(false);

  const [icon, setIcon] = useState(<UnverifiedIcon />)
  const [title, setTitle] = useState("Verifying...");
  const [content, setContent] = useState("");
  const [bottomElement, setBottomElement] = useState(<RedirectRegisterButton pageTitle="Register" path={paths.auth.signup} />);

  const [waitToHomeSec, setWaitToHomeSec] = useState(5);
  const [shouldRedirect, setShouldRedirect] = useState(false);


  // Unverified → isVerifying === false && verified === false
  const unverifiedDefaultText = "Sorry, verification failed! Please register again."
  const Unverified = () => {
    setIcon(<UnverifiedIcon />)
    setTitle("Unverified")
    setContent(unverifiedDefaultText)
    setBottomElement(<RedirectRegisterButton pageTitle="Register" path={paths.auth.signup} />)
  }

  // Verified → isVerifying === false && verified === true
  const Verified = () => {
    setIcon(<CheckCircleOutlineIcon style={{ fontSize: "60px", color: v.green }} />)
    setTitle("Verified")
    setContent(`Verified! System will redirect to Home during ${waitToHomeSec} seconds.`)
    setBottomElement(<RedirectRegisterButton pageTitle="Home" path={paths.site.home} />)
  }

  // Verifying → isVerifying === true && verified === false
  const Verifying = () => {
    setIcon(<CircularProgress size={50} style={{ color: v.green }} />)
    setTitle("Verifying")
    setContent("Verifying the email, please wait a moment.")
    setBottomElement(<></>)
  }


  //------------------------------------------
  useEffect(() => {
    if (e_token === undefined || p_token === undefined) {
      setIsVerifying(false)
      setVerified(false)
    }

    if (e_token !== undefined && p_token !== undefined) {
      setIsVerifying(true)
      setVerified(false)

      let dataToSend = jsons.auth.verifyEmail.input(
        e_token,
        p_token)
      const config = apiConfig.auth.verify_email(dataToSend)

      axios(config)
        .then(result => {
          result.data.result_code = 200
          switch (result.data.result_code) {
            case 200:
              setIsVerifying(false)
              setVerified(true)
              break;
            default:
              setIsVerifying(false)
              setVerified(false)
          }

        }).catch(err => {
          console.log("err:",err)
          setIsVerifying(false)
          setVerified(false)
        })

    }
  }, [])


  useEffect(() => {
    if (isVerifying === false && verified === false) {
      Unverified()
    }

    if (isVerifying === true && verified === false) {
      Verifying()
    }

    if (isVerifying === false && verified === true) {
      Verified()
      setWaitToHomeSec(4)
    }

  }, [isVerifying, verified]);

  useEffect(() => {
    let  timerId = null;
    if (isVerifying === false && verified === true) {
       timerId = setInterval(() => {
        setWaitToHomeSec(prevSec => {
          if (prevSec <= 0) {
            clearInterval(timerId);
            setShouldRedirect(true);
            return 0;
          } else {
            return prevSec - 1;
          }
        });
        setContent(`Verified! System will redirect to Home during ${waitToHomeSec} seconds.`)
      }, 1000);
    }

    return () => clearInterval(timerId);
  }, [waitToHomeSec])


  useEffect(() => {
    if (shouldRedirect) {
      navigate(paths.site.home);
    }
  }, [shouldRedirect, navigate]);


  return (
    <div className={scss.layout}>
      <div className={scss.main_block}>
        {icon}
        <div className={scss.title}>{title}</div>
        <div className={scss.content}>{content}</div>
        <div className={scss.bottom_button}>  {bottomElement}
        </div>
      </div>
    </div>
  )
}

export default Verify;
import * as React from 'react';
import Chip from '@mui/material/Chip';

//------------------------------------------------------------------------------
 const MuiChip = (props) => {
  const {label} = props
  
  return (
    <Chip
      label={label}
      // onDelete={onDelete}
      {...props}/>)
}
export default MuiChip
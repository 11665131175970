import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedPeptideCls: "",
  amDonutCharV4HoveredPeptideName: "",
  isPeptideListHovering: false,
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    updateSelectedPeptideCls: (state, action) => {
        state.selectedPeptideCls = action.payload.value;
    },
    hoverListItem: (state, action) => {
      state.amDonutCharV4HoveredPeptideName = action.payload.value;
    },
    updatePeptideListHoverStatus: (state, action) => {
      state.isPeptideListHovering = action.payload.value;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateSelectedPeptideCls,
  updateSelectedPeptideClsByListHove,
  hoverListItem,
  updatePeptideListHoverStatus,
} = mainSlice.actions;

export default mainSlice.reducer;

// import { useState } from "react";
// import { Outlet } from 'react-router-dom';

import scss from 'views/protein/ProteinList.module.scss';
import BreadBar from 'components/bread/BreadBar';

//---------------------------------------------------------------------------
export default function LayoutPage({ children, ...props }) {
  // console.log('Layout props', props);
  // console.log('Layout', props.bread);

  return (
    <div className={scss.layout}>
      <BreadBar bread={props.bread}
        searchInput={props.searchInput} jsonInput={props.jsonInput}
        searchOutput={props.searchOutput} desc={props.desc} tooltipOfDesc={props.tooltipOfDesc}/>
      <main>{children}</main>
    </div>
  )
}

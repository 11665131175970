import { TextField } from '@mui/material';
import styled from 'styled-components';

import v from "assets/scss/_variables.scss"

//---------------------------------------------------------------------------
const StyledTextField = styled((props) => 
  <TextField
    variant="outlined"
    fullWidth
    multiline maxRows={4}
   {...props} />)({
  //for variant='standard' StyledTextField in http://localhost:3000/tool_ehp_s
  '& .MuiInput-underline:after': {
    // borderBottomColor: '#f0f',
    borderBottomColor: v.green,
  },
  //for variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: v.green
  },
  //for variant='outlined'(default) StyledTextField in http://localhost:3000/tool_hs_s
  '& label.Mui-focused': {
    // color: '#ff0',
    color: v.darkGreen,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: '#f00',
    },
    '&:hover fieldset': {
      // borderColor: '#0f0',
      borderColor: v.darkGreen,
    },
    '&.Mui-focused fieldset': {
      // borderColor: '#00f',
      borderColor: v.green,
    },
  },
});

export default StyledTextField;
import { useEffect, useState, useRef } from 'react';
// import { Box, Button } from '@mui/material';
// import Stack from '@mui/material/Stack';
// import { styled } from '@mui/material/styles';
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import scss from './Navbar.module.scss';
import NoneButton from 'components/button/NoneButton';
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import cookie from 'utils/cookie';
import imgs from 'utils/imgs';
import links from 'utils/links';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch } from "utils/network/axios";
import Search2 from './home/Search2';
import Text, { TextLink } from 'components/tag/Text';
import { ImgLink } from 'components/tag/Img';
//---------------------------------------------------------------------------
/*
const GridContainer = (props) => (
    <Grid container
      // disableEqualOverflow
      {...props}>
        {props.children}
    </Grid>
)

const GridItem = (props) => (
    <Grid item {...props}>
        {props.children}
    </Grid>
)
 */

//---------------------------------------------------------------------------
export default function Navbar({ setInfo }) {
  const [isSearchDisplay, setIsSearchDisplay] = useState(false);
  const [isClickSearchBtn, setIsClickSearchBtn] = useState(false);
  const [isSearchBar, setIsSearchBar] = useState(false);
  const [isHomepage, setIsHomepage] = useState(false);
  let location = useLocation();
  let pathsName = location.pathname

  // console.log(Site.Company.AboutUs);
  // console.log(Site.Company.AboutUs.link);
  const [linkLog, setLinkLog] = useState('');
  const [userToken, setUserToken] = useState('');

  // let email = cookie.getCookie(cookie.keys.auth.email)
  //console.log("email",email);
  // const accountName = email.split('@')[0];
  // const userName = accountName.charAt(0).toUpperCase() + accountName.slice(1);
  const userName = cookie.getCookie(cookie.keys.auth.nickname)
  //console.log("userName",userName);

  let token = cookie.getCookie(cookie.keys.auth.token)
  if (token !== userToken)
    setUserToken(token)
  // console.log('token', token);
  // console.log('userToken', userToken);

  function removeAllCookie() {
    cookie.removeCookie(cookie.keys.auth.token)
    cookie.removeCookie(cookie.keys.auth.id)
    cookie.removeCookie(cookie.keys.auth.nickname)
  }

  useEffect(() => {
    if (pathsName === '/') {
      setIsHomepage(true);
    } else {
      setIsHomepage(false);
    }
  }, [pathsName]);

  useEffect(() => {
    // console.log(linkList.nav.auth.login);
    // console.log(linkList.nav.auth.logout);
    if (userToken === '')
      setLinkLog(links.auth.login)
    else
      setLinkLog(links.auth.logout)
    // console.log('linkLog', linkLog);
  }, [userToken])
  
  const navigate = useNavigate()
  const logoutEvent = () => {
    const config = apiConfig.auth.logout(cookie.getCookie(cookie.keys.auth.token))
    // console.log(config);

    axios(config)
      .then(result => {
        switch (result.data.result_code) {
          case 200:
            removeAllCookie()
            setInfo(snackInfo.open(snackType.success, "Logout Successfully"))
            navigate(paths.site.home)
            break;
          default:
            setInfo(snackInfo.open(snackType.error, result.data.reason))
        }

      }).catch(err => {
        // console.error("Logout error:", err)
        let msg = axiosCatch.getMsg(err)
        let code = axiosCatch.getResultCode(err)
        // console.log('msg', msg);
        // console.log('code', code);
        setInfo(snackInfo.open(snackType.error, msg))
        if (axiosCatch.needLogin(err))
          cookie.removeCookieAndJump(navigate, location)
      })
  }

  const handleClickLog = () => {
    if (userToken === '')
      navigate(paths.auth.login)
    else
      logoutEvent()
  }
  
  const css = {
    text_menu: 'size20strong-darkGreen',
    text_user: 'b1strong-darkGreen',
  }

  return (
    // <div className={scss.layout} style={{backgroundImage: `url("${imgList.home.bg.src}")`}}></div>
    <div className={scss.layout}>
      <div className={scss.frame_all}>
        <div className={scss.frame_left}>
          <ImgLink imgName={imgs.site.nav.logo} link={links.site.nav.home} className={scss.logo}></ImgLink>
        </div>
        <div className={scss.frame_menu}>
          <TextLink className={css.text_menu} link={links.site.nav.proteins}></TextLink>
          <TextLink className={css.text_menu} link={links.site.nav.peptides}></TextLink>
          <TextLink className={css.text_menu} link={links.site.nav.products}></TextLink>
          <TextLink className={css.text_menu} link={links.site.nav.tools}></TextLink>
          <TextLink className={css.text_menu} link={links.site.nav.about}></TextLink>
        </div>
        <div className={scss.frame_right}>
          {/* {console.log("pathname", pathsName,'isHomepage',isHomepage)} */}
          {!isHomepage && !isSearchDisplay &&
            <IconButton
              size="large"
              onClick={() => {
                setIsSearchDisplay(true)
              }}>
              <SearchIcon fontSize="small" />
            </IconButton>}
          {isSearchDisplay &&
            <div className={scss.frame_search}>
              <Search2 setInfo={setInfo}></Search2>
              <IconButton
                className={scss.button_arrow}
                size="large"
                style={{ height: '50px' }}
                onClick={() => {
                  setIsSearchDisplay(false)
                }}>
                <KeyboardDoubleArrowRightIcon fontSize="small" />
              </IconButton>
            </div>
          }
          <Text className={`${css.text_user} hover-cursor-pointer`} onClick={handleClickLog}>{linkLog.text}</Text>
          <div className={scss.button_signup}>
            {(userToken !== "" && userToken !== undefined)
              ? <Text className={css.text_user}>{userName}</Text>
              : <TextLink pre className={css.text_user} link={links.auth.signup}></TextLink>
            }
          </div >
        </div >
      </div >
    </div >
  )
}
import { TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
// import Chart from "chart.js/auto";

import 'assets/scss/common.scss';
// import v from 'assets/scss/_variables.scss';
import scssPeptide from 'views/peptide/PeptideDFBPIDResult.module.scss';
import scssFull from 'views/peptide/PeptideDFBPIDFull.module.scss';

import { breadData } from 'components/bread/BreadBar';
import LoadingAnime from 'components/LoadingAnime';
import { TagText, Code } from 'components/tag/Text';
import { TableRow2Body } from 'components/table/MuiTable';
import { A } from 'components/tag/A';
import NoneButton from 'components/button/NoneButton';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import StyledTab from 'components/mui/StyledTab';
import StyledTabs from 'components/mui/StyledTabs';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import LayoutPage2 from 'layouts/LayoutPage2';
import links from 'utils/links';
import Text from 'components/tag/Text';
import MuiTabPanel, { scrollTab } from 'components/mui/MuiTabPanel';
import { getClassName, getClassNameTitle, getScssNameCell } from 'components/peptide/sppID';
import { handleScroll } from 'components/peptide/sppLayout';

//---------------------------------------------------------------------------
// Go in: 'Peptide' page -> any item list -> any 'SPP ID' -> click 'Specific target protein(s)' link -> this page
// path: paths.spp.peptide.id_target_protein_page
const jsons = spp.peptide.search.id_target_protein_page //89
const texts = {
  bread: breadData.create('Peptide', 'Target Protein'),

  tabs: [''],
}

const tabs = {
  _00card: {
    head: [],
    json: [],
  },

  _0overview: {
    title: texts.tabs[0],
    head: jsons.output_text,
    json: [
      ...jsons.output.slice(0, 5 + 1),
    ],
  },
}

//---------------------------------------------------------------------------
const PeptideTargetProteinPage = ({ setInfo }) => {
  const [queryParameters] = useSearchParams()
  const sppId = queryParameters.get(paths.params.id)
  let input = jsons.input(sppId) //89
  // console.log(input);
  
  //---------------------------------------------------------------------------
  const [output, setOutput] = useState({}) //all of output
  const [outputObj, setOutputObj] = useState({}) //object
  const [card, setCard] = useState([])

  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiResult = () => {
    const config = apiConfig.peptide.id_target_protein_page(input) //89
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.open(snackType.error, result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let output = result.data
        setOutput(output)
        // console.log('output', output)

        let card = []
        setCard(card)
        // console.log(card);
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  useEffect(() => {
    ref.current = ref.current.slice(0, texts.tabs.length)
    if (axiosState.keepRest(state))
      apiResult()
  }, [])
  
  //---------------------------------------------------------------------------
  // TabPanel cell
  const TabCell = (props) => { //show table key&value
    const { tab, output, outputObj } = props
    let indexRef = 0 //bug D number always being from 1
    return (
      <>
        <Text className={'h3-darkGreen'}>{tab.title}</Text>
        {tab.head.map((item, index) => {
          // console.log(index, item)
          const json = tab.json[index]
          let isHide = false
          let link = ""
          let isFullCell = (json === "Highlight sequence")

          return (isHide
            ? null
            : <div key={index} className={scssFull[getScssNameCell(json, tab, index)]}>
              {isFullCell
                ? getValue(output, outputObj, tab.json, index, indexRef)
                : <>
                  <div key={`div-${item}`} className={scssFull.cell_label}>
                    <Text key={`text-${item}`} className={getClassNameTitle(item)}>
                      {link !== ""
                        ? <A href={link} className={getClassNameTitle(item)} >
                          <TagText html={item} />
                        </A>
                        : <TagText html={item} />}
                    </Text>
                  </div>
                  <div key={`div-${index}`} className={scssFull.cell_value}>
                    <Text key={`text-${index}`} className={getClassName(json, output)}>
                      {getValue(output, outputObj, tab.json, index, indexRef)}
                    </Text>
                  </div>
                </>}
            </div>)
        })}
      </>
    )
  }
  
  function getValue(output, outputObj, json, index, indexRef) {
    // console.log("output:", output)
    // console.log('json:', json)
    // console.log('index:', index)
    let idx = index
    const name = json[idx] //db name
    // console.log('name', name)
    let valueReturn = name
    if (name in output) {
      const value = output[name] //db value
      // console.log('name=', name, 'value=', value)
      valueReturn = value

      // console.log(name);
      switch (name) {
        // case "Highlight sequence":
        //   return (<Code>{value.toCode()}</Code>)
        case "target_gene":
        case "diseases":
        case "pathways":
          valueReturn = value.replaceHTML()
          break
        default:
      }
    } else {
      // console.log(name);
      // switch (index) {
      //   case 2: //'Peptide sequence'
      //     valueReturn = keyword.toUpperCase()
      //     break
      //   default:
          valueReturn = name
      // }
    }
    return (<TagText html={valueReturn} />)
  }
  
  //---------------------------------------------------------------------------
  // TabPanel
  const isShownAllTabs = true //false=multi tabs
  const ref = useRef([])
  const [tabValue, setTabValue] = React.useState(0);
  
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/
  useEffect(() => {
    window.addEventListener("scroll", () => handleScroll(ref, setTabValue));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/

  const ContentObjList = [
    <TabCell tab={tabs._0overview} output={output} outputObj={outputObj}></TabCell>,
  ]
  
  return (
    <LayoutPage2 bread={texts.bread} card={card} output={output}>
      <div className={scssPeptide.layout}>
        {isShownAllTabs && state.isLoading ? <LoadingAnime /> :
          <div className={scssFull.frame_right}>
            {ContentObjList.map((item, index) => (
              <MuiTabPanel key={index} index={index} value={tabValue} tabRef={ref}
                isShownAllTabs={isShownAllTabs}>
                {!isShownAllTabs && state.isLoading ? <LoadingAnime /> : item}
              </MuiTabPanel>
            ))}
          </div>
        }
      </div>
    </LayoutPage2>
  )
}

export default PeptideTargetProteinPage;
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

//---------------------------------------------------------------------------
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  whiteSpace: 'pre-line',
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    fontWeight: 'bold',
    // fontSize: 16,
    // whiteSpace: 'pre-line',
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 16,
    // whiteSpace: 'pre-line',
  },
}));
export default StyledTableCell
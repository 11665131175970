import { configureStore } from '@reduxjs/toolkit'

import mainReducer from './mainSlice'
import registerReducer from './registerSlice'

//---------------------------------------------------------------------------
export const store = configureStore({
  reducer: {
    main: mainReducer,
    register: registerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['main/setRightSideInfo', 'main/rightSideOpenHandler'],
        // Ignore these field paths in all actions
        ignoredActionPaths: [''],
        // Ignore these paths in the state
        ignoredPaths: ['main.rightSideBlockInfo'],
      },
    }),
})

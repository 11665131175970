import { MenuItem, Select } from "@mui/material";

import v from 'assets/scss/_variables.scss';

//------------------------------------------------------------------------------
const MuiSelect = (props) => {
  return (
  <Select variant="standard"
    style={{ color: v.darkGreen }}
    {...props}
  >
    <MenuItem value=""><em>None</em></MenuItem>
    {props.children}
  </Select>)
}

export default MuiSelect
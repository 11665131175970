import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { breadData } from 'components/bread/BreadBar';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import SearchFullResultLayout from 'layouts/search/SearchFullResultLayout';
import cookie from 'utils/cookie';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import paths from 'utils/network/apiPath';
import { getKeyByValue } from 'utils/object';
import SearchResultLayout from 'layouts/search/SearchResultLayout';

//---------------------------------------------------------------------------
// path: paths.spp.tool.aceipp_anoxpp_mm
let tool_names = [
  'ACEiPP',
  'AnOxPP',
]

//------------------------------------------------------------------------------
const ToolACEiPPAnOxPPManualMutation = ({ setInfo }) => {
  const [queryParameters] = useSearchParams()
  const datasetStr = queryParameters.get(paths.params.dataset)
  const dataset = Number(datasetStr)
  // console.log('dataset', dataset);
  const keyword = queryParameters.get(paths.params.keyword)
  // console.log('keyword', keyword);
  let site = queryParameters.get(paths.params.site) || 1
  
  const jsons = spp.tool.aceipp_anoxpp_manual_mutation
  const datasetName = getKeyByValue(jsons.dataset, dataset)
  // console.log(datasetName);
  const toolIndex = dataset - 1
  const toolName = (toolIndex >= 0 && toolIndex < tool_names.length) ? tool_names[toolIndex] : ''

  // let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  // let bread4th = cookie.getCookie(cookie.keys.protein.bread4th)
  // console.log('bread4th', bread4th);
  
  let texts = {
    bread: breadData.create('Tools', `${toolName}-1.0 Server Output`, `${toolName} Pre-${datasetName}`
      , `Manual Result`, 'Mutation'),
    
    tableHeadCell: jsons.items_text,
    tableBodyCell: jsons.items,
  }

  return (
    <>
      <SearchFullResultLayout
        setInfo={setInfo}
        texts={texts}
        configSearch={apiConfig.tool.aceipp_anoxpp_manual_mutation}
        jsonInput={jsons.input}
        inputProp={[dataset, keyword, site]}
        jsonInputKeyword={jsons.input}
      />
    </>
  )
}
export default ToolACEiPPAnOxPPManualMutation